import React, { useEffect, useState } from "react";
import titleimage from "../../../../Images/title-icon.png";
import { FaAddressCard, FaCalendar, FaUser } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { Link, useNavigate } from "react-router-dom";
import Config from "../../../../API/Config";
import usePageSEO from "../../../usepageSEO/Index";
import {InfluencerGetById,InfluencerStep3Add} from "../../../../API/InfluencerSignUpAPI";
import { getAllCollbration } from "../../../../API/CollabrationAPi";

const validationSchema = Yup.object().shape({
  CollaborationsType: Yup.array()
  .min(1, "Please select at least one CollaborationsType.")
  .max(5, "You can select up to 5 CollaborationsType.")
  .required("Please select at least one CollaborationsType."),
  TermsandConditions: Yup.boolean().oneOf(
    [true],
    "You must accept the Terms and Conditions"
  ),
  PrivacyPolicy: Yup.boolean().oneOf(
    [true],
    "You must accept the Privacy Policy"
  ),
});

const RegisterInfluencerNew3 = () => {
  usePageSEO(
    "Free Influencer Register - ViralKar",
    "Want more brand deals? Use our free influencer register to boost your visibility, connect with top companies, and grow your online presence fast.",
    [
      "Free influence Register, influence Register, Register as influencer, Register as influencer for free",
    ]
  );
  const InfluencerId = Config.getInfluencerIdForTemp();
  const [CollbrationData, setCollbrationData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [CollbrationResult] = await Promise.all([getAllCollbration()]);
        setCollbrationData(CollbrationResult);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const getData = async () => {
    try {
      const response = await InfluencerGetById(InfluencerId);
  
      sessionStorage.setItem("InfluencerStep1ForTemp", JSON.stringify(response.Step1));
      sessionStorage.setItem("InfluencerStep2ForTemp", JSON.stringify(response.Step2));
      sessionStorage.setItem("InfluencerStep3ForTemp", JSON.stringify(response.Step3));
  
      const parseStringToArray = (str) =>
        str
          .split(",")
          .map((item) => item.trim())
          .filter((item) => item !== "");
  
      const collabrationIdArray = parseStringToArray(response.CollabrationId || "");
  
      const numericCollabs = [];
      let otherCollabType = "";
  
      collabrationIdArray.forEach((collab) => {
        if (/^\d+$/.test(collab)) {
          numericCollabs.push(collab);
        } else {
          otherCollabType = collab;
        }
      });
  
      formik.setValues({
        InfluencerId: response?.Id || InfluencerId,
        CollaborationsType: numericCollabs, 
        Website: response?.Website || "",
        OtherCollaborationsType: otherCollabType || "", 
        CollaborationIndustries: response?.CollaborationIndustries || "",
        TermsandConditions: response?.TermsandConditions || false,
        PrivacyPolicy: response?.PrivacyPolicy || false,
      });
  
      if (otherCollabType) {
        formik.setFieldValue("CollaborationsType", [...numericCollabs, "Other"]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    getData();
  }, [InfluencerId]);
  

  const handleLanguageCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const { CollaborationsType } = formik.values;

    if (checked) {
      if (value === "Other") {
        formik.setFieldValue("CollaborationsType", [
          ...CollaborationsType,
          value,
        ]);
      } else {
        formik.setFieldValue("CollaborationsType", [
          ...CollaborationsType,
          value,
        ]);
      }
    } else {
      const newCollaborationsType = CollaborationsType.filter(
        (id) => id !== value
      );

      // Clear OtherCollaborationsType if "Other" is deselected
      if (value === "Other") {
        formik.setFieldValue("OtherCollaborationsType", "");
      }

      formik.setFieldValue("CollaborationsType", newCollaborationsType);
    }
  };

  const [isFormLoading, setIsFormLoading] = useState(false);
  const navigation = useNavigate();
  const formik = useFormik({
    initialValues: {
      InfluencerId: InfluencerId,
      CollaborationsType: [],
      Website: "",
      OtherCollaborationsType: "",
      CollaborationIndustries: "",
      TermsandConditions: false,
      PrivacyPolicy: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsFormLoading(true);

      try {
        const finalValues = {
          ...values,
        };

        // Submit the form
        const result = await InfluencerStep3Add(finalValues);
        if (result.status === true) {
          navigation("/profile/influencer");
        }
        getData();
      
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  return (
    <div>
      <div className="bg-dark">
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-14 px-5 container mx-auto">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center pb-2 dark:text-white capitalize">
              Unlock your influence register or log in to shine brighter
            </p>
            <img
              src={titleimage}
              className="text-center mt-5 title-icon"
              alt=""
            />
          </div>
          <div className="relative flex items-center justify-between w-full my-10">
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300"></div>
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-themecolor dark:bg-white transition-all duration-500"></div>
            <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor rounded-full place-items-center">
              <FaUser className="w-5 h-5" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th bg-themecolor transition-all duration-300 rounded-full place-items-center">
              <FaCalendar className="w-5 h-5 text-white" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-themecolor rounded-full place-items-center">
              <FaAddressCard className="w-5 h-5 text-white  " />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className=" my-10 dark:border border-white"
          >
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="col-span-3 bg-white dark:bg-slate-800 p-5 md:p-8 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border">
                <p className="text-center text-2xl pb-3 mb-3">
                  Collaboration Preferences
                </p>
                <div className="rounded-lg shadow-sm duration-500 border px-4 py-4 mb-6">
                  <div className="mb-3">
                    <p>
                      Type of Collaborations Interested In{" "}
                      <span className="text-red-500">*</span>
                    </p>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-4">
                    {CollbrationData[0]?.ChildCat?.map((val) => (
                      <div className="flex items-center my-1" key={val.Id}>
                        <input
                          type="checkbox"
                          className="mr-2"
                          name="CollaborationsType"
                          value={val.Id}
                          checked={formik.values.CollaborationsType.includes(
                            val.Id.toString()
                          )}
                          id={`collab-${val.Id}`}
                          onChange={handleLanguageCheckboxChange}
                        />
                        <label
                          className="text-sm text-gray-500 font-normal"
                          htmlFor={`collab-${val.Id}`}
                        >
                          {val.Title}
                        </label>
                      </div>
                    ))}
                    <div className="flex items-center my-1">
                      <input
                        type="checkbox"
                        className="mr-2"
                        name="CollaborationsType"
                        value="Other"
                        id="collab-other"
                        checked={formik.values.CollaborationsType.includes(
                          "Other"
                        )}
                        onChange={handleLanguageCheckboxChange}
                      />
                      <label
                        className="text-sm text-gray-500 font-normal"
                        htmlFor="collab-other"
                      >
                        Other
                      </label>
                    </div>
                  </div>
                  <div className="pt-0">
                    {formik.touched.CollaborationsType &&
                      formik.errors.CollaborationsType && (
                        <div className="text-red-500 text-sm">
                          {formik.errors.CollaborationsType}
                        </div>
                      )}
                    {formik.values.CollaborationsType.includes("Other") && (
                      <div className="mt-2">
                        <input
                          type="text"
                          id="OtherCollaborationsType"
                          name="OtherCollaborationsType"
                          value={formik.values.OtherCollaborationsType}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Other Collaborations"
                          className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                        />
                        {formik.errors.OtherCollaborationsType &&
                          formik.touched.OtherCollaborationsType && (
                            <div className="text-red-500 text-sm">
                              {formik.errors.OtherCollaborationsType}
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                  <div className="my-1">
                    <label for="">Preferred Brands/Industries </label>
                    <input
                      type="text"
                      name="CollaborationIndustries"
                      value={formik.values.CollaborationIndustries}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Preferred Brands/Industries"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    <div className="flex items-center">
                      {formik.touched.CollaborationIndustries &&
                      formik.errors.CollaborationIndustries ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.CollaborationIndustries}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="my-1">
                    <label for="">Website/Blog URL</label>
                    <input
                      type="text"
                      name="Website"
                      value={formik.values.Website}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Website/Blog URL"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    <div className="flex items-center">
                      {formik.touched.Website && formik.errors.Website ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Website}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="my-1">
                  <div className="flex items-center mb-3">
                    <input
                      type="checkbox"
                      id="TermsandConditions"
                      name="TermsandConditions"
                      checked={formik.values.TermsandConditions}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="mr-2"
                    />
                    <label
                      className="text-sm text-gray-500 font-normal"
                      htmlFor="TermsandConditions"
                    >
                      I agree to the Terms and Conditions
                    </label>
                  </div>
                  {formik.touched.TermsandConditions &&
                  formik.errors.TermsandConditions ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.TermsandConditions}
                    </div>
                  ) : null}
                </div>
                <div className="my-1">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="PrivacyPolicy"
                      name="PrivacyPolicy"
                      checked={formik.values.PrivacyPolicy}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="mr-2"
                    />
                    <label
                      className="text-sm text-gray-500 font-normal"
                      htmlFor="PrivacyPolicy"
                    >
                      I accept the Privacy Policy
                    </label>
                  </div>
                  {formik.touched.PrivacyPolicy &&
                  formik.errors.PrivacyPolicy ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.PrivacyPolicy}
                    </div>
                  ) : null}
                </div>
                <div className="flex justify-around my-3 mt-5">
                  <Link
                    to={"/influencer/sign-up/influencer2"}
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10"
                  >
                    Previous
                  </Link>
                  <button
                    type="submit"
                    className="bg-green-600 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 ms-auto"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterInfluencerNew3;
