import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../Breadcumb/Breadcumb";
import ServiceBannerImg from "../../../Images/services-inner-banner.jpg";
import { useParams } from "react-router";
import { getServiceById } from "../../../API/ServiceApi";
import ScrollToTop from "../ScrollToTop"; // Scroll to top component
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../../usepageSEO/Index";

const ServiceInner = () => {

  const backgroundImage = ServiceBannerImg;

  const { Id } = useParams();
  const [serviceData, setServiceData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetService = async () => {
      try {
        const response = await getServiceById(Id);
        setServiceData(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetService();
  }, [Id]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top
  }, [Id]);

  usePageSEO(
    serviceData ? serviceData.SeoTitle : "Viral kar | Pages", // Use page title for SEO if available
    serviceData ? serviceData.SeoDescription : "Viral kar | Pages", // Use page description for SEO if available
    [serviceData ? serviceData.SeoKeyword : "Viral kar | Pages"] // No keywords provided in this example
  );

  const breadcrumbItems = [
    {
      text: serviceData.Title,
    },
  ];

  return (
    <div className="dark:bg-darkmode">
      <ScrollToTop /> {/* Scroll to Top component added here */}
      <div>
        <BreadCrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
        <div className="  text-black dark:text-white">
          {loading ? (
            <div className="flex justify-center items-center my-40 dark:bg-darkmode">
              <ClipLoader color={"#f59231"} loading={loading} size={40} />
            </div>
          ) : (
            <div className="container mx-auto md:py-10 py-5 px-4 lg:px-0">
              <div className="md:flex">
                <div className="md:w-3/4">
                  <p
                    className="pt-0 lg:text-base text-sm text-justify lg:px-10"
                    dangerouslySetInnerHTML={{
                      __html: serviceData ? serviceData.Content : "",
                    }}
                  />
                </div>
                <div className="md:w-1/4">
                  <img
                    src={serviceData.BannerImg}
                    className="rounded-xl"
                    alt=""
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceInner;
