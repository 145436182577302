import React, { useEffect, useState } from "react";
import titleimage from "../../../../Images/title-icon.png";
import { FaAddressCard, FaCalendar, FaUser } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from "../../../../Images/logo.png";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { useNavigate } from "react-router";
import Config from "../../../../API/Config";
import { Link } from "react-router-dom";
import usePageSEO from "../../../usepageSEO/Index";
import { useData } from "../../../../Context/DataContext ";
import { getAllCategory } from "../../../../API/CategoryAPi";
import { ArtistGetById, ArtistStep2Add } from "../../../../API/ArtistSignUpAPI";

const RegisterArtist2 = () => {
  usePageSEO(
    "Free Artist Register - ViralKar", // Use page title for SEO if available
    "Need more exposure for your art? Join our free artist register to connect with clients, showcase your work, and grow your creative career today.", // Use page description for SEO if available
    [
      "Free Artist Register, Artist Register, Register as Artist, Register as Artist for free",
    ] // No keywords provided in this example
  );

  const [CategoryData, setCategoryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryResult] = await Promise.all([getAllCategory()]);
        setCategoryData(categoryResult);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const [isFormLoading, setIsFormLoading] = useState(false);
  const ArtistId = Config.getArtistIdForTemp();
  const navigation = useNavigate();

  const validationSchema = Yup.object().shape({
    Category: Yup.array()
      .min(1, "Please select at least one category.")
      .max(5, "You can select up to 5 categories.")
      .required("Please select at least one category."),
    Bio: Yup.string().required("Please Enter Your Bio"),
    Experience: Yup.string().required("Please Enter Your Experience"),
    PreviousProjects: Yup.string().required(
      "Please Enter Your Previous Projects"
    ),
    Awards: Yup.string().required("Please Enter Your Awards"),
    NotableWorks: Yup.string().required("Please Enter Your Notable Works"),
    Website: Yup.string()
      .url("Must be a valid URL")
      .required("Please Enter Your Website"),
    VideoUrl: Yup.string()
      .url("Must be a valid URL")
      .required("Please Enter Your Video Url")
  });

  const { CityData } = useData();

  const [ArtistData, setArtistData] = useState([]);
  const ArtistStep1 = Config.getArtistStep1ForTemp();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await ArtistGetById(ArtistId);

        setArtistData(response);

        sessionStorage.setItem(
          "ArtistStep1ForTemp",
          JSON.stringify(response.Step1)
        );
        sessionStorage.setItem(
          "ArtistStep2ForTemp",
          JSON.stringify(response.Step2)
        );
        sessionStorage.setItem(
          "ArtistStep3ForTemp",
          JSON.stringify(response.Step3)
        );

        formik.setValues({
          ArtistId: response?.Id || "",
          Category: response?.Category || [], // Ensure arrays are initialized
          Bio: response?.Bio || "", // Ensure arrays are initialized
          Experience: response?.Experience || "", // Ensure arrays are initialized
          PreviousProjects: response?.PreviousProjects || "", // Ensure arrays are initialized
          Awards: response?.Awards || "", // Ensure arrays are initialized
          NotableWorks: response?.NotableWorks || "", // Ensure arrays are initialized
          Website: response?.Website || "", // Ensure arrays are initialized,
          VideoUrl: response?.VideoUrl || "", // Ensure arrays are initialized,
          Exhibitions: response?.Exhibitions || "", // Ensure arrays are initialized,
          // GalleryofWorks: response?.Images || [],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, [ArtistId]);

  const formik = useFormik({
    initialValues: {
      ArtistId: ArtistId,
      Category: [],
      Bio: "",
      Experience: "",
      PreviousProjects: "",
      Awards: "",
      NotableWorks: "",
      Website: "",
      VideoUrl: "",
      Exhibitions: "",
      // GalleryofWorks: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsFormLoading(true);

      try {
        const formData = new FormData();

        const result = await ArtistStep2Add(values);

        if (result.status === true) {
          navigation("/artist/sign-up/artist3");
          sessionStorage.setItem(
            "ArtistEmailForTemp",
            JSON.stringify(ArtistData.Email)
          );
          sessionStorage.setItem(
            "ArtistUserNameForTemp",
            JSON.stringify(ArtistData.UserName)
          );
          sessionStorage.setItem(
            "ArtistPhoneForTemp",
            JSON.stringify(ArtistData.Phone)
          );
          sessionStorage.setItem(
            "ArtistStep1ForTemp",
            JSON.stringify(ArtistData.Step1)
          );
          sessionStorage.setItem(
            "ArtistStep1ForTemp",
            JSON.stringify(ArtistData.Step2)
          );
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  const handleCheckboxChange = async (event) => {
    const { value, checked } = event.target;
    const { Category } = formik.values;

    // Convert value to string
    const stringValue = value;

    if (checked) {
      // Add value to array if not already present and if the limit is not reached
      if (!Category.includes(stringValue) && Category.length <= 5) {
        formik.setFieldValue("Category", [...Category, stringValue]);
      }
    } else {
      // Remove value from array
      formik.setFieldValue(
        "Category",
        Category.filter((category) => category !== stringValue)
      );
    }

    // Manually trigger validation
    await formik.validateField("Category");
  };
  return (
    <div>
      <div className="bg-dark">
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-14 px-5 container mx-auto">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center pb-2 dark:text-white capitalize">
              Unleash your creativity register or log in to let your art shine
            </p>

            <img
              src={titleimage}
              className="text-center mt-5 title-icon"
              alt=""
            />
          </div>
          <div className="relative flex items-center justify-between w-full my-10">
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300"></div>
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-themecolor dark:bg-white transition-all duration-500"></div>
            <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor rounded-full place-items-center">
              <FaUser className="w-5 h-5" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th bg-themecolor transition-all duration-300 rounded-full place-items-center">
              <FaCalendar className="w-5 h-5 text-white" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-gray-300 rounded-full place-items-center">
              <FaAddressCard className="w-5 h-5 text-black" />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className=" my-10 dark:border border-white"
          >
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="col-span-3 bg-white dark:bg-slate-800 p-5 md:p-8 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border">
                <p className="text-center text-2xl pb-3 mb-3">
                  Artistic Information
                </p>
                <div className="rounded-lg shadow-sm duration-500 border px-4 py-4 mb-6">
                  <div className="mb-3">
                    <p>
                      Please select category{" "}
                      <span className="text-red-500">*</span>
                    </p>
                    <small className="text-gray-400">
                      Select categories (Products or Services you can promote)
                    </small>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-4">
                    {CategoryData[2]?.ChildCat?.map((val, index) => (
                      <div className="flex items-center my-2" key={index}>
                        <input
                          type="checkbox"
                          className="mr-2"
                          name="Category"
                          value={val.Id} // Ensure this matches the type of data in Formik
                          checked={formik.values.Category.includes(
                            val.Id.toString()
                          )} // Convert ID to string
                          id={val.Title}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="text-sm text-gray-500 font-normal"
                          htmlFor={val.Title}
                        >
                          {val.Title}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="pt-0">
                    {formik.touched.Category && formik.errors.Category && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Category}
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                  <div className="my-1">
                    <label className="text-base">
                      Artistic Description/Bio{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      name="Bio"
                      value={formik.values.Bio}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Artistic Description/Bio"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    ></textarea>
                    {formik.touched.Bio && formik.errors.Bio ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Bio}
                      </div>
                    ) : null}
                  </div>
                </div>

                <p className="text-center text-2xl my-3 mb-5 pb-5 border-b">
                  Experience and Achievements
                </p>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                  <div className="my-1">
                    <label className="text-base">
                      Years of Experience{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="Experience"
                      value={formik.values.Experience}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Years of Experience"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    {formik.touched.Experience && formik.errors.Experience ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Experience}
                      </div>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <label className="text-base">
                      Previous Collaborations/Projects{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="PreviousProjects"
                      value={formik.values.PreviousProjects}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Previous Collaborations/Projects"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]  "
                    />
                    {formik.touched.PreviousProjects &&
                    formik.errors.PreviousProjects ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.PreviousProjects}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Awards and Recognitions{" "}
                    </label>
                    <input
                      type="text"
                      name="Awards"
                      value={formik.values.Awards}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Awards and Recognitions"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]  "
                    />
                    {formik.touched.Awards && formik.errors.Awards ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Awards}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Notable Works <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="NotableWorks"
                      value={formik.values.NotableWorks}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Notable Works"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    {formik.touched.NotableWorks &&
                    formik.errors.NotableWorks ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.NotableWorks}
                      </div>
                    ) : null}
                  </div>
                </div>

                <p className="text-center text-2xl my-3 mb-5 pb-5 border-b">
                  Portfolio Information
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="my-1">
                    <label className="text-base">
                      Portfolio Website/Blog URL
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="Website"
                      value={formik.values.Website}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Portfolio Website/Blog URL"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    {formik.touched.Website && formik.errors.Website ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Website}
                      </div>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <label className="text-base">
                      Showreel/Video Link
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="VideoUrl"
                      value={formik.values.VideoUrl}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Showreel/Video Link"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    {formik.touched.VideoUrl && formik.errors.VideoUrl ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.VideoUrl}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">Exhibitions</label>
                    <input
                      type="text"
                      name="Exhibitions"
                      value={formik.values.Exhibitions}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Exhibitions"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    {formik.touched.Exhibitions && formik.errors.Exhibitions ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Exhibitions}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/*
                 <div className="grid grid-cols-1  gap-4">
                  <div className="my-1">
                    <label className="text-base">
                      Gallery of Works{" "}
                      <span className="text-red-500">
                        *(Multiple Images)-(Below 1 MB)
                      </span>
                    </label>
                    <input
                      type="file"
                      name="GalleryofWorks"
                      accept="image/*"
                      onChange={(event) => {
                        const files = Array.from(event.currentTarget.files);
                        formik.setFieldValue("GalleryofWorks", files);
                      }}
                      onBlur={formik.handleBlur}
                      multiple
                      className="px-4 py-1.5 w-full focus:outline-none my-1.5 border dark:bg-[#020617] "
                    />
                    {formik.touched.GalleryofWorks &&
                    formik.errors.GalleryofWorks ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.GalleryofWorks}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="grid grid-cols-5  gap-4">
                  {formik.values.GalleryofWorks?.map((image, index) => (
                    <img
                      key={index}
                      src={image.ImageUrl}
                      alt={`Artwork ${index}`}
                      className="gallery-image"
                    />
                  ))}
                </div>
                */}

                <div className="flex justify-around my-3">
                  <Link
                    to={"/artist/sign-up/artist"}
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10"
                  >
                    Previous
                  </Link>
                  <button
                    type="submit"
                    className="bg-green-600 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 ms-auto"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterArtist2;
