import React, { useState } from "react";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import FaqBanner from "../../Images/FaqBanner.jpg";

const FAQ = () => {
  const breadcrumbItems = [
    {
      text: "FAQ",
    },
  ];

  const faqItems = [
    {
      question: "How do I create an account on ViralKar.com?",
      answer:
        "Click on the “Sign Up” button and fill out the registration form with your details. You’ll receive a confirmation email to activate your account.",
    },
    {
      question: "Is there a fee to join ViralKar.com?",
      answer:
        "Joining ViralKar.com is free. However, certain features or services may have associated fees, which will be clearly communicated.",
    },
    {
      question: "How can I find opportunities?",
      answer:
        " Once registered, you can browse through available opportunities in your dashboard and apply or connect with potential clients or collaborators.",
    },
    {
      question: "Can minors register on ViralKar.com?",
      answer:
        "Yes, minors aged 13-17 can register with parental consent. A parent or legal guardian must complete a consent form during the registration process.",
    },
    {
      question: "How can I contact support?",
      answer:
        "You can reach our support team at [support@viralkar.com] or [+91 1234567890].",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className="da">
      <Breadcrumb items={breadcrumbItems} backgroundImage={FaqBanner} />

      <main className="px-5 lg:py-10 py-5 bg-gray-100 dark:bg-darkmode dark:text-white">
        <div className="flex justify-center items-start my-2">
          <div className="w-full sm:w-10/12 md:w-1/2 my-1">
            <h2 className="md:text-xl font-semibold text-vnet-blue mb-2">
              Got Questions? We've Got Answers!
            </h2>
            <ul className="flex flex-col">
              {faqItems.map((item, index) => (
                <div
                  key={index}
                  className="bg-white dark:bg-darkmode border my-2 shadow-lg rounded-md overflow-hidden"
                >
                  <h2
                    onClick={() => handleToggle(index)}
                    className=" justify-between items-center font-semibold p-3 cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-950 transition-colors duration-300"
                  >
                    <div className="grid grid-cols-5">
                      <div className="col-span-4">
                        <span className="md:text-base">{item.question}</span>
                      </div>
                      <div className="ml-auto">
                        <svg
                          className={`fill-current text-themecolor h-6 w-6 transform transition-transform duration-300 ${openIndex === index ? "rotate-180" : ""
                            }`}
                          viewBox="0 0 20 20"
                        >
                          <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                        </svg>
                      </div>
                    </div>
                  </h2>
                  <div
                    className={`border-l-4 border-themecolor overflow-hidden transition-max-height duration-500 ease-in-out ${openIndex === index ? "max-h-40" : "max-h-0"
                      }`}
                  >
                    <p className="p-3 text-sm md:text-base text-gray-900 dark:text-white">{item.answer}</p>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FAQ;
