import React, { useState } from "react";
import logo from "../../Images/logo.png";
import { IoHomeOutline } from "react-icons/io5";
import { FaSignInAlt, FaUser } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import Config from "../../API/Config";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const toggleSubMenu = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  const closeMenus = () => {
    setIsMenuOpen(false);
    setActiveSubMenu(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const Type = Config.getType();
  const Id = Config.getId();

  return (
    <div className="top-0 z-50 right-0 left-0 sticky border-b shadow-md">
      <header className="relative">
        <nav className="bg-white dark:bg-slate-950 border-gray-200 dark:bg-gray-80">
          <div className="flex flex-wrap justify-between items-center w-full md:p-4 p-3 text-black">
            <Link to={"/"}>
              <a className="flex items-center">
                <img
                  src={logo}
                  className="mr-3 h-8 sm:h-11 surface:h-7 bg-transparent bg-white rounded"
                  alt="Logo"
                />
              </a>
            </Link>
            <button
              data-collapse-toggle="mobile-menu-2"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-white ml-auto"
              aria-controls="mobile-menu-2"
              aria-expanded={isSidebarOpen}
              onClick={toggleSidebar}
            >
              <svg
                className={`${isSidebarOpen ? "hidden" : "block"} w-6 h-6`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
              <svg
                className={`${isSidebarOpen ? "block" : "hidden"} w-6 h-6`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <div
              className={`${isSidebarOpen ? "block" : "hidden"
                } fixed top-0 right-0 w-64 bg-themecolor1 text-white h-full z-50 lg:hidden`}
            >
              <div className="p-4">
                <button
                  type="button"
                  className="text-white"
                  onClick={toggleSidebar}
                >
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* mobile side Bar menu */}
              <ul className="">
                <li className="border-b border-gray-100">
                  <Link
                    to={"/"}
                    onClick={closeSidebar}
                    className="flex items-center py-3 px-4 text-white hover:bg-gray-700"
                  >
                    <span className="">Home</span>
                  </Link>
                </li>
                <li className="relative group border-b border-gray-100">
                  <button
                    onClick={() => toggleSubMenu(1)}
                    className="flex items-center px-4 py-3 text-white bg-gray-800 hover:bg-gray-700 text-sm  w-full"
                  >
                    Find
                    <IoMdArrowDropdown className="ml-auto" />
                  </button>
                  {activeSubMenu === 1 && (
                    <ul className="absolute left-0  bg-gray-800  z-10 w-full shadow-lg text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <li>
                        <Link
                          to="/find-influencer"
                          onClick={closeSidebar}
                          className="block px-4 py-3 text-white hover:text-themecolor text-xs"
                        >
                          Influencers
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/find-model"}
                          onClick={closeSidebar}
                          className="block px-4 py-3 text-white hover:text-themecolor text-sm"
                        >
                          Models
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/find-artist"}
                          onClick={closeSidebar}
                          className="block px-4 py-3 text-white hover:text-themecolor text-sm"
                        >
                          Artist
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>

                <li className="border-b border-gray-100">
                  <Link
                    to={"/how-its-work"}
                    onClick={closeSidebar}
                    className="flex items-center px-4 py-3 text-white hover:bg-gray-700 rounded text-sm"
                  >
                    How It Works!
                  </Link>
                </li>
                <li className="border-b border-gray-100">
                  <Link
                    to={"/service"}
                    onClick={closeSidebar}
                    className="flex items-center px-4 py-3 text-white hover:bg-gray-700 text-sm"
                  >
                    Services
                  </Link>
                </li>
                <li className="border-b border-gray-100">
                  <Link
                    to={"/blog"}
                    onClick={closeSidebar}
                    className="flex items-center px-4 py-3 text-white hover:bg-gray-700 text-sm"
                  >
                    Blog
                  </Link>
                </li>
                {!(Type && Id) && (
                  <li className="border-b border-gray-100">
                    <Link
                      to="/sign-up/advertiser"
                      onClick={closeSidebar}
                      className={`${isActive("/sign-up/advertiser") ? "text-themecolor dark:text-yellow-600" : "text-black"
                        }
                      flex items-center px-4 py-3 text-white hover:bg-gray-700 text-sm`}
                    >
                      Sign up as an Advertiser
                    </Link>
                  </li>
                )}
                {Type && Id ? (
                  <li className="border-b border-gray-100">
                    <Link
                      to={
                        Type === "I" ? "/profile/influencer" :
                          Type === "M" ? "/profile/model" :
                            Type === "A" ? "/profile/artist" :
                              "/dashboard" // Default route if Type doesn't match
                      }
                      onClick={closeSidebar}>
                      <div className="flex items-center px-4 py-3 text-white hover:bg-gray-700 text-sm">
                        <FaUser className="inline-block mr-2" />
                        <span>My Profile</span>
                      </div>
                    </Link>
                  </li>
                ) : (
                  <li className="border-b border-gray-100">
                    <Link to="/sign-up" onClick={closeSidebar}>
                      <button
                        type="button"
                        className="flex items-center px-4 py-3 text-white hover:bg-gray-700 text-sm"
                      >
                        Influencer | Model | Artist
                      </button>
                    </Link>
                  </li>
                )}
              </ul>
            </div>

            <div className="hidden lg:block">
              <ul className="flex flex-col mt-4 font-medium lg:items-center lg:flex-row lg:space-x-3 surface:space-x-2 lg:mt-0">
                <Link to={"/"} className="mx-auto pe-2">
                  <a
                    className={`${isActive("/") ? "text-themecolor" : "text-black"
                      } block my-1 md:my-0 text-lg mx-auto text-black rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white`}
                    aria-current="page"
                  >
                    <IoHomeOutline className="text-2xl text-themecolor" />
                  </a>
                </Link>
                <li
                  className="p-1 my-1 md:my-0 text-lg mx-auto relative hidden"
                  onMouseEnter={() => toggleSubMenu(1)}
                  onMouseLeave={() => setActiveSubMenu(null)}
                >
                  <a
                    className="flex items-center cursor-pointer text-sm pr-1 pl-1 text-black py-2 lg:py-0 border-gray-100 lg:p-0 dark:text-gray-300 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Influencer <IoMdArrowDropdown className="mt-1 ml-1" />
                  </a>
                  {activeSubMenu === 1 && (
                    <ul className="absolute left-0 mt-2 bg-[#000000cf] rounded-lg z-10 shadow-lg lg:w-48 md:w-48 w-44 text-sm">
                      <li>
                        <Link
                          to="/female-influencer"
                          onClick={closeMenus}
                          className="block px-4 py-2 hover:text-themecolor text-white text-sm font-normal"
                        >
                          Female Influencers
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/male-influencer"}
                          onClick={closeMenus}
                          className="block px-4 py-2 hover:text-themecolor text-white text-sm font-normal"
                        >
                          Male Influencers
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li
                  className="p-1 my-1 md:my-0 text-lg mx-auto relative hidden"
                  onMouseEnter={() => toggleSubMenu(2)}
                  onMouseLeave={() => setActiveSubMenu(null)}
                >
                  <a
                    className="flex items-center cursor-pointer text-sm pr-1 pl-1 text-black py-2 lg:py-0 border-gray-100 lg:p-0 dark:text-gray-300 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Model
                    <IoMdArrowDropdown className="mt-1 ml-1" />
                  </a>
                  {activeSubMenu === 2 && (
                    <ul className="absolute left-0 mt-2 bg-[#000000cf] rounded-lg z-10 shadow-lg lg:w-48 md:w-48 w-44 text-sm">
                      <li>
                        <Link
                          to={"/female-models"}
                          onClick={closeMenus}
                          className="block px-4 py-2 hover:text-themecolor text-white text-sm font-normal"
                        >
                          Female Models
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/male-models"}
                          onClick={closeMenus}
                          className="block px-4 py-2 hover:text-themecolor text-white text-sm font-normal"
                        >
                          Male Models
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/kids-models"}
                          onClick={closeMenus}
                          className="block px-4 py-2 hover:text-themecolor text-white text-sm font-normal"
                        >
                          Kids Models
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="p-1 my-1 md:my-0 text-lg mx-auto relative hidden">
                  <Link
                    to={"/find-artist"}
                    className={`${isActive("/artist") ? "text-themecolor dark:text-yellow-600" : "text-black"
                      } flex items-center text-sm pr-1 pl-1 py-2 lg:py-0 border-gray-100 lg:p-0 dark:text-white lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    Artist
                  </Link>
                </li>

                <li
                  className="p-1 my-1 md:my-0 text-lg mx-auto relative"
                  onMouseEnter={() => toggleSubMenu(3)}
                  onMouseLeave={() => setActiveSubMenu(null)}
                >
                  <a
                    className="flex items-center cursor-pointer text-sm pr-1 pl-1 text-black py-2 lg:py-0 border-gray-100 lg:p-0 dark:text-gray-300 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Find
                    <IoMdArrowDropdown className="mt-1 ml-1" />
                  </a>
                  {activeSubMenu === 3 && (
                    <ul className="absolute left-0 mt-2 top-5 bg-[#000000cf] rounded-lg z-10 shadow-lg lg:w-48 md:w-48 w-44 text-sm">
                      <li>
                        <Link
                          to={"/find-influencer"}
                          onClick={closeMenus}
                          className="block px-4 py-2 hover:text-themecolor text-white text-xs font-normal"
                        >
                          Influencer
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/find-model"}
                          onClick={closeMenus}
                          className="block px-4 py-2 hover:text-themecolor text-white text-xs font-normal"
                        >
                          Models
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/find-artist"}
                          onClick={closeMenus}
                          className="block px-4 py-2 hover:text-themecolor text-white text-xs font-normal"
                        >
                          Artist
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>

                <li className="p-1 my-1 md:my-0 text-lg mx-auto relative">
                  <Link
                    to={"/how-its-work"}
                    className={`${isActive("/how-its-work")
                      ? "text-themecolor dark:text-yellow-600"
                      : "text-black"
                      } flex items-center text-sm pr-1 pl-1 py-2 lg:py-0 border-gray-100 lg:p-0 dark:text-white lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    How It Works!
                  </Link>
                </li>

                <li className="p-1 my-1 md:my-0 text-lg mx-auto relative md:pr-5">
                  <Link
                    to={"/service"}
                    className={`${isActive("/service") ? "text-themecolor dark:text-yellow-600" : "text-black"
                      } flex items-center text-sm pr-1 pl-1 py-2 lg:py-0 border-gray-100 lg:p-0 dark:text-white lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    Service
                  </Link>
                </li>


                {!(Type && Id) && (
                  <li className="p-1 md:my-0 text-lg my-2 hover:bg-[#173559] bg-themecolor text-center rounded-full py-2 px-3">
                    <Link
                      to="/sign-up/advertiser"
                      className={`${isActive("/sign-up/advertiser") ? "text-themecolor dark:text-yellow-600" : "text-black"
                        }
                      block text-sm font-normal md:flex items-center px-2 rounded text-white dark:text-white lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                    >
                      <FaSignInAlt className="mr-2" /> Sign up as an Advertiser
                    </Link>
                  </li>
                )}
                {Type && Id ? (
                  <li className="p-1 md:my-0 text-lg my-2 hover:bg-[#173559] bg-themecolor text-center rounded-full py-2 px-3">
                    <Link to={
                      Type === "I" ? "/profile/influencer" :
                        Type === "M" ? "/profile/model" :
                          Type === "A" ? "/profile/artist" :
                            "/dashboard" // Default route if Type doesn't match
                    }>
                      <div className="block text-sm font-normal md:flex items-center px-2 ps-1 rounded text-white dark:text-gray-300 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
                        <FaUser className="inline-block mr-2" />
                        <span>My Profile</span>
                      </div>
                    </Link>
                  </li>
                ) : (
                  <li className="p-1 md:my-0 text-lg my-2 bg-[#173559] hover:bg-themecolor text-center rounded-full py-2 px-3">
                    <Link to="/sign-up">
                      <button
                        type="button"
                        className="block text-sm font-normal md:flex items-center px-2 rounded text-white dark:text-white lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        <span className="uppercase text-xs font-semibold me-1">Join Now: - </span>
                        Influencer | Model | Artist
                      </button>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;