import React, { useEffect, useState } from "react";
import {FaFacebookF, FaInstagram, FaLinkedin,FaWhatsapp,FaYoutube} from "react-icons/fa";
import logo from "../../Images/logo.png";
import loader from "../../Images/loaderimage.webp";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { IoMdMail } from "react-icons/io";
import SunImg from "../../Images/sun.png";
import MoonImg from "../../Images/night.png";

const Footer = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme === "dark" : false;
  });

  useEffect(() => {
    // Apply the theme to the body element
    document.body.className = isDarkMode ? "dark" : "";
    // Save the theme to localStorage
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <>
      <div className="bottom-10 lg:left-8 left-4 fixed z-10">
        <button
          onClick={() => setIsDarkMode(!isDarkMode)}
          className="flex items-center justify-center p-1 border bg-white border-slate-500 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-gray-900 dark:text-gray-300"
        >
          {isDarkMode ? (
            <img src={SunImg} alt="" className="w-5" />
          ) : (
            <img src={MoonImg} alt="" className="w-5" />
          )}
          <span className="sr-only">
            {isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
          </span>
        </button>
      </div>
      <div className="relative">
        <div className="bg-white dark:bg-slate-950 border-t">
          <div className="container mx-auto px-4 pb-3 md:pb-0 pt-8 md:p-5">
            <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2">
              <div className="text-black dark:text-gray-300 col-span-1 md:mx-auto md:my-5">
              <Link to={"/"}>
                <img
                  src={logo}
                  alt=""
                  className="w-52 mx-auto bg-white rounded-md mb-5"
                />
              </Link>
                <div className=" text-black dark:text-white text-center flex   items-center mb-4">
                  <h1 className="border border-black dark:border-white rounded-full flex items-center justify-center p-1.5">
                    <IoMdMail className="text-xl" />
                  </h1>
                  <a
                    href="mailto:connect@viralkar.com"
                    className="text-left ps-2 text-sm"
                  >
                    connect@viralkar.com
                  </a>
                </div>
                <div className="mt-5 mb-6 md:mt-7 md:mb-4 lg:mb-0 text-black xl:mx-auto text-left">
                  <p className="text-black dark:text-white font-semibold mb-2 border-b-2 border-themecolor table underline-offset-8 text-lg">
                    Follow Us
                  </p>
                  <div className="my-auto flex mx-auto mt-5">
                  <Link
                      to={"https://www.instagram.com/viralkar.official/"}
                      target="_blank"
                    >
                      <h2 className="bg-instagram text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                        <FaInstagram />
                      </h2>
                    </Link>                

                    <Link
                      to={"https://www.facebook.com/viralkarr/"}
                      target="_blank"
                    >
                      <h2 className="bg-[#0165E1] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                        <FaFacebookF />
                      </h2>
                    </Link>
                    <Link to={"https://x.com/viral_kar_"} target="_blank">
                      <h2 className="bg-black text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                        <FaXTwitter />
                      </h2>
                    </Link>
                    <Link
                      to={"https://www.youtube.com/@ViralKarr"}
                      target="_blank"
                    >
                      <h2 className="bg-red-600 text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                        <FaYoutube />
                      </h2>
                    </Link>
                    <Link
                      to={"https://www.linkedin.com/company/viralkar"}
                      target="_blank"
                    >
                      <h2 className="bg-[#27A7E7] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                        <FaLinkedin />
                      </h2>
                    </Link>
                    <Link
                      to={"https://www.whatsapp.com/channel/0029Van2Z041iUxRfvDuN02u"}
                      target="_blank"
                    >
                      <h2 className="bg-[#52c960] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                        <FaWhatsapp />
                      </h2>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="text-black dark:text-white col-span-1 md:mx-auto md:my-5">
                <span className="font-semibold md:text-xl text-lg border-b-2 border-themecolor">
                  Reading Links
                </span>
                <div className="md:mt-6 py-2 md:py-0">
                  <p className="my-2">
                    <Link to={"/about-us"}  className="text-sm text-gray-600 dark:text-gray-300 font-medium hover:text-themecolor cursor-pointer">
                      About Us
                    </Link>
                  </p>
                  <p className="my-2">
                  <Link to={"/service"} className="text-sm text-gray-600 dark:text-gray-300 font-medium hover:text-themecolor cursor-pointer">
                      Services
                    </Link>
                  </p>
                  {/* <p className="text-sm text-gray-600 font-medium dark:text-gray-300 hover:text-themecolor cursor-pointer my-2">
                    <Link to={"/plan"}>Plan</Link> 
                  </p> */}
                  <p className="text-sm text-gray-600 font-medium dark:text-gray-300 hover:text-themecolor cursor-pointer my-2">
                  <Link to={"/faq"}>FAQ</Link>
                  </p>
                  <p className="text-sm text-gray-600 font-medium dark:text-gray-300 hover:text-themecolor cursor-pointer my-2">
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>
                  </p>
                  <p className="text-sm text-gray-600 font-medium dark:text-gray-300 hover:text-themecolor cursor-pointer my-2">
                  <Link to={"/terms-codtion"}>Terms & Conditions</Link>
                  </p>
                </div>
              </div>
              <div className="text-black dark:text-gray-300 col-span-1 md:mx-auto md:my-5">
                <span className="font-semibold md:text-xl text-lg border-b-2 border-themecolor">
                Talent Searches
                </span>
                <div className="md:mt-6 py-2 md:py-0">
                  <p className="my-2">
                    <Link to={"/find-influencer"} className="text-sm text-gray-600 dark:text-gray-300 font-medium hover:text-themecolor cursor-pointer">
                      Find Influencer
                    </Link>
                  </p>
                  <p className="my-2">
                    <Link to={"/find-model"} className="text-sm text-gray-600 dark:text-gray-300 font-medium hover:text-themecolor cursor-pointer">
                      Find Model
                    </Link>
                  </p>
                  <p className="my-2">
                    <Link to={"/find-artist"}  className="text-sm text-gray-600 dark:text-gray-300 font-medium hover:text-themecolor cursor-pointer">
                      Find Artist
                    </Link>
                  </p>
                  <p className="my-2">
                    <Link to={"/blog"}  className="text-sm text-gray-600 dark:text-gray-300 font-medium hover:text-themecolor cursor-pointer">
                    Blog
                    </Link>
                  </p>
                  
                </div>
              </div>
              <div className="text-black dark:text-gray-300 col-span-1 md:mx-auto md:my-5">
                <span className="font-semibold md:text-xl text-lg border-b-2 border-themecolor">
                  Reach Us
                </span>
                <div className="md:mt-6 py-2 md:py-0">
                  <p className="my-2">
                  <Link to={"/model-register"}  className="text-sm text-gray-600 dark:text-gray-300 font-medium hover:text-themecolor cursor-pointer">
                      Sign Up as Model
                  </Link>
                  </p>
                  <p className="my-2">
                  <Link to={"/influencer-register"}  className="text-sm text-gray-600 dark:text-gray-300 font-medium hover:text-themecolor cursor-pointer">
                      Sign Up as Influencer
                    </Link>
                  </p>
                  <p className="my-2">
                  <Link to={"/artist-register"}  className="text-sm text-gray-600 dark:text-gray-300 font-medium hover:text-themecolor cursor-pointer">
                      Sign Up as Artist
                    </Link>
                  </p>
                  <p className="my-2">
                  <Link to={"/contact"}  className="text-sm text-gray-600 dark:text-gray-300 font-medium hover:text-themecolor cursor-pointer">
                      Contact Us
                  </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-themebgcolor3 loader-first1">
          <p className="text-balance font-normal text-white py-2 text-center text-xs flex items-center justify-center flex-col md:flex-row">
            © 2024 | All right Reserved | Design & Developed By{" "}
            <a
              href="https://seawindsolution.com/"
              target="_blank"
              className="ml-1"
            >
              Seawind Solution Pvt. Ltd.{" "}
              <img src={loader} className="animate-rotate w-16" alt="" /> 
            </a>
          </p>
        </div>
      </div>
      <div className="fixed top-[33%] right-0 z-10 inline-grid grid-row-5 gap-0">
        <a
          href="https://www.instagram.com/viralkar.official/"
          target="_blank"
          className="flex-row bg-instagram text-white rounded-l-full mb-2 p-2 uppercase transition-transform duration-700"
        >
          <FaInstagram className="bg-white text-[#FD1D1D] rounded-full h-8 w-8 mr-0 p-2 transition-transform duration-500 hover:rotate-[360deg]" />
        </a>

        <a
          href="https://www.facebook.com/viralkarr/"
          target="_blank"
          className="flex-row bg-[#0165E1] text-white rounded-l-full mb-2 p-2 uppercase transition-transform duration-700 "
        >
          <FaFacebookF className="bg-white text-[#2C80D3] rounded-full h-8 w-8 mr-05 p-2 transition-transform duration-500 hover:rotate-[360deg]" />
        </a>
        <a
          href="https://x.com/viral_kar_"
          target="_blank"
          className="flex-row bg-black text-white rounded-l-full mb-2 p-2 uppercase transition-transform duration-700 transform "
        >
          <FaXTwitter className="bg-white text-black rounded-full h-8 w-8 mr-0 p-2 transition-transform duration-500 hover:rotate-[360deg]" />
        </a>
        <a
          href="https://www.youtube.com/@ViralKarr"
          target="_blank"
          className="flex-row bg-[#FA0910] text-white rounded-l-full mb-2 p-2 uppercase transition-transform duration-700 transform "
        >
          <FaYoutube className="bg-white text-[#FA0910] rounded-full h-8 w-8 mr-0 p-2 transition-transform duration-500 hover:rotate-[360deg]" />
        </a>

        <a
          href="https://www.linkedin.com/company/viralkar"
          target="_blank"
          className="flex-row bg-[#27A7E7] text-white rounded-l-full mb-2 p-2 uppercase transition-transform duration-700 transform "
        >
          <FaLinkedin className="bg-white text-[#27A7E7] rounded-full h-8 w-8 mr-0 p-2 transition-transform duration-500 hover:rotate-[360deg]" />
        </a>
        <a
          href="https://www.whatsapp.com/channel/0029Van2Z041iUxRfvDuN02u"
          target="_blank"
          className="flex-row bg-[#52c960] text-white rounded-l-full mb-2 p-2 uppercase transition-transform duration-700 transform "
        >
          <FaWhatsapp className="bg-white text-[#52c960] rounded-full h-8 w-8 mr-0 p-1.5 transition-transform duration-500 hover:rotate-[360deg]" />
        </a>

      </div>
    </>
  );
};

export default Footer;
