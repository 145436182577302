import React, { useEffect, useRef, useState } from "react";
import FemaleInnerbanner from "../../Images/contact-back.jpg";
import BreadCrumb from "../../Breadcumb/Breadcumb";
import {FaFacebookF, FaInstagram, FaLinkedin, FaTiktok, FaWhatsapp, FaYoutube, } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import { AddVendorInquiry } from "../../API/ModelInfMain";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../common/FormLoader";
import ScrollToTop from "./ScrollToTop";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../usepageSEO/Index";
import { getArtistByCityNameandUserName } from "../../API/NewInfluencerAPI";
import { IoIosCall } from "react-icons/io";

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Name cannot contain numbers")
    .required("Name is required"),

  Email: Yup.string().email("Invalid email").required("Email is required"),

  Phone: Yup.string()
    .matches(/^[0-9]+$/, "Invalid mobile number")
    .required("Number is required"),
  Message: Yup.string().required("Message is required"),
});

const ArtistDetail = () => {
  const { CityName, ArtistName } = useParams();
  const [ArtistData, setArtistData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const messageSectionRef = useRef(null);

  const getData = async () => {
    try {
      const results = await getArtistByCityNameandUserName(CityName, ArtistName);
      setArtistData(results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [CityName, ArtistName]);

  usePageSEO(
    ArtistData ? `${ArtistData.Name}| Viral kar` : "Viral kar | Artist", // Use page title for SEO if available
    ArtistData ? `${ArtistData.Name}| Viral kar` : "Viral kar | Artist", // Use page description for SEO if available
    [ArtistData ? `${ArtistData.Name}| Viral kar` : "Viral kar | Artist"] // No keywords provided in this example
  );

  const [isFormLoading, setIsFormLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      VendorId: ArtistName,
      Name: "",
      Email: "",
      Phone: "",
      Message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await AddVendorInquiry(values);
        if (response.status === true) {
          actions.resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const breadcrumbItems = [
    {
      text: ArtistData?.Name,
    },
  ];
  const backgroundImage = FemaleInnerbanner;
  return (
    <div>
      <div>
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <BreadCrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
        <div className=" dark:bg-darkmode dark:text-white pt-10 pb-10">
          <div className="container mx-auto px-2 xl:px-0 lg:px-4">
            {loading ? (
              <div className="flex justify-center items-center my-40">
                <ClipLoader color={"#d00067"} loading={loading} size={40} />
              </div>
            ) : (
              <React.Fragment>
                 
                <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-10">
                  <div className=" mb-4 md:mb-0 col-span-2">
                    <div className="models-box relative">
                      <div className="relative overflow-hidden">
                        <img
                          src={ArtistData?.Path + ArtistData?.ProfileImage}
                          alt=""
                          className="md:h-[400px] w-full md:object-contain object-cover lg:object-center border rounded-md border-gray-200 bg-white p-2"
                        />
                        <div className="absolute bottom-1.5 right-1.5 flex flex-col">
                          <Link
                            to={
                              ArtistData?.Instragram || "https://instagram.com"
                            }
                            className="mb-1 pointer-events-none"
                          >
                            <h2 className="bg-instagram  duration-200 text-white p-2.5 text-lg  drop-shadow-lg">
                              <FaInstagram />
                            </h2>
                          </Link>
                          <Link
                            to={ArtistData?.Facebook || "https://facebook.com"}
                            className="mb-1 pointer-events-none"
                          >
                            <h2 className="bg-[#0165E1] duration-200 text-white p-2.5 text-lg  drop-shadow-lg">
                              <FaFacebookF />
                            </h2>
                          </Link>
                          <Link
                            to={ArtistData?.Twitter || "https://x.com"}
                            className="mb-1 pointer-events-none"
                          >
                            <h2 className="bg-black duration-200 text-white p-2.5 text-lg  drop-shadow-lg">
                              <FaXTwitter />
                            </h2>
                          </Link>

                          <Link
                            to={ArtistData?.YouTube || "https://youtube.com"}
                            className="mb-1 pointer-events-none"
                          >
                            <h2 className="bg-red-600  duration-200 text-white p-2.5 text-lg  drop-shadow-lg">
                              <FaYoutube />
                            </h2>
                          </Link>
                          <Link
                            to={ArtistData?.LinkedIn || "https://linkedin.com/"}
                            className="mb-1 pointer-events-none"
                          >
                            <h2 className="bg-[#27A7E7] duration-200 text-white p-2.5 text-lg  drop-shadow-lg">
                              <FaLinkedin />
                            </h2>
                          </Link>

                          <Link
                            className="pointer-events-none"
                            to={
                              ArtistData?.TikTok || "https://www.tiktok.com/en/"
                            }
                          >
                            <h2 className="bg-black  duration-200 text-white p-2.5 text-lg  drop-shadow-lg">
                              <FaTiktok />
                            </h2>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-3 ">
                    <div className="grid grid-cols-1 xl:gap-4 lg:gap-0">
                      <div className="">
                        <div className="bg-white rounded-lg dark:border-gray-100 shadow-lg dark:bg-zinc-800 border dark:text-white">
                          <table className="table-auto w-full">
                            <tbody>
                              {/* Field array for conditional rendering */}
                              {[
                                { label: "Name", value: ArtistData?.Name },
                                {
                                  label: "Artist Name",
                                  value: ArtistData?.ArtistName,
                                },
                                {
                                  label: "DOB",
                                  value: ArtistData?.DOB
                                    ? new Date(
                                        ArtistData?.DOB
                                      ).toLocaleDateString("en-GB")
                                    : "",
                                },
                                { label: "City", value: ArtistData?.CityName },
                                {
                                  label: "Category",
                                  value:
                                    ArtistData?.ChildCategoryNames?.join(", "),
                                },
                                {
                                  label: "Collaborations",
                                  value:
                                    ArtistData?.CollaborationNames?.join(", "),
                                },
                                {
                                  label: "Exhibitions",
                                  value: ArtistData?.Exhibitions,
                                },
                                {
                                  label: "Experience",
                                  value: ArtistData?.Experience,
                                },
                                {
                                  label: "Previous Projects",
                                  value: ArtistData?.PreviousProjects,
                                },
                                {
                                  label: "Collaboration Industries",
                                  value: ArtistData?.CollaborationIndustries,
                                },
                                { label: "Awards", value: ArtistData?.Awards },
                                {
                                  label: "Notable Works",
                                  value: ArtistData?.NotableWorks,
                                },
                              ]
                                .filter((field) => field.value)
                                .map((field, index, arr) => (
                                  <tr
                                    key={index}
                                    className={`flex flex-col md:flex-row md:justify-between p-2 ${
                                      index < arr.length - 1 ? "border-b" : ""
                                    }`}
                                  >
                                    <td className="text-left text-md font-medium text-black dark:text-white my-auto md:w-1/3">
                                      {field.label}:
                                    </td>
                                    <td className="my-auto text-sm text-gray-600 dark:text-gray-300 md:w-2/3">
                                      {field.value}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    {ArtistData?.Bio && (
                      <div className="dark:bg-zinc-800 dark:text-black border border-gray-200 shadow-md rounded-md p-4 mt-5">
                        <div className="flex justify-center space-x-2">
                          <p className="text-md text-gray-500 dark:text-white">
                            {ArtistData?.Bio}
                          </p>
                        </div>
                      </div>
                    )}

                      <div className="grid grid-cols-2 lg:grid-cols-2 mt-4 lg:gap-10">
                      <div className="lg:ml-auto">
                        <button
                          type="button" // Change from "submit" to "button"
                          onClick={() =>
                            messageSectionRef.current.scrollIntoView({
                              behavior: "smooth",
                            })
                          }
                          className="bg-themebgcolor3 flex p-3 rounded-full lg:px-6 px-4 mx-auto block font-medium lg:text-sm text-xs mt-4 border text-white hover:bg-black"
                        >
                          <IoIosCall className="text-xl my-auto  me-1"/>
                          Contact Artist
                        </button>
                      </div>
                      <div className="lg:mr-auto">
                        <Link
                          to={
                            "https://www.whatsapp.com/channel/0029Van2Z041iUxRfvDuN02u"
                          }
                          target="_black"
                        >
                          <button
                            type="submit"
                            className="bg-[#25D366] p-3 rounded-full px-6 flex mx-auto block font-medium lg:text-sm text-xs mt-4 border  text-white hover:bg-black"
                          >
                              <FaWhatsapp  className="text-xl my-auto  me-1"/>
                            WhatsApp
                          </button>
                        </Link>
                      </div>
                    </div>


                  </div>
                </div>
              </React.Fragment>
            )}


              <div ref={messageSectionRef} className="md:w-3/5 mx-auto">
              <div className="dark:bg-zinc-800 border border-gray-200 shadow-md rounded-md p-6 mt-10">
                <h1 className="md:text-2xl text-center font-semibold mb-5">
                  Hire {ArtistData?.Name}
                </h1>
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
                    <div>
                      <label for="">
                        Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Name}
                        className="p-3 w-full dark:bg-neutral-500 dark:border-0 border rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black dark:text-white placeholder:text-sm"
                        placeholder="Enter Your Name"
                      />
                      {formik.touched.Name && formik.errors.Name ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Name}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <label for="">
                        Email <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="email"
                        name="Email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Email}
                        className="p-3 w-full dark:bg-neutral-500 dark:border-0 border rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black dark:text-white placeholder:text-sm"
                        placeholder="Enter Your Email"
                      />
                      {formik.touched.Email && formik.errors.Email ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Email}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-5">
                    <label for="">
                      Phone <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="Phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Phone}
                      className="p-3 w-full dark:bg-neutral-500 dark:border-0 border rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black dark:text-white placeholder:text-sm"
                      placeholder="Enter Your Mobile"
                    />
                    {formik.touched.Phone && formik.errors.Phone ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Phone}
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-5">
                    <label for="">
                      Message <span className="text-red-500">*</span>
                    </label>
                    <textarea
                      name="Message"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Message}
                      rows="4"
                      className="w-full p-3 dark:bg-neutral-500 dark:border-0 border rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black dark:text-white placeholder:text-sm"
                      placeholder="Message"
                    ></textarea>
                    {formik.touched.Message && formik.errors.Message ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Message}
                      </div>
                    ) : null}
                  </div>
                  <button
                    type="submit"
                    className="bg-themecolor p-3 rounded-full px-8 mx-auto block font-medium text-sm mt-4 border  text-white hover:bg-black"
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistDetail;
