/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DashboardMenu from "../../DashboardMenu";
import ScrollToTop from "../ScrollToTop";
import { useFormik } from "formik";
import FormLoader from "../../../common/FormLoader";
import Config from "../../../API/Config";
import {
  ArtistGetById,
  UpdateModelInfById,
} from "../../../API/ArtistSignUpAPI";
import { useData } from "../../../Context/DataContext ";
import { format } from "date-fns";
import usePageSEO from "../../usepageSEO/Index";
import { getAllCategory } from "../../../API/CategoryAPi";
import { getAllCollbration } from "../../../API/CollabrationAPi";

const ArtistProfile = () => {
  usePageSEO("Viral kar | Dashboard", "Viral kar | Dashboard", [
    "Viral kar | Dashboard",
  ]);

  const [ThumbImagePreview, setThumbImagePreview] = useState();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const Id = Config.getId(); // Assuming Config is defined and imported properly
  const Status = Config.getStatus();
  const { CityData } = useData(); // Assuming useData provides the necessary data

  // Formik Initialization
  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Phone: "",
      DOB: "",
      Gender: "",
      CityId: "",
      Instagram: "",
      Facebook: "",
      Youtube: "",
      Twitter: "",
      Blog: "",
      Roposo: "",
      MXTakatak: "",
      ProfileImage: "",
     
      
      HidImg: "",
      Category: [],
      CollaborationNames: [],
      CollaborationsType: [],
      Bio: "",
      Experience: "",
      PreviousProjects: "",
      Awards: "",
      NotableWorks: "",
      CollaborationIndustries: "",
      AvailableforTravel: "",
    },

    onSubmit: async (values) => {
      setIsFormLoading(true);
      try {
        const formData = new FormData();   
        const result = await UpdateModelInfById(Id, formData);
        FatchData(); // Refetch data after submission to refresh form values
      } catch (error) {
        console.error("Error updating model information:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  // Fetch data function
  const FatchData = async () => {
    try {
      const result = await ArtistGetById(Id);

      // Check and set profile image
      if (result.ProfileImage) {
        setThumbImagePreview(result.Path + result.ProfileImage);
      }

      // Format the date correctly for the form
      const parsedDate = result.DOB ? new Date(result.DOB) : new Date();
      const formattedDate = format(parsedDate, "yyyy-MM-dd");

      // Set formik values with the fetched data
      formik.setValues({
        Name: result.Name || "",
        Email: result.Email || "",
        Phone: result.Phone || "",
        DOB: formattedDate || "",
        Gender: result.Gender || "",
        CityId: result.CityId || "",
        Instagram: result.Instagram || "",
        Facebook: result.Facebook || "",
        Youtube: result.YouTube || "",
        Twitter: result.Twitter || "",
        Blog: result.Blog || "",
        Roposo: result.Roposo || "",
        MXTakatak: result.MXTakatak || "",
        ProfileImage: result.ProfileImage || "", 
        HidImg: result.Hid_Image || "",
        Category: result.Category || [],
        CollaborationNames: result.CollaborationNames || "",
        CollaborationsType: result.CollaborationsType || [],
        Bio: result.Bio || "",
        Experience: result.Experience || "",
        PreviousProjects: result.PreviousProjects || "",
        Awards: result.Awards || "",
        NotableWorks: result.NotableWorks || "",
        CollaborationIndustries:result.CollaborationIndustries||"",
        AvailableforTravel: result.AvailableforTravel || "",
      });

      sessionStorage.setItem("getvendorStatus", result.Status);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    FatchData();
  }, [Id]);

  useEffect(() => {
    if (ThumbImagePreview) {
      sessionStorage.setItem("ProfileImage", ThumbImagePreview);
    }
  }, [ThumbImagePreview]);

  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryResult] = await Promise.all([getAllCategory()]);
        setCategoryData(categoryResult);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleCheckboxChange = async (event) => {
    const { value, checked } = event.target;
    const { Category } = formik.values;

    // Convert value to string
    const stringValue = value;

    if (checked) {
      // Add value to array if not already present and if the limit is not reached
      if (!Category.includes(stringValue) && Category.length < 5) {
        formik.setFieldValue("Category", [...Category, stringValue]);
      }
    } else {
      // Remove value from array
      formik.setFieldValue(
        "Category",
        Category.filter((category) => category !== stringValue)
      );
    }

    // Manually trigger validation
    await formik.validateField("Category");
  };

  const [CollaborationData, setCollaborationData] = useState([]);
  useEffect(() => {
    const fetchCollaborationData = async () => {
      try {
        const [CollaborationResult] = await Promise.all([getAllCollbration()]);
        setCollaborationData(CollaborationResult);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCollaborationData();
  }, []);
  const handleLanguageCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const { CollaborationsType } = formik.values;

    if (checked) {
      if (!CollaborationsType.includes(value)) {
        formik.setFieldValue("CollaborationsType", [
          ...CollaborationsType,
          value,
        ]);
      }
    } else {
      const newCollaborationsType = CollaborationsType.filter(
        (id) => id !== value
      );
      if (value === "Other") {
        formik.setFieldValue("OtherCollaborationsType", "");
      }
      formik.setFieldValue("CollaborationsType", newCollaborationsType);
    }
  };

  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="">
        <ScrollToTop />
        <div className="container mx-auto py-20">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-10">
            <div className="">
              <div className="mb-5 md:mb-0 sticky top-24">
                <DashboardMenu />
              </div>
            </div>

            <div className="col-span-3">
              <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4">
                <div className="bg-dark-light">
                  <form onSubmit={formik.handleSubmit}>
                    <input
                      type="hidden"
                      name="HidImg"
                      value={formik.values.HidImg}
                    />
                    <div className="">
                      <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
                        {Status === 0 ? (
                          <div
                            class="bg-orange-100 border-t-2 mb-4 text-sm border-orange-500 rounded-b text-orange-900 px-4 py-2 shadow-md"
                            role="alert"
                          >
                            <div class="flex justify-center">
                              <p class="font-semibold text-sm">
                                Your Profile is Under Review
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div
                            class="bg-green-100 border-t-4 mb-4 text-sm border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
                            role="alert"
                          >
                            <div class="flex justify-center">
                              <p class="font-bold">Your Profile is Approved</p>
                            </div>
                          </div>
                        )}
                        <p className="text-center text-xl py-1.5 mb-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                          Details
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                          <div className="my-1">
                            <label className="text-base" for="">
                              Name <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="Name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Name}
                              placeholder="Name"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.Name && formik.errors.Name ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Name}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">
                              Email
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="email"
                              name="Email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Email}
                              disabled
                              placeholder="Email Address"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.Email && formik.errors.Email ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Email}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">
                              Phone <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="Phone"
                              disabled
                              readOnly
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Phone}
                              placeholder="Phone"
                              className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.Phone && formik.errors.Phone ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Phone}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">
                              DOB <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="date"
                              name="DOB"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.DOB}
                              placeholder="DOB"
                              className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                            />
                            {formik.touched.DOB && formik.errors.DOB ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.DOB}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">
                              Profile Picture{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="file"
                              name="ProfileImage"
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "ProfileImage",
                                  event.currentTarget.files[0]
                                );
                              }}
                              onBlur={formik.handleBlur}
                              placeholder="Business Country"
                              className="text-sm text-grey-500 file:mr-5  mt-3 file:py-1.5 file:px-5 file:rounded-full file:border-0 file:text-md file:font-semibold  file:text-white
                              file:bg-gradient-to-r file:from-themecolor file:to-black hover:file:cursor-pointer hover:file:opacity-80 py-1.5 w-full focus:outline-none border-0 rounded"
                            />
                            {formik.touched.ProfileImage &&
                            formik.errors.ProfileImage ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.ProfileImage}
                              </div>
                            ) : null}
                          </div>
                         
                          <div className="my-1">
                            <label className="text-base">
                              Select City{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <select
                              name="CityId"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.CityId}
                              className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            >
                              {CityData?.map((val, index) => {
                                return (
                                  <option key={index} value={val.Id}>
                                    {val.Title}
                                  </option>
                                );
                              })}
                            </select>
                            {formik.touched.CityId && formik.errors.CityId ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.CityId}
                              </div>
                            ) : null}
                          </div>

                          <div className="my-1">
                            <label className="text-base">
                              Gender <span className="text-red-500">*</span>
                            </label>
                            <div className="flex items-center mt-2 dark:bg-[#1e1e1e] px-2 py-2.5">
                              <div className="mr-3">
                                <input
                                  type="radio"
                                  id="Male"
                                  name="Gender"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value="M"
                                  checked={formik.values.Gender === "M"}
                                  className="mr-2"
                                />
                                <label htmlFor="Male">Male</label>
                              </div>
                              <div className="">
                                <input
                                  type="radio"
                                  id="Female"
                                  name="Gender"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value="F"
                                  checked={formik.values.Gender === "F"}
                                  className="mr-3"
                                />
                                <label htmlFor="Female">Female</label>
                              </div>
                            </div>

                            {formik.touched.Gender && formik.errors.Gender ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Gender}
                              </div>
                            ) : null}
                          </div>  
                        </div>
                        <p className="text-center text-xl py-1.5 my-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                          Choose Your Category
                        </p>

                        <div className="grid grid-cols-1 md:grid-cols-4">
                          {categoryData[2]?.ChildCat?.map((val, index) => (
                            <div className="flex items-center my-2" key={index}>
                              <input
                                type="checkbox"
                                className="mr-2"
                                name="Category"
                                value={val.Id}
                                checked={formik.values.Category.includes(
                                  val.Id.toString()
                                )}
                                id={val.Title}
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="text-sm text-gray-500 font-normal"
                                htmlFor={val.Title}
                              >
                                {val.Title}
                              </label>
                            </div>
                          ))}
                        </div>

                        <div className="mt-2 mb-2">
                          <label className="text-base" for="">
                            Artistic Description/Bio
                          </label>
                          <textarea
                            type="text"
                            name="Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Bio}
                            placeholder="Bio"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                        </div>
                        <p className="text-center text-xl py-1.5 my-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                          Experience and Achievements
                        </p>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                          <div className="my-auto">
                            <div className="mt-2 mb-2">
                              <label className="text-base" for="">
                                Artistic Description/Bio
                              </label>
                              <input
                                type="text"
                                name="Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Experience}
                                placeholder="Experience"
                                className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                              />
                            </div>
                          </div>

                          <div className="">
                            <div className="mt-2 mb-2">
                              <label className="text-base" for="">
                                Previous Collaborations/Projects
                              </label>
                              <textarea
                                type="text"
                                name="Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.PreviousProjects}
                                placeholder="PreviousProjects"
                                className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                              />
                            </div>
                          </div>

                          <div className="">
                            <div className="mt-2 mb-2">
                              <label className="text-base" for="">
                                Awards and Recognitions
                              </label>
                              <textarea
                                type="text"
                                name="Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Awards}
                                placeholder="Awards"
                                className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                              />
                            </div>
                          </div>

                          <div className="">
                            <div className="mt-2 mb-2">
                              <label className="text-base" for="">
                                Notable Works
                              </label>
                              <textarea
                                type="text"
                                name="Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.NotableWorks}
                                placeholder="NotableWorks"
                                className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                              />
                            </div>
                          </div>
                        </div>

                        <p className="text-center text-xl py-1.5 my-3 bg-themebgcolor3 text-white dark:text-white rounded-lg">
                          Collaboration Preferences
                        </p>

                        {/* Collaboration Types */}
                        <div className="my-1">
                          <div className="grid grid-cols-1 md:grid-cols-4">
                            {CollaborationData[2]?.ChildCat?.map((val) => (
                              <div
                                className="flex items-center my-1"
                                key={val.Id}
                              >
                                <input
                                  type="checkbox"
                                  className="mr-2"
                                  name="CollaborationsType"
                                  value={val.Id}
                                  checked={formik.values.CollaborationsType.includes(
                                    val.Id.toString()
                                  )}
                                  id={`collab-${val.Id}`}
                                  onChange={handleLanguageCheckboxChange}
                                />
                                <label
                                  className="text-sm text-gray-500 font-normal"
                                  htmlFor={`collab-${val.Id}`}
                                >
                                  {val.Title}
                                </label>
                              </div>
                            ))}
                            <div className="flex items-center my-1">
                              <input
                                type="checkbox"
                                className="mr-2"
                                name="CollaborationsType"
                                value="Other"
                                id="collab-other"
                                checked={formik.values.CollaborationsType.includes(
                                  "Other"
                                )}
                                onChange={handleLanguageCheckboxChange}
                              />
                              <label
                                className="text-sm text-gray-500 font-normal"
                                htmlFor="collab-other"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                        </div>
                        {formik.touched.CollaborationsType &&
                          formik.errors.CollaborationsType && (
                            <div className="text-red-500 text-sm">
                              {formik.errors.CollaborationsType}
                            </div>
                          )}
                        {formik.values.CollaborationsType.includes("Other") && (
                          <div className="my-2">
                            <input
                              type="text"
                              placeholder="Please specify"
                              className={`w-full border rounded-lg p-2 ${
                                formik.touched.OtherCollaborationsType
                                  ? "border-gray-300"
                                  : "border-gray-300"
                              }`}
                              onChange={formik.handleChange}
                              value={formik.values.OtherCollaborationsType}
                              name="OtherCollaborationsType"
                            />
                          </div>
                        )}

                        <div className="grid grid-cols-1 lg:grid-cols-2 my-4">
                          <div className="my-auto">
                            <div className="my-1">
                              <label className="text-base">
                                Available for Travel{" "}
                                
                              </label>
                              <div className="flex items-center mt-2 dark:bg-[#1e1e1e] px-2 py-2.5">
                                {["Yes", "No"].map((option) => (
                                  <div className="mr-3" key={option}>
                                    <input
                                      type="radio"
                                      id={`available-${option}`}
                                      name="AvailableforTravel"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={option}
                                      checked={
                                        formik.values.AvailableforTravel ===
                                        option
                                      }
                                      className="mr-2"
                                    />
                                    <label htmlFor={`available-${option}`}>
                                      {option}
                                    </label>
                                  </div>
                                ))}
                              </div>
                              {formik.touched.AvailableforTravel &&
                              formik.errors.AvailableforTravel ? (
                                <div className="text-red-500 text-sm">
                                  {formik.errors.AvailableforTravel}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="">
                          <div className="">
                            <div className="mt-2 mb-2">
                              <label className="text-base" for="">
                              Preferred Brands/Industries
                              </label>
                              <input
                                type="text"
                                name="Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.CollaborationIndustries}
                                placeholder="CollaborationIndustries"
                                className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-3 border border-zinc-200 rounded-md dark:bg-[#020617]"
                              />
                            </div>
                          </div>
                          </div>
                        </div>

                        <p className="text-center text-xl py-1.5 mb-3 bg-themebgcolor3 text-white dark:text-white rounded-lg mt-4">
                          Social Media Details
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                          <div className="my-1">
                            <label for="">
                              Instagram <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="Instagram"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Instagram}
                              placeholder="Instagram"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.Instagram &&
                            formik.errors.Instagram ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Instagram}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">
                              Facebook <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="Facebook"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Facebook}
                              placeholder="Facebook"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.Facebook &&
                            formik.errors.Facebook ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Facebook}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">
                              Youtube <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="Youtube"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Youtube}
                              placeholder="Youtube"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.Youtube && formik.errors.Youtube ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Youtube}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">
                              Twitter <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              name="Twitter"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Twitter}
                              placeholder="Twitter"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.Twitter && formik.errors.Twitter ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Twitter}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">Blog</label>
                            <input
                              type="text"
                              name="Blog"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Blog}
                              placeholder="Blog"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.Blog && formik.errors.Blog ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Blog}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">Roposo</label>
                            <input
                              type="text"
                              name="Roposo"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.Roposo}
                              placeholder="Roposo"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.Roposo && formik.errors.Roposo ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.Roposo}
                              </div>
                            ) : null}
                          </div>
                          <div className="my-1">
                            <label for="">MXTakatak</label>
                            <input
                              type="text"
                              name="MXTakatak"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.MXTakatak}
                              placeholder="MXTakatak"
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched.MXTakatak &&
                            formik.errors.MXTakatak ? (
                              <div className="text-red-500 text-sm">
                                {formik.errors.MXTakatak}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="flex justify-center my-3">
                          <button
                            type="submit"
                            className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-10"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistProfile;
