import axios from "axios";
import Config from "./Config";
const { API_BASE_URL } = Config;

// --------------------------------get Influencer by Category-----------------------
export const getInfluencerBySubCategoryId = async (CategoryId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/influencer/category/${CategoryId}`
    );
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get Model by Category-----------------------
export const getModelBySubCategoryId = async (CategoryId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/model/category/${CategoryId}`
    );
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get Artist by Category-----------------------
export const getArtistBySubCategoryId = async (CategoryId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/model/category/${CategoryId}`
    );
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
// --------------------------------get Influencer by CityName and UserName-----------------------
export const getInfluencerByCityNameandUserName = async (CityName, UserName) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/influencer/${CityName}/${UserName}`
    );
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
// --------------------------------get popular-influencer by Category-----------------------
export const getPopulatInfluencer = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/popular-influencer`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get popular-influencer by Category-----------------------
export const getAllInfluencer = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/influencer`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get Influencer by Id-----------------------
export const getInfluencerById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/influencer/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get getAllModel by Category-----------------------
export const getAllModel = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/model`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get Influencer by Id-----------------------
export const getModelById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/model/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get Model by CityName and UserName-----------------------
export const getModelByCityNameandUserName = async (CityName, StageName) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/model/${CityName}/${StageName}`
    );
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get getAllModel by Category-----------------------
export const getAllArtist = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/artist`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get Influencer by Id-----------------------
export const getArtistById = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/artist/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get Artist by CityName and UserName-----------------------
export const getArtistByCityNameandUserName = async (CityName, ArtistName) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/artist/${CityName}/${ArtistName}`
    );
    if (response.data.status === true) {
      return response.data.responseData[0];
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// --------------------------------get Influencer by City-----------------------
export const getInfluencerByCity = async (Id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/influencer/city/${Id}`);
    if (response.data.status === true) {
      return response.data.responseData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

