import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

// ------------------------------HireModel---------------------------------
// https://model.easyshadi.com/front/api/hire/model
export const HireModel = async (data) => {
  try {
    const responce = await axios.post(`${API_BASE_URL}/hire/model`, data);
    if (responce.data.status === true) {
      toast.success(responce.data.message);
      return responce.data;
    } else {
      throw new Error(responce.data.message);
    }
  } catch (error) {
    throw error;
  }
};
// ------------------------------HireInfluencer---------------------------------
// https://model.easyshadi.com/front/api/hire/influencer
export const HireInfluencerAPI = async (data) => {
  try {
    const responce = await axios.post(`${API_BASE_URL}/hire/influencer`, data);
    if (responce.data.status === true) {
      toast.success(responce.data.message);
      return responce.data;
    } else {
      throw new Error(responce.data.message);
    }
  } catch (error) {
    throw error;
  }
};
