import React, { useState } from "react";
import HiremodelusImg from "../../../Images/hire-model-banner.jpg";
import titleimage from "../../../Images/title-icon.png";
import { FaEnvelope, FaMobile, FaPaperPlane } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { useFormik } from "formik";

import * as Yup from "yup";
import { sendContact } from "../../../API/ContactUsApi";
import Breadcrumb from "../../../Breadcumb/Breadcumb";
import FormLoader from "../../../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
import { HireInfluencerAPI } from "../../../API/HireApi";
import usePageSEO from "../../usepageSEO/Index";

const validationSchema = Yup.object().shape({
  FirstName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "First Name cannot contain numbers")
    .required("First Name is required"),
  LastName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Last Name cannot contain numbers")
    .required("Last Name is required"),

  Email: Yup.string().email("Invalid email").required("Email is required"),

  Phone: Yup.string()
    .matches(/^[0-9]+$/, "Invalid mobile number")
    .required("Number is required"),
  CompanyName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Company Name cannot contain numbers")
    .required("Company Name is required"),
  Subject: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Subject cannot contain numbers")
    .required("Subject is required"),
  Message: Yup.string().required("Message is required"),
});

const HireInfluencer = () => {
  const breadcrumbItems = [
    {
      text: "Hire A Models",
    },
  ];

  usePageSEO(
    "Viral kar | Hire Influencer", // Use page title for SEO if available
    "Viral kar | Hire Influencer", // Use page description for SEO if available
    ["Viral kar | Hire Influencer"] // No keywords provided in this example
  );
  const backgroundImage = HiremodelusImg;
  const [isFormLoading, setIsFormLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      Email: "",
      Phone: "",
      CompanyName: "",
      Subject: "",
      Message: "",
      Type: "Influencer",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await HireInfluencerAPI(values);
        if (response.status === true) {
          actions.resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });
  return (
    <div className="dark:bg-darkmode dark:text-white">
      {" "}
      <Breadcrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <ScrollToTop />
      <div className="py-20 container mx-auto px-5">
        <div className="flex items-center justify-center flex-col">
          <p className="page-title text-center">Hire A Models</p>
          <p className="px-10 text-center mb-4">POST YOUR REQUIREMENT</p>
          <img src={titleimage} className="text-center title-icon" alt="" />
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="border rounded-md shadow-md  p-8 my-10"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <input
                type="text"
                name="FirstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.FirstName}
                placeholder="First Name"
                className=" px-4 py-3 w-full focus:outline-none my-2.5 border border-zinc-400 rounded dark:bg-darkmode"
              />
              {formik.touched.FirstName && formik.errors.FirstName ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.FirstName}
                </div>
              ) : null}
            </div>
            <div>
              <input
                type="text"
                name="LastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.LastName}
                placeholder="Last Name"
                className=" px-4 py-3 w-full focus:outline-none my-2.5 border border-zinc-400 rounded dark:bg-darkmode"
              />
              {formik.touched.LastName && formik.errors.LastName ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.LastName}
                </div>
              ) : null}
            </div>
            <div>
              <input
                type="email"
                name="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Email}
                placeholder="Email Address"
                className=" px-4 py-3 w-full focus:outline-none my-2.5 border border-zinc-400 rounded dark:bg-darkmode"
              />
              {formik.touched.Email && formik.errors.Email ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.Email}
                </div>
              ) : null}
            </div>
            <div>
              <input
                type="text"
                name="Phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Phone}
                placeholder="Phone Number"
                className=" px-4 py-3 w-full focus:outline-none my-2.5 border border-zinc-400 rounded dark:bg-darkmode"
              />
              {formik.touched.Phone && formik.errors.Phone ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.Phone}
                </div>
              ) : null}
            </div>
            <div>
              <input
                type="text"
                name="CompanyName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.CompanyName}
                placeholder="Your Company Name"
                className=" px-4 py-3 w-full focus:outline-none my-2.5 border border-zinc-400 rounded dark:bg-darkmode"
              />
              {formik.touched.CompanyName && formik.errors.CompanyName ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.CompanyName}
                </div>
              ) : null}
            </div>
            <div>
              <input
                type="text"
                name="Subject"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Subject}
                placeholder="Your Subject"
                className=" px-4 py-3 w-full focus:outline-none my-2.5 border border-zinc-400 rounded dark:bg-darkmode"
              />
              {formik.touched.Subject && formik.errors.Subject ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.Subject}
                </div>
              ) : null}
            </div>
          </div>
          <textarea
            rows="5"
            placeholder="Message"
            className=" px-4 py-3 w-full focus:outline-none my-5 border border-zinc-400 rounded dark:bg-darkmode"
            type="text"
            name="Message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Message}
          ></textarea>
          {formik.touched.Message && formik.errors.Message ? (
            <div className="text-red-500 text-sm">{formik.errors.Message}</div>
          ) : null}

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 mx-auto flex justify-center items-center"
            >
              <FaPaperPlane className="mr-3" /> Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HireInfluencer;
