import React, { useEffect, useState } from "react";
import DashboardMenu from "../../DashboardMenu";
import ContactusImg from "../../../Images/contact-back.jpg";
import Config from "../../../API/Config";
import { GetVendorInquiry } from "../../../API/ModelInfMain";
import Swal from "sweetalert2";
import FormLoader from "../../../common/FormLoader";
import ClipLoader from "react-spinners/FadeLoader";
import NoDataFound from "../../../common/NodataFound";
import ScrollToTop from "../ScrollToTop";
import usePageSEO from "../../usepageSEO/Index";

const Enquiry = () => {
  const backgroundImage = ContactusImg;
  const breadcrumbStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    position: "relative",
    backgroundPosition: "center",
    zIndex: 1,
    padding: "140px 0 80px 0",
  };

  usePageSEO(
    "Viral kar | Enquiry", // Use page title for SEO if available
    "Viral kar | Enquiry", // Use page description for SEO if available
    ["Viral kar | Enquiry"] // No keywords provided in this example
  );

  const [InfluencerInqData, setInfluencerIndData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const Id = Config.getId();
  const [isFormLoading, setIsFormLoading] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const getInqData = async () => {
    try {
      const results = await GetVendorInquiry(Id);
      setInfluencerIndData(results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInqData();
  }, [Id]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = InfluencerInqData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(InfluencerInqData.length / itemsPerPage);

  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <div className="breadcrumb-bg" style={breadcrumbStyle}>
          <div className="breadcrumb-before"></div>
          <ul className="text-white text-3xl text-center">Enquiry</ul>
        </div>
      </nav>
      <div className="pb-24">
        <ScrollToTop />
        <div className="w-full container mx-auto">
          <div className="w-full lg:grid grid-cols-5 py-20 px-3 gap-3 ">
            <DashboardMenu />

            {/* ===============================Nav Pills data ====================================*/}
            <div className="relative flex h-full flex-col min-w-0 break-words w-full rounded col-span-4">
              <div className="bg-dark-light">
                <div className="grid grid-cols-1 gap-5">
                  <div className="bg-dark-light p-6">
                    <p className="text-center text-xl py-1.5 mb-3 bg-themecolor">
                      My Enquiry
                    </p>
                    {loading ? (
                      <div className="flex justify-center items-center my-40">
                        <ClipLoader
                          color={"#d00067"}
                          loading={loading}
                          size={40}
                        />
                      </div>
                    ) : (
                      <React.Fragment>
                        {currentItems.length === 0 ? (
                          <NoDataFound />
                        ) : (
                          <div className="grid grid-cols-1 gap-3">
                            {currentItems.map((val, index) => (
                              <div
                                key={index}
                                className="bg-white text-black p-3 relative"
                              >
                                <span className="absolute right-0 bg-themecolor2 px-3 py-1 text-white top-0">
                                  {indexOfFirstItem + index + 1}
                                </span>
                                <div>
                                  <span className="text-themecolor">
                                    Name :
                                  </span>{" "}
                                  {val.Name}
                                </div>
                                <div>
                                  <span className="text-themecolor">
                                    Email :
                                  </span>{" "}
                                  {val.Email}
                                </div>
                                <div>
                                  <span className="text-themecolor">
                                    Phone :
                                  </span>{" "}
                                  {val.Phone}
                                </div>
                                <div className="text-justify">
                                  <span className="text-themecolor">
                                    Message :
                                  </span>{" "}
                                  {val.Message}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {totalPages > 1 && (
                          <div className="flex justify-center mt-6 flex-wrap">
                            <button
                              className="mx-2 px-3 py-1 bg-themecolor2 text-white rounded mb-2"
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                            {[...Array(totalPages)].map((_, pageIndex) => (
                              <button
                                key={pageIndex}
                                className={`mx-1 px-3 py-1 mb-2 ${
                                  currentPage === pageIndex + 1
                                    ? "bg-themecolor text-white"
                                    : "bg-gray-200 text-black"
                                } rounded`}
                                onClick={() => handlePageChange(pageIndex + 1)}
                              >
                                {pageIndex + 1}
                              </button>
                            ))}
                            <button
                              className="mx-2 px-3 py-1 bg-themecolor2 text-white rounded mb-2"
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
