import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../Breadcumb/Breadcumb";
import ServiceBannerImg from "../../../Images/PricingPlans.jpg";
import titleimage from "../../../Images/title-icon.png";
import usePageSEO from "../../usepageSEO/Index";

const PlanModel = () => {
  const breadcrumbItems = [
    {
      text: "Our Plans",
    },
  ];
  const backgroundImage = ServiceBannerImg;

  useEffect(() => { 
   
  }, []);

  usePageSEO(
    "Viral kar | Plan", // Use page title for SEO if available
    "Viral kar | Plan", // Use page description for SEO if available
    ["Viral kar | Plan"] // No keywords provided in this example
  );

 
  return (
    <div className="dark:bg-darkmode ">
      <div className="">
        <BreadCrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
        <div className=" text-white pb-20">
          <div className="container mx-auto py-10 px-4 xl:px-0">
            <h1 className="page-title text-3xl text-center pb-1 dark:text-white text-black dark:text-white">
              Portfolio Packages
            </h1>
            <img src={titleimage} alt="" className="mx-auto mt-2 title-icon" />

            <div className="mt-20">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
                <div className="">
                  <div className="bg-zinc-100 dark:bg-slate-900 dark:text-white xl:p-8 p-4 text-black rounded-md shadow-lg border">
                    <div className="border-l-4 ps-4 mb-5 border-themecolor">
                      <h1 className="text-xl font-medium text-left ">
                        Standard{" "}
                      </h1>
                      <h1 className="text-5xl font-bold text-left uppercase">
                        {" "}
                        Free
                      </h1>
                    </div>
                    <div className="border-t border-b border-zinc-300 w-full ">
                      <p className=" pb-2 pt-4">No. of looks</p>
                      <p className=" pb-2">Timeframe</p>
                      <p className=" pb-2">No. of photos clicked</p>
                      <p className=" pb-2">Kids</p>
                      <p className=" pb-2">Add Makeup & Hairstyling</p>
                      <p className=" pb-2">Final retouched Photos</p>
                      <p className=" pb-2">Add more photographs</p>
                      <p className=" pb-2">Get all raw photos (DVD)</p>
                      <p className=" pb-2">Add Wardrobe + Styling</p>
                    </div>
                    <div className="pt-4 md:flex grid">
                      <h1 className="text-3xl font-semibold my-auto">
                        $120{" "}
                        <span className="text-base text-zinc-400 font-normal">
                          / per session
                        </span>
                      </h1>
                      <button className="bg-themecolor p-2 px-5 rounded-md text-white text-lg block m-auto border   hover:bg-black duration-150">
                        Sign Up
                      </button>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="bg-zinc-100 dark:bg-slate-900 dark:text-white xl:p-8 p-4 text-black rounded-md shadow-lg border">
                    <div className="border-l-4 ps-4 mb-5 border-themecolor">
                      <h1 className="text-xl font-medium text-left ">
                        Unlimited{" "}
                      </h1>
                      <h1 className="text-5xl font-bold text-left">SILVER</h1>
                    </div>
                    <div className="border-t border-b border-zinc-300 w-full">
                      <p className=" pb-2 pt-4">3</p>
                      <p className=" pb-2">4 hours</p>
                      <p className=" pb-2">Restricted</p>
                      <p className=" pb-2">40% off for kids up to 10 years</p>
                      <p className=" pb-2">On Request</p>
                      <p className=" pb-2">15</p>
                      <p className=" pb-2">INR 250/Photo</p>
                      <p className=" pb-2">INR 5000/-</p>
                      <p className=" pb-2">On Request</p>
                    </div>
                    <div className="pt-4 md:flex grid">
                      <h1 className="text-3xl font-semibold my-auto">
                        $120{" "}
                        <span className="text-base text-zinc-400 font-normal">
                          / per session
                        </span>
                      </h1>
                      <button className="bg-themecolor p-2   px-5 rounded-md text-white text-lg block m-auto border   hover:bg-black duration-150">
                        Sign Up
                      </button>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="bg-zinc-100 dark:bg-slate-900 dark:text-white xl:p-8 p-3 text-black rounded-md shadow-lg border">
                    <div className="border-l-4 ps-4 mb-5 border-themecolor">
                      <h1 className="text-xl font-medium text-left ">
                        For business{" "}
                      </h1>
                      <h1 className="text-5xl font-bold text-left">GOLD</h1>
                    </div>
                    <div className="border-t border-b border-zinc-300 w-full">
                      <p className=" pb-2 pt-4">5</p>
                      <p className=" pb-2">Full day</p>
                      <p className=" pb-2">Restricted</p>
                      <p className=" pb-2">40% off for kids up to 10 years</p>
                      <p className=" pb-2">On Request</p>
                      <p className=" pb-2">25</p>
                      <p className=" pb-2">INR 250/Photo</p>
                      <p className=" pb-2">INR 5000/-</p>
                      <p className=" pb-2">On Request</p>
                    </div>
                    <div className="pt-4 md:flex grid">
                      <h1 className="text-3xl font-semibold my-auto">
                        $120{" "}
                        <span className="text-base text-zinc-400 font-normal">
                          / per session
                        </span>
                      </h1>
                      <button className="bg-themecolor p-2   px-5 rounded-md text-white text-lg block m-auto border  hover:bg-black duration-150">
                        Sign Up
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-5"> 
                <div className="bg-dark-light p-10 rounded-2xl">
                  <h1 className="text-2xl font-bold text-center">MEMBERSHIP</h1>
                  <h1 className="text-lg font-bold text-center py-2">Free</h1>
                  <h1 className=" font-bold text-center">NCLUDES: –</h1>

                  <p className="text-center pb-4 pt-4">No. of looks</p>
                  <p className="text-center pb-4">Timeframe</p>
                  <p className="text-center pb-4">No. of photos clicked</p>
                  <p className="text-center pb-4">Kids</p>
                  <p className="text-center pb-4">Add Makeup & Hairstyling</p>
                  <p className="text-center pb-4">Final retouched Photos</p>
                  <p className="text-center pb-4">Add more photographs</p>
                  <p className="text-center pb-4">Get all raw photos (DVD)</p>
                  <p className="text-center pb-4">Add Wardrobe + Styling</p>

                  <button className="bg-themecolor p-3 mt-4 px-5 rounded-md text-lg block m-auto border border-themecolor hover:bg-black duration-150">
                    Sign Up
                  </button>
                </div>

                <div className="bg-slate-500 p-10 rounded-2xl">
                  <h1 className="text-2xl font-bold text-center">SILVER</h1>
                  <h1 className="text-lg font-bold text-center py-2">
                    INR 10000
                  </h1>
                  <h1 className=" font-bold text-center">NCLUDES: –</h1>

                  <p className="text-center pb-4 pt-4">3</p>
                  <p className="text-center pb-4">4 hours</p>
                  <p className="text-center pb-4">Restricted</p>
                  <p className="text-center pb-4">
                    40% off for kids up to 10 years
                  </p>
                  <p className="text-center pb-4">On Request</p>
                  <p className="text-center pb-4">15</p>
                  <p className="text-center pb-4">INR 250/Photo</p>
                  <p className="text-center pb-4">INR 5000/-</p>
                  <p className="text-center pb-4">On Request</p>
                  <button className="bg-themecolor p-3 mt-4 px-5 rounded-md text-lg block m-auto border border-themecolor hover:bg-black duration-150">
                    Sign Up
                  </button>
                </div>

                <div className="bg-yellow-600 p-10 rounded-2xl">
                  <h1 className="text-2xl font-bold text-center">GOLD</h1>
                  <h1 className="text-lg font-bold text-center py-2">Free</h1>
                  <h1 className=" font-bold text-center">NCLUDES: –</h1>

                  <p className="text-center pb-4 pt-4">5</p>
                  <p className="text-center pb-4">Full day</p>
                  <p className="text-center pb-4">Restricted</p>
                  <p className="text-center pb-4">
                    40% off for kids up to 10 years
                  </p>
                  <p className="text-center pb-4">On Request</p>
                  <p className="text-center pb-4">25</p>
                  <p className="text-center pb-4">INR 250/Photo</p>
                  <p className="text-center pb-4">INR 5000/-</p>
                  <p className="text-center pb-4">On Request</p>
                  <button className="bg-themecolor p-3 mt-4 px-5 rounded-md text-lg block m-auto border border-themecolor hover:bg-black duration-150">
                    Sign Up
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanModel;
