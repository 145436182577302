import React, { useEffect, useState } from "react";
import DashboardMenu from "../../DashboardMenu";
import ScrollToTop from "../ScrollToTop";
import { useFormik } from "formik";
import FormLoader from "../../../common/FormLoader";
import Config from "../../../API/Config";
import { InfluencerGetById, UpdateModelInfById } from "../../../API/InfluencerSignUpAPI";
import { useData } from "../../../Context/DataContext ";
import { format, parse } from "date-fns";
import { getAllCollbration } from "../../../API/CollabrationAPi";

const InfluencerProfile = () => {
  const [ThumbImagePreview, setThumbImagePreview] = useState(); // Image preview
  const [isFormLoading, setIsFormLoading] = useState(false); // Loading state
  const Id = Config.getId(); // Influencer Id
  const Status = Config.getStatus();
  const { CityData, CategoryData } = useData(); // Fetch city and category data
  const [CollbrationData, setCollbrationData] = useState([]);
  const languageData = [
    { Title: "English", Id: 1 },
    { Title: "Hindi", Id: 2 },
    { Title: "Gujarati", Id: 3 },
    { Title: "Marathi", Id: 4 },
    { Title: "Tamil", Id: 5 },
    { Title: "Telugu", Id: 6 },
    { Title: "Other", Id: 7 },
  ];

  // Fetch collaborations on component mount
  useEffect(() => {
    const fetchCollaborationData = async () => {
      try {
        const result = await getAllCollbration();
        setCollbrationData(result);
      } catch (error) {
        console.error("Error fetching collaboration data:", error);
      }
    };
    fetchCollaborationData();
  }, []);

  // Formik setup
  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Phone: "",
      DOB: "",
      Gender: "",
      CityId: "",
      Instagram: "",
      Facebook: "",
      Youtube: "",
      Twitter: "",
      LinkedIn: "",
      TikTok: "",
      EngagementRate: "",
      FollowerCount: "",
      TermsandConditions: false,
      PrivacyPolicy: false,
      ProfileImage: "",
      HidImg: "",
      Category: [], // Initialize Category as an empty array
      Language: [], // Initialize Language as an empty array
      CollaborationsType: [], // Initialize Collaborations as an empty array
      OtherCollaborationType: "", // Additional input for "Other" collaboration type
    },
    onSubmit: async (values) => {
      setIsFormLoading(true);
      try {
        // If "Other" is selected, append the OtherCollaborationType field
        const finalCollaborations = values.Collaborations.includes("Other")
          ? [...values.Collaborations.filter((c) => c !== "Other"), values.OtherCollaborationType]
          : values.Collaborations;

        const finalValues = {
          ...values,
          Collaborations: finalCollaborations,
          Language: values.Language.includes("Other")
            ? [...values.Language.filter(lang => lang !== "Other"), values.OtherLanguage]
            : values.Language,
        };

        const formData = new FormData();
        Object.entries(finalValues).forEach(([key, value]) => {
          formData.append(key, value);
        });

        // Submit form data
        await UpdateModelInfById(Id, formData);
        await fetchData(); // Refetch data after update
      } catch (error) {
        console.error("Error updating influencer data:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  // Fetch Influencer Data
const fetchData = async () => {
  try {
    const result = await InfluencerGetById(Id);

    // Handle ProfileImage with full path
    if (result.ProfileImage) {
      setThumbImagePreview(result.Path + result.ProfileImage);
    }

    // Parse and format DOB if it exists
    let formattedDate = "";
    if (result.DOB) {
      const parsedDate = parse(result.DOB, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date());
      formattedDate = format(parsedDate, "yyyy-MM-dd");
    }

    // Convert Category string to array of IDs
    const categoryArray = result.Category ? result.Category.split(",").map(Number) : [];

    formik.setValues({
      // Other fields...
      Category: categoryArray,  // Set the correct Category Ids
    });

    // Convert Collaboration ID to an array (if needed, assuming you have multiple collaboration options)
    const collabArray = result.CollabrationId ? [result.CollabrationId] : [];

    formik.setValues({
      Name: result.Name || "",
      Email: result.Email || "",
      Phone: result.Phone || "",
      DOB: formattedDate || "",
      Gender: result.Gender || "",
      CityId: result.CityName || "",
      Instragram: result.Instragram || "",
      Facebook: result.Facebook || "",
      YouTube: result.YouTube || "",
      Twitter: result.Twitter || "",
      LinkedIn: result.LinkedIn || "",
      TikTok: result.TikTok || "",
      Other: result.Other || "",
      EngagementRate: result.EngagementRate || "",
      FollowerCount: result.FollowerCount || "",
      TermsandConditions: result.TermsandConditions || false,
      PrivacyPolicy: result.PrivacyPolicy || false,
      ProfileImage: result.ProfileImage || "",
      HidImg: result.Hid_Image || "",
      Category: categoryArray, // Set the category values
      Language: result.Languages ? result.Languages.split(",") : [],
      CollaborationsType: collabArray, // Set collaboration IDs
    });

    sessionStorage.setItem("ProfileImage", ThumbImagePreview);
  } catch (error) {
    console.error("Error fetching influencer data:", error);
  }
};


  // Fetch data on component mount or when Id changes
  useEffect(() => {
    if (Id) {
      fetchData();
    }
  }, [Id]);

  // Handle Collaboration checkbox changes
  const handleCollaborationCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const currentCollaborations = formik.values.CollaborationsType || []; // Safely access the current values
  
    if (checked) {
      formik.setFieldValue("CollaborationsType", [...currentCollaborations, value]);
    } else {
      formik.setFieldValue(
        "CollaborationsType",
        currentCollaborations.filter((val) => val !== value)
      );
    }
  
    // Clear "Other" type when deselected
    if (value === "Other" && !checked) {
      formik.setFieldValue("OtherCollaborationType", "");
    }
  };

  const handleCheckboxChange = (event) => {
    const value = Number(event.target.value); // Get the value of the checkbox
    const isChecked = event.target.checked; // Check if it is checked
  
    if (isChecked) {
      // If checked, add the value to the array
      formik.setFieldValue("Category", [...formik.values.Category, value]);
    } else {
      // If unchecked, remove the value from the array
      formik.setFieldValue("Category", formik.values.Category.filter((id) => id !== value));
    }
  };
  

  // Handle Language Checkbox Changes
  const handleLanguageCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const currentLanguages = formik.values.Language || [];

    if (value === "Other") {
      if (checked) {
        formik.setFieldValue("Language", [...currentLanguages, "Other"]);
        formik.setFieldValue("OtherLanguage", ""); // Reset other language
      } else {
        formik.setFieldValue("Language", currentLanguages.filter((lang) => lang !== "Other"));
        formik.setFieldValue("OtherLanguage", ""); // Reset other language
      }
    } else {
      if (checked) {
        formik.setFieldValue("Language", [...currentLanguages, value]);
      } else {
        formik.setFieldValue("Language", currentLanguages.filter((lang) => lang !== value));
      }
    }
  };

  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="">
        <ScrollToTop />
        <div className="container mx-auto">
          <div className="md:flex py-5 md:py-14 px-3 md:gap-10 ">
          <div className="md:w-1/3 mb-5 md:mb-0">
          <DashboardMenu />
          </div>
            {/* ===============================Nav Pilss data ====================================*/}
            <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4">
              <div className="bg-dark-light">
                <form onSubmit={formik.handleSubmit}>
                  <input
                    type="hidden"
                    name="HidImg"
                    value={formik.values.HidImg}
                  />
                  <div className="">
                    <div className="rounded-lg bg-dark-light p-5 dark:border border-white">
                      {Status === 0 ? (
                        <div
                          class="bg-orange-100 border-t-2 mb-4 text-sm border-orange-500 rounded-b text-orange-900 px-4 py-2 shadow-md"
                          role="alert"
                        >
                          <div class="flex justify-center">
                            <p class="font-semibold text-sm">
                              Your Profile is Under Review
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          class="bg-green-100 border-t-4 mb-4 text-sm border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
                          role="alert"
                        >
                          <div class="flex justify-center">
                            <p class="font-bold">Your Profile is Approved</p>
                          </div>
                        </div>
                      )}
                      <p className="text-md py-1.5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full">
                        Details
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="my-1">
                          <label className="text-base" for="">
                            Name <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Name}
                            placeholder="Name"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.Name && formik.errors.Name ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Name}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Email
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="email"
                            name="Email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Email}
                            disabled
                            placeholder="Email Address"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.Email && formik.errors.Email ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Email}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Phone <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Phone"
                            disabled
                            readOnly
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Phone}
                            placeholder="Phone"
                            className=" px-4 py-3 w-full focus:outline-none  mt-2 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.Phone && formik.errors.Phone ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Phone}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            DOB <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="date"
                            name="DOB"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.DOB}
                            placeholder="DOB"
                            className=" px-4 py-3 w-full focus:outline-none  mt-3 placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                          {formik.touched.DOB && formik.errors.DOB ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.DOB}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Profile Picture{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="file"
                            name="ProfileImage"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "ProfileImage",
                                event.currentTarget.files[0]
                              );
                            }}
                            onBlur={formik.handleBlur}
                            placeholder="Business Country"
                            className="text-sm text-grey-500 file:mr-5  mt-3 file:py-1.5 file:px-5 file:rounded-full file:border-0 file:text-md file:font-semibold  file:text-white
                            file:bg-gradient-to-r file:from-themecolor file:to-black hover:file:cursor-pointer hover:file:opacity-80 py-1.5 w-full focus:outline-none border-0 rounded"
                          />
                          {formik.touched.ProfileImage &&
                          formik.errors.ProfileImage ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.ProfileImage}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label className="text-base">
                            Select City <span className="text-red-500">*</span>
                          </label>
                          <select
                            name="CityId"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CityId}
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          >
                            {CityData?.map((val, index) => {
                              return (
                                <option key={index} value={val.Id}>
                                  {val.Title}
                                </option>
                              );
                            })}
                          </select>
                          {formik.touched.CityId && formik.errors.CityId ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.CityId}
                            </div>
                          ) : null}
                        </div>
                        <div className="flex items-center mb-2">
                          <label className="text-base mr-5">
                            Gender <span className="text-red-500">*</span>
                          </label>
                          <div className="flex items-center dark:bg-[#1e1e1e] px-2 py-2.5">
                            <div className="mr-3">
                              <input
                                type="radio"
                                id="Male"
                                name="Gender"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="M"
                                checked={formik.values.Gender === "M"}
                                className="mr-2"
                              />
                              <label htmlFor="Male">Male</label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id="Female"
                                name="Gender"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="F"
                                checked={formik.values.Gender === "F"}
                                className="mr-3"
                              />
                              <label htmlFor="Female">Female</label>
                            </div>
                          </div>
                          {formik.touched.Gender && formik.errors.Gender ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Gender}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <p className="text-md py-1.5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full">
                        Choose your category
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-4">
                        {CategoryData?.[0]?.ChildCat?.map((val, index) => (
                          <div className="flex items-center my-2" key={index}>
                            <input
                              type="checkbox"
                              className="mr-2"
                              name="Category"
                              value={val.Id}
                              checked={formik.values.Category.includes(val.Id)} // Corrected to check against the value
                              id={val.Id}
                              onChange={handleCheckboxChange} // Handle changes with your function
                            />
                            <label className="text-sm text-gray-500 font-normal" htmlFor={val.Id}>
                              {val.Title}
                            </label>
                          </div>
                        ))}
                      </div>
                      <p className="text-md py-1.5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full mt-5">
                        Choose Language
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-7">
                        {languageData?.map((val, index) => (
                          <div className="flex items-center my-1" key={index}>
                            <input
                              type="checkbox"
                              className="mr-2"
                              name="Language"
                              value={val.Title}
                              checked={Array.isArray(formik.values.Language) && formik.values.Language.includes(val.Title)} // Check if Language is an array
                              id={val.Title}
                              onChange={handleLanguageCheckboxChange}
                            />
                            <label className="text-sm text-gray-500 font-normal" htmlFor={val.Title}>
                              {val.Title}
                            </label>
                          </div>
                        ))}
                      </div>
                      {/* "Other" Language Input Section */}
                      {formik.values.Language && formik.values.Language.includes("Other") && ( // Ensure Language is defined
                        <div className="my-2">
                          <label className="text-base">Specify Other Language</label>
                          <input
                            type="text"
                            name="OtherLanguage"
                            value={formik.values.OtherLanguage || ""} // Ensure value is not undefined
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Please specify"
                            className="px-4 py-2 w-full focus:outline-none my-1.5 border dark:bg-[#020617]"
                          />
                          {formik.touched.OtherLanguage && formik.errors.OtherLanguage ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.OtherLanguage}
                            </div>
                          ) : null}
                        </div>
                      )}
                      {/* Language Error Message */}
                      {formik.touched.Language && formik.errors.Language && (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Language}
                        </div>
                      )}
                      <p className="text-md py-1.5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full mt-3">
                        Collaboration Preferences
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-4">
                        {CollbrationData[0]?.ChildCat?.map((val) => (
                          <div className="flex items-center my-1" key={val.Id}>
                            <input
                              type="checkbox"
                              className="mr-2"
                              name="CollaborationsType"
                              value={val.Id}
                              checked={Array.isArray(formik.values.CollaborationsType) && formik.values.CollaborationsType.includes(val.Id.toString())} // Ensure it's an array before calling includes
                              id={`collab-${val.Id}`}
                              onChange={handleCollaborationCheckboxChange} // Use a separate handler for collaborations
                            />
                            <label className="text-sm text-gray-500 font-normal" htmlFor={`collab-${val.Id}`}>
                              {val.Title}
                            </label>
                          </div>
                        ))}
                      </div>
                      <p className="text-md py-1.5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full mt-3">
                        Collaboration Preferences
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div className="my-1">
                          <label for="">
                            Preferred Brands/Industries <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Instragram"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CollaborationIndustries}
                            placeholder="CollaborationIndustries"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                          />
                          {formik.touched.Instragram &&
                          formik.errors.Instragram ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Instragram}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            FollowerCount <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Instragram"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CollaborationIndustries}
                            placeholder="CollaborationIndustries"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                          />
                          {formik.touched.Instragram &&
                          formik.errors.Instragram ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Instragram}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            EngagementRate <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Instragram"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CollaborationIndustries}
                            placeholder="CollaborationIndustries"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                          />
                          {formik.touched.Instragram &&
                          formik.errors.Instragram ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Instragram}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label className="text-base">
                            Age Range <span className="text-red-500">*</span>
                          </label>
                          <select
                            name="AgeRange"
                            value={formik.values.AgeRange}
                            onChange={formik.handleChange}
                            className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Please select Age Range</option>
                            <option value="0 to 12 years">0 to 12 years</option>
                            <option value="13 to 17 years">13 to 17 years</option>
                            <option value="18 to 30 years">18 to 30 years</option>
                            <option value="30 to 40 years">30 to 40 years</option>
                            <option value="40 and more">40 and more</option>
                          </select>
                          {formik.touched.AgeRange && formik.errors.AgeRange ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.AgeRange}
                            </div>
                          ) : null}
                        </div>
                        <div className="mt-2 mb-2">
                          <label className="text-base" for="">
                           Description/Bio
                          </label>
                          <textarea
                            type="text"
                            name="Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Bio}
                            placeholder="Bio"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder mt-2 border border-zinc-200 rounded-md dark:bg-[#020617]"
                          />
                        </div>
                      </div>
                      <p className="text-md py-1.5 mb-3 text-themecolor1 font-semibold border-b-2 dark:text-white w-full">
                        Social Media Details
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div className="my-1">
                          <label for="">
                            Instragram <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Instragram"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Instragram}
                            placeholder="Instragram"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                          />
                          {formik.touched.Instragram &&
                          formik.errors.Instragram ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Instragram}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            YouTube <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="YouTube"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.YouTube}
                            placeholder="YouTube"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                          />
                          {formik.touched.YouTube &&
                          formik.errors.YouTube ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.YouTube}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Facebook <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Facebook"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Facebook}
                            placeholder="Facebook"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                          />
                          {formik.touched.Facebook && formik.errors.Facebook ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Facebook}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            Twitter <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Twitter"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Twitter}
                            placeholder="Twitter"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                          />
                          {formik.touched.Twitter && formik.errors.Twitter ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Twitter}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">
                            LinkedIn <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="LinkedIn"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.LinkedIn}
                            placeholder="LinkedIn"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder  border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                          />
                          {formik.touched.LinkedIn && formik.errors.LinkedIn ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.LinkedIn}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">TikTok</label>
                          <input
                            type="text"
                            name="TikTok"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.TikTok}
                            placeholder="TikTok"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                          />
                          {formik.touched.TikTok && formik.errors.TikTok ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.TikTok}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Other</label>
                          <input
                            type="text"
                            name="Other"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Other}
                            placeholder="Roposo"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                          />
                          {formik.touched.Other && formik.errors.Other ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Other}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Website</label>
                          <input
                            type="text"
                            name="Website"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Website}
                            placeholder="Roposo"
                            className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-2 dark:bg-[#020617]"
                          />
                          {formik.touched.Website && formik.errors.Website ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Website}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex justify-center my-3">
                        <button
                          type="submit"
                          className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-10"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfluencerProfile;