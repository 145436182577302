/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DashboardMenu from "../../DashboardMenu";
import ContactusImg from "../../../Images/contact-back.jpg";
import Config from "../../../API/Config";
import { CampaignDeletebyId, CampaignGetbyId } from "../../../API/CampaignApi";
import { Link } from "react-router-dom";
import { FaGlobe } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import Swal from "sweetalert2";
import FormLoader from "../../../common/FormLoader";
import ClipLoader from "react-spinners/FadeLoader";
import NoDataFound from "../../../common/NodataFound";
import ScrollToTop from "../ScrollToTop";
import { GetCampaignforIunfluModel } from "../../../API/ModelInfMain";
import usePageSEO from "../../usepageSEO/Index";

const ModelCampaign = () => {
  const backgroundImage = ContactusImg;
  const breadcrumbStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    position: "relative",
    backgroundPosition: "center",
    zIndex: 1,
    padding: "140px 0 80px 0",
  };

  usePageSEO(
    "Viral kar | Campaign", // Use page title for SEO if available
    "Viral kar | Campaign", // Use page description for SEO if available
    ["Viral kar | Campaign"] // No keywords provided in this example
  );
  const [CampaingnData, setCampaingnData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const Id = Config.getId();
  const Status = Config.getvendorStatus();
  const FatchData = async () => {
    try {
      const result = await GetCampaignforIunfluModel(Id);
      setCampaingnData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    FatchData();
  }, [Id]);

  const [isFormLoading, setIsFormLoading] = useState(false);

  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <div className="breadcrumb-bg" style={breadcrumbStyle}>
          <div className="breadcrumb-before"></div>
          <ul className="text-white text-3xl text-center">Campaigns</ul>
        </div>
      </nav>
      <div className="pb-24">
        <ScrollToTop />
        <div className="w-full container mx-auto">
          <div className="w-full  lg:grid grid-cols-5 py-20 px-3 gap-3 ">
            <DashboardMenu />

            {/* ===============================Nav Pilss data ====================================*/}
            <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4">
              <div className="bg-dark-light">
                <div className="grid grid-cols-1 gap-5">
                  <div className="bg-dark-light p-6">
                    <p className="text-center text-xl py-1.5 mb-3 bg-themecolor">
                      Campaigns
                    </p>

                    {Status === 0 ? (
                      <div>
                        <div
                          class="bg-orange-100 border-t-4 mb-4 text-sm border-orange-500 rounded-b text-orange-900 px-4 py-2 shadow-md"
                          role="alert"
                        >
                          <div class="flex">
                            <p class="font-bold">
                              {" "}
                              Please Complete your profile before apply
                              campaign.
                            </p>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="bg-themecolor py-2 px-4"
                        >
                          <Link to={"/profile/model"}>Click</Link>
                        </button>
                      </div>
                    ) : (
                      <>
                        {loading ? (
                          <div className="flex justify-center items-center my-40">
                            <ClipLoader
                              color={"#d00067"}
                              loading={loading}
                              size={40}
                            />
                          </div>
                        ) : (
                          <React.Fragment>
                            {CampaingnData.length === 0 ? (
                              <NoDataFound />
                            ) : (
                              <div className="grid grid-cols-1 lg:grid-cols-1 gap-5">
                                {CampaingnData?.map((val, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="mb-5  surface:px-2"
                                    >
                                      <div className="bg-white p-5 shadow-lg rounded">
                                        <div className="grid grid-cols-1 md:grid-cols-4 border-b-2 border-gray-400 border-dashed">
                                          <div className="col-span-4 ">
                                            <div>
                                              <div className="flex items-center">
                                                <Link
                                                  to={
                                                    "/influencerCategoryInner"
                                                  }
                                                >
                                                  <p className="text-2xl text-themecolor my-2">
                                                    {val.CampaignName}
                                                  </p>
                                                </Link>
                                                <p className="ml-auto">
                                                  <Link
                                                    className="bg-themecolor2 p-2"
                                                    target="_blank"
                                                    to={val.CampaignDoc}
                                                  >
                                                    View
                                                  </Link>
                                                </p>
                                              </div>
                                              <div className="text-textcolor my-2">
                                                <p className="flex items-center my-1">
                                                  <FaLocationDot />
                                                  <span className="px-1.5">
                                                    {val.CityNames}
                                                  </span>
                                                </p>
                                                <p className="flex items-center my-1 line-break-anywhere">
                                                  <span className="mr-1.5">
                                                    <FaGlobe />
                                                  </span>
                                                  {val.Website}
                                                </p>
                                                <p>
                                                  Category: {val.CategoryName}
                                                </p>
                                                <p>
                                                  ServiceName:{" "}
                                                  {val.ServiceNames}
                                                </p>

                                                <p>
                                                  Promotion Type:{" "}
                                                  {val.Type === "S"
                                                    ? "Service"
                                                    : "Product"}
                                                </p>
                                              </div>

                                              <p className="flex items-center my-1 text-textcolor line-clamp-1">
                                                {val.Description}
                                              </p>
                                              <p className="text-2xl text-themecolor my-1">
                                                {val.Price}
                                              </p>

                                              <p className="text-black my-1">
                                                {val.Categories}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex justify-center">
                                          <Link
                                            to={`/campaign/apply/${val.Id}`}
                                          >
                                            <button
                                              className="bg-themecolor   mt-5 p-2 px-5 text-white rounded md:text-base text-xs"
                                              type="button"
                                            >
                                              Apply
                                            </button>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelCampaign;
