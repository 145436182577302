import React from 'react';
import errorbanner from "../../src/Images/error-banner.jpg";
import { FaLongArrowAltRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { TiHome } from "react-icons/ti";

const NotFound = () => {
  return (
    <div>
      <div className='container m-auto px-5 md:px-0 py-5'>
        <div className='md:flex gap-4 items-center'>
          <div className='md:w-3/5 mb-4 md:mb-0'>
            <img src={errorbanner} alt="error banner" />
          </div>
          <div className='md:w-2/5'>
            <div className='text-center bg-white pb-5'>
              <h1 className='font-bold md:text-3xl mb-2'>404 Not Found</h1>
              <p className='text-sm'>The page you're looking for doesn't exist.</p>
            </div>
            <div className="flex items-center justify-center mt-3 mb-2">
              <Link
                to={`/`}
                type="button"
                className="bg-themebgcolor3 flex items-center p-3 rounded-full px-8 mx-auto font-medium text-sm mt-2  text-white hover:bg-themecolor1 dark:bg-themecolor1 "
              >      <TiHome  className="me-2" />
                Back To Home

              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;