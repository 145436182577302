import React, { useState } from "react";
import ContactusImg from "../../../Images/contact-back.jpg";
import titleimage from "../../../Images/title-icon.png";
import { useFormik } from "formik";
import {CitySelect,CountrySelect,StateSelect} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import * as Yup from "yup";
import FormLoader from "../../../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
import { AddAdvertiser } from "../../../API/AddAdvertiserApi";
import { useNavigate } from "react-router";
import usePageSEO from "../../usepageSEO/Index";

const validateFileSize = (file, maxSizeMB) => {
  if (file && file.size > maxSizeMB * 1024 * 1024) {
    return `File size must be less than ${maxSizeMB} MB`;
  }
  return null;
};

const validationSchema = Yup.object().shape({
  Name: Yup.string().required("Please Enter Your Name"),
  Email: Yup.string()
    .email("Invalid email")
    .required("Please Enter Your Email"),
  Phone: Yup.string().required("Please Enter Your Phone"),
  Password: Yup.string().required("Please Enter Your Password"),
  BusinessName: Yup.string().required("Please Enter Your Business Name"),
  GSTNumber: Yup.string().required("Please Enter Your GST Number"),
  BusinessWebsite: Yup.string().required("Please Enter Your Business Website"),
  BusinessAddress: Yup.string().required("Please Enter Your Business Address"),
  BusinessCity: Yup.string().required("Please Enter Your Business City"),
  BusinessState: Yup.string().required("Please Enter Your Business State"),
  BusinessCountry: Yup.string().required("Please Enter Your Business Country"),
  ProfileImage: Yup.mixed()
    .required("Profile Picture is required")
    .test("fileSize", "File size must be less than 1 MB", (value) => {
      return validateFileSize(value, 1) === null;
    }),
});

const RegisterEnterprice = () => {
  const breadcrumbItems = [
    {
      text: "Join Our Team",
    },
  ];

  usePageSEO(
    "Viral kar | Register", // Use page title for SEO if available
    "Viral kar | Register", // Use page description for SEO if available
    ["Viral kar | Register"] // No keywords provided in this example
  );
  const backgroundImage = ContactusImg;
  const [isFormLoading, setIsFormLoading] = useState(false);
  const usenavigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Phone: "",
      Password: "",
      BusinessName: "",
      GSTNumber: "",
      BusinessWebsite: "",
      BusinessAddress: "",
      BusinessCity: "",
      BusinessState: "",
      BusinessCountry: "",
      ProfileImage: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const result = await AddAdvertiser(formData);
        if (result.status === true) {
          usenavigate("/register/success");
        }
      } catch (error) {
        console.error("Error updating slider:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [value, setValue] = useState()

  return (
    <div>
      <div className="dark:bg-darkmode dark:text-white">
        {" "}
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-10 px-5 container mx-auto">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center dark:text-white md:text-3xl">
              Advertiser Sign Up{" "}
            </p>

            <img
              src={titleimage}
              className="text-center mt-5 title-icon"
              alt=""
            />
          </div>
          <form onSubmit={formik.handleSubmit} className=" my-10">
            <div className="md:w-2/3 mx-auto">
              <div className="border rounded-md shadow-md md:p-8 p-5">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div className="my-1">
                    <input
                      type="text"
                      name="Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Name"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.Name && formik.errors.Name ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Name}
                      </div>
                    ) : null}
                  </div>
                  <div className="">
                    <input
                      type="email"
                      name="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Email Address"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.Email && formik.errors.Email ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Email}
                      </div>
                    ) : null}
                  </div>
                  <div className="">
                    <PhoneInput
                    placeholder="Enter phone number"
                    value={value}
                    defaultCountry="IN"
                    onChange={setValue} className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617] " />
                  </div>
                  <div className="">
                    <input
                      type="password"
                      name="Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Password"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.Password && formik.errors.Password ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Password}
                      </div>
                    ) : null}
                  </div>
                  <div className="">
                    <input
                      type="text"
                      name="BusinessName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Business Name"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.BusinessName &&
                      formik.errors.BusinessName ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.BusinessName}
                      </div>
                    ) : null}
                  </div>
                  <div className="">
                    <input
                      type="text"
                      name="GSTNumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="GST Number"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.GSTNumber && formik.errors.GSTNumber ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.GSTNumber}
                      </div>
                    ) : null}
                  </div>
                  <div className="country-col">
                      <CountrySelect
                      name="BusinessCountry"
                        onChange={(e) => {
                          setCountryid(e.id);
                        }}
                        placeHolder="Select Country"
                      />
                      {formik.touched.BusinessCountry &&
                      formik.errors.BusinessCountry ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.BusinessCountry}
                      </div>
                    ) : null}
                    </div>
                  <div className="country-box">
                    <StateSelect
                      name="BusinessState"
                      countryid={countryid}
                      onChange={(e) => {
                        setstateid(e.id);
                      }}
                      placeHolder="Select State"
                    />
                    {formik.touched.BusinessState &&
                      formik.errors.BusinessState ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.BusinessState}
                      </div>
                    ) : null}
                  </div>
                  <div className="country-box">
                    <CitySelect
                      name="BusinessCity"
                      countryid={countryid}
                      stateid={stateid}
                      onChange={(e) => {
                        console.log(e);
                      }}
                      placeHolder="Select City"
                    />
                    {formik.touched.BusinessCity &&
                      formik.errors.BusinessCity ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.BusinessCity}
                      </div>
                    ) : null}
                  </div>
                  <div className="">
                    <input
                      type="text"
                      name="BusinessWebsite"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Business Website"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md dark:bg-[#020617] "
                    />
                    {formik.touched.BusinessWebsite &&
                      formik.errors.BusinessWebsite ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.BusinessWebsite}
                      </div>
                    ) : null}
                  </div>

                </div>
                <div className="my-3 mt-5">
                  <label className="text-themecolor1">
                    Profile Picture
                    <input
                      type="file"
                      class="text-sm text-grey-500
                      file:mr-5 file:py-3 file:px-10
                      file:rounded-full file:border-0
                      file:text-md file:font-semibold  file:text-white
                      file:bg-gradient-to-r file:from-themecolor file:to-black
                      hover:file:cursor-pointer hover:file:opacity-80
                      p-4 py-3.5 w-full focus:outline-none my-1.5 border-zinc-400 rounded"
                      name="ProfileImage"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "ProfileImage",
                          event.currentTarget.files[0]
                        );
                      }}
                      onBlur={formik.handleBlur}
                      placeholder="Business Country"
                    />
                  </label>
                
                  {formik.touched.ProfileImage && formik.errors.ProfileImage ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.ProfileImage}
                    </div>
                  ) : null}
                </div>
                <div className="my-3">
                  <textarea
                    rows="3"
                    cols=""
                    name="BusinessAddress"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Business Address"
                    className="px-4 py-3 w-full focus:outline-none placeholder-placeholder border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                  ></textarea>
                  {formik.touched.BusinessAddress &&
                    formik.errors.BusinessAddress ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.BusinessAddress}
                    </div>
                  ) : null}
                </div>
             

                <div className="flex justify-center my-3">
                  <button
                    type="submit"
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 mx-auto"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
            <div>
            </div>
            <div>
          </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterEnterprice;
