/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import titleimage from "../../../../Images/title-icon.png";
import {
  FaAddressCard,
  FaCalendar,
  FaEye,
  FaEyeSlash,
  FaUser,
} from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { useNavigate } from "react-router";
import usePageSEO from "../../../usepageSEO/Index";
import Config from "../../../../API/Config";
import { ArtistGetById, ArtistStep1Add } from "../../../../API/ArtistSignUpAPI";
import { useData } from "../../../../Context/DataContext ";

const RegisterArtist = () => {
  usePageSEO(
    "Free Artist Register - ViralKar", // Use page title for SEO if available
    "Need more exposure for your art? Join our free artist register to connect with clients, showcase your work, and grow your creative career today.", // Use page description for SEO if available
    [
      "Free Artist Register, Artist Register, Register as Artist, Register as Artist for free",
    ] // No keywords provided in this example
  );
  const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);
  const socialMediaOptions = [
    "Instagram",
    "YouTube",
    "Facebook",
    "Twitter",
    "LinkedIn",
    "TikTok",
    "Other",
  ];

  const validateFileSize = (file, maxSizeMB) => {
    if (file && file.size > maxSizeMB * 1024 * 1024) {
      return `File size must be less than ${maxSizeMB} MB`;
    }
    return null;
  };

  const validationSchema = Yup.object()
    .shape({
      Name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only alphabetic characters are allowed")
        .required("Please Enter Your Name"),
      ArtistName: Yup.string().required("Please Enter Your Artist Name"),
      ProfilePicture: Yup.mixed()
        .required("Profile Picture is required")
        .test("fileSize", "File size must be less than 5 MB", (value) => {
          return validateFileSize(value, 5) === null;
        }),
      Email: Yup.string().required("Please Enter Your Email"),
      Phone: Yup.string().required("Please Enter Your Phone"),
      PassWord: Yup.string().required("Please Enter Your Password"),
      Username: Yup.string()
      .required("Please Enter a Username")
      .matches(/^[a-z0-9]*$/, "Username must be lowercase letters and numbers only.")
      .matches(/^\S*$/, "Username cannot contain spaces."),
      // .test('is-unique', 'Username already exists', async (value) => {
      //   if (value) {
      //     const exists = await checkUsernameExists(value); // Call to check if the username exists
      //     return !exists; // Return true if username does not exist
      //   }
      //   return true; // If no value, return true
      // }),
      Gender: Yup.string().required("Please Enter Your Gender"),
      DOB: Yup.string().required("Please Enter Your Date of birth"),
      Address: Yup.string().required("Please Enter Your City"),
      Instagram: Yup.string().url("Invalid URL format"),
      YouTube: Yup.string().url("Invalid URL format"),
      TikTok: Yup.string().url("Invalid URL format"),
      Facebook: Yup.string().url("Invalid URL format"),
      Twitter: Yup.string().url("Invalid URL format"),
      LinkedIn: Yup.string().url("Invalid URL format"),
      Other: Yup.string().url("Invalid URL format"),
    })
    .test(
      "at-least-one-social-media",
      "Please add at least one social media URL",
      (values) => {
        return socialMediaOptions.some(
          (option) => values[option] && values[option].trim() !== ""
        );
      }
    );

  const ArtistId = Config.getArtistIdForTemp();
  const ArtistStep1 = Config.getArtistStep1ForTemp();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [ArtistData, setArtistData] = useState([]);
  const [Path, setPath] = useState([]);
  const [profilePicture, setProfilePicture] = useState("");
  const { CityData } = useData();
  const [phone, setPhone] = useState("");


  useEffect(() => {
    const getData = async () => {
      const response = await ArtistGetById(ArtistId);
      setArtistData(response);
      setPath(response.Path);
      setProfilePicture(response.ProfileImage || "");

      sessionStorage.setItem(
        "ArtistStep1ForTemp",
        JSON.stringify(response.Step1)
      );
      sessionStorage.setItem(
        "ArtistStep2ForTemp",
        JSON.stringify(response.Step2)
      );
      sessionStorage.setItem(
        "ArtistStep3ForTemp",
        JSON.stringify(response.Step3)
      );
      const dobFormatted = response?.DOB
        ? new Date(response.DOB).toISOString().split("T")[0]
        : "";
      formik.setValues({
        ArtistId: response.Id || "",
        Name: response.Name || "",
        ArtistName: response.ArtistName || "",
        ProfilePicture: response.ProfileImage || "",
        Hid_Image: response.Hid_Image || "",
        Email: response.Email || "",
        Phone: response.Phone || "",
        PassWord: response.PassWord || "",
        Username: response.Username || "",
        Gender: response?.Gender || "",
        Instagram: response.Instagram || "",
        YouTube: response.YouTube || "",
        TikTok: response.TikTok || "",
        Facebook: response.Facebook || "",
        Twitter: response.Twitter || "",
        LinkedIn: response.LinkedIn || "",
        Other: response.Other || "",
        Address: response?.Address || 9, // Ensure arrays are initialized
        DOB: dobFormatted || "", // Ensure arrays are initialized
      });
      // Automatically check the boxes for social media that have values
      const filledSocialMedia = socialMediaOptions.filter(
        (option) => response[option] && response[option].trim() !== ""
      );
      setSelectedSocialMedia(filledSocialMedia);
    };
    getData();
  }, [ArtistId]);

  const navigation = useNavigate();
  const formik = useFormik({
    initialValues: {
      ArtistId: ArtistId,
      Name: "",
      ArtistName: "",
      ProfilePicture: "",
      Hid_Image: "",
      Email: "",
      Phone: "",
      PassWord: "",
      Username: "",
      DOB: "",
      Address: "",
      Gender: "",
      Instagram: "",
      YouTube: "",
      Facebook: "",
      Twitter: "",
      LinkedIn: "",
      TikTok: "",
      Other: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);

      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const result = await ArtistStep1Add(formData);

        if (result.status === true) {
          navigation("/artist/sign-up/artist2");
          sessionStorage.setItem(
            "ArtistEmailForTemp",
            JSON.stringify(ArtistData.Email)
          );
          sessionStorage.setItem(
            "ArtistUserNameForTemp",
            JSON.stringify(ArtistData.ArtistName)
          );
          sessionStorage.setItem(
            "ArtistUserNameForTemp",
            JSON.stringify(ArtistData.UserName)
          );
          sessionStorage.setItem(
            "ArtistPhoneForTemp",
            JSON.stringify(ArtistData.Phone)
          );
          sessionStorage.setItem(
            "ArtistStep1ForTemp",
            JSON.stringify(ArtistData.Step1)
          );
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const handleSocialMediaCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedSocialMedia((prevSelected) =>
      checked
        ? [...prevSelected, name]
        : prevSelected.filter((media) => media !== name)
    );
  };
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    formik.setFieldValue("Phone", value);
  };

  return (
    <div>
      <div className="bg-dark">
        {" "}
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-14 px-5 container mx-auto">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center dark:text-white capitalize pb-3 dark:text-white">
              Unleash your creativity register or log in to let your art shine
            </p>
            <img
              src={titleimage}
              className="text-center mt-5 title-icon"
              alt=""
            />
          </div>
          <div className="relative flex items-center justify-between w-full my-10">
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300"></div>
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-themecolor dark:bg-white transition-all duration-500"></div>
            <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor rounded-full place-items-center">
              <FaUser className="w-5 h-5" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-gray-300 rounded-full place-items-center">
              <FaCalendar className="w-5 h-5 text-black" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-gray-300 rounded-full place-items-center">
              <FaAddressCard className="w-5 h-5 text-black  " />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className=" my-10 dark:border border-white"
          >
            <input
              type="hidden"
              name="Hid_Image"
              value={formik.values.Hid_Image}
            />
            <div className=" grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="col-span-3 bg-white dark:bg-slate-800 p-5 md:p-8 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border">
                <p className="text-center text-2xl pb-3 mb-3 border-b">
                  Personal Information
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="my-1">
                    <label className="text-base">
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Name}
                      placeholder="Please Enter Full Name"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Name && formik.errors.Name ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Name}
                      </div>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <label className="text-base">
                      Artist Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="ArtistName"
                      value={formik.values.ArtistName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Artist Name"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.ArtistName && formik.errors.ArtistName ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.ArtistName}
                      </div>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <label className="text-base">Username <span className="text-red-500">*</span></label>
                    <input
                      type="text"
                      name="Username"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Username}
                      placeholder="Please Enter Username"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Username && formik.errors.Username ? (
                      <div className="text-red-500 text-sm">{formik.errors.Username}</div>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <label className="text-base">
                      Profile Picture <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="file"
                      name="ProfilePicture"
                      accept="image/*"
                      onBlur={formik.handleBlur}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "ProfilePicture",
                          event.currentTarget.files[0]
                        );
                      }}
                      className="text-sm text-grey-500
                      file:mr-5 file:py-1.5 file:px-5
                      file:rounded-full file:border-0
                      file:text-md file:font-semibold  file:text-white
                      file:bg-gradient-to-r file:from-themecolor file:to-black
                      hover:file:cursor-pointer hover:file:opacity-80
                      p-4 py-1.5 w-full focus:outline-none my-3 rounded"
                    />
                    {profilePicture && (
                      <img
                        src={
                          typeof profilePicture === "string"
                            ? Path + profilePicture
                            : URL.createObjectURL(profilePicture)
                        }
                        alt="Profile"
                        className="mt-2 h-24 w-24 object-cover"
                      />
                    )}
                    {formik.touched.ProfilePicture &&
                    formik.errors.ProfilePicture ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.ProfilePicture}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      name="Email"
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Email Address"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Email && formik.errors.Email ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Email}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Phone Number  <span className="text-red-500">*</span>
                    </label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={formik.values.Phone}
                      onChange={handlePhoneChange} // Update the phone value with country code
                      defaultCountry="IN"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Phone && formik.errors.Phone ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Phone}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Gender <span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center mt-3 px-2 py-2.5">
                      <div className="mr-3">
                        <input
                          type="radio"
                          id="Male"
                          name="Gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="M"
                          className="mr-2"
                          checked={formik.values.Gender === "M"}
                        />
                        <label htmlFor="Male">Male</label>
                      </div>
                      <div className="">
                        <input
                          type="radio"
                          id="Female"
                          name="Gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="F"
                          className="mr-3"
                          checked={formik.values.Gender === "F"}
                        />
                        <label htmlFor="Female">Female</label>
                      </div>
                      <div className="">
                        <input
                          type="radio"
                          id="Other"
                          name="Gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="O"
                          className="mr-3"
                          checked={formik.values.Gender === "O"}
                        />
                        <label htmlFor="Other">Other</label>
                      </div>
                    </div>

                    {formik.touched.Gender && formik.errors.Gender ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Gender}
                      </div>
                    ) : null}
                  </div>
                  {ArtistStep1 === "1" ? (
                    ""
                  ) : (
                    <div className="my-1">
                      <label className="text-base">
                        Create Password <span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <input
                          name="PassWord"
                          type={passwordVisible ? "text" : "password"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.PassWord}
                          placeholder="Please Enter Password "
                          className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
                        >
                          {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                        </button>
                      </div>
                      {formik.touched.PassWord && formik.errors.PassWord ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.PassWord}
                        </div>
                      ) : null}
                    </div>
                  )}

                  <div className="my-1">
                    <label className="text-base">
                      Date of Birth <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="date"
                      name="DOB"
                      value={formik.values.DOB}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Age Range"
                      max={new Date().toISOString().split("T")[0]} // Disable future dates
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.DOB && formik.errors.DOB ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.DOB}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Select City <span className="text-red-500">*</span>
                    </label>
                    <select
                      name="Address"
                      value={formik.values.Address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    >
                      <option className="text-base">Select City</option>
                      {CityData?.map((val, index) => {
                        return (
                          <option key={index} value={val.Id}>
                            {val.Title}
                          </option>
                        );
                      })}
                    </select>
                    {formik.touched.Address && formik.errors.Address ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Address}
                      </div>
                    ) : null}
                  </div>
                </div>
                <p className="text-center text-2xl pb-3 mb-3 border-b"></p>
                <div className="rounded-md mt-5 md:mb-10">
                  <div className="md:mb-4">
                    <p>Your Social Media Assets</p>
                    <small className="text-gray-400">
                      Select Social Media Assets and Add Links
                    </small>
                  </div>
                  <div className="grid md:grid-cols-4 grid-cols-1 gap-5 mt-2">
                    <div className="my-1">
                      <label className="text-base">Instagram</label>
                      <input
                        type="text"
                        name="Instragram"
                        value={formik.values.Instragram}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Instagram URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Instragram && formik.errors.Instragram ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Instragram}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">YouTube</label>
                      <input
                        type="text"
                        name="YouTube"
                        value={formik.values.YouTube}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter YouTube URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.YouTube && formik.errors.YouTube ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.YouTube}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">Facebook</label>
                      <input
                        type="text"
                        name="Facebook"
                        value={formik.values.Facebook}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Facebook URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Facebook && formik.errors.Facebook ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Facebook}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">Twitter</label>
                      <input
                        type="text"
                        name="Twitter"
                        value={formik.values.Twitter}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Twitter URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Twitter && formik.errors.Twitter ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Twitter}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">LinkedIn</label>
                      <input
                        type="text"
                        name="LinkedIn"
                        value={formik.values.LinkedIn}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter LinkedIn URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.LinkedIn && formik.errors.LinkedIn ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.LinkedIn}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">TikTok</label>
                      <input
                        type="text"
                        name="TikTok"
                        value={formik.values.TikTok}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter TikTok URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.TikTok && formik.errors.TikTok ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.TikTok}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">Other</label>
                      <input
                        type="text"
                        name="Other"
                        value={formik.values.Other}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Other URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Other && formik.errors.Other ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Other}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mt-5">
                  <button
                    type="submit"
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 mx-auto"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterArtist;
