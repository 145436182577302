import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import titleimage from "../../Images/title-icon.png";
import {FaFacebook,FaFacebookF,FaInstagram,FaLinkedin,FaSignLanguage,FaYoutube} from "react-icons/fa";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import servicemid from "../../Images/our-services-model.png";
import { getAllSlider, getPopularInfluencer } from "../../API/HomeApi";
import { useData } from "../../Context/DataContext ";
import NoDataFound from "../../common/NodataFound";
import usePageSEO from "../usepageSEO/Index";
import PopularInfluencerImg from "../../Images/home-page-back-1.png";
import { getAllCategory } from "../../API/CategoryAPi";
import { getAllModel, getAllArtist } from "../../API/NewInfluencerAPI";
import { getAllInfluencer } from "../../API/ModelInfMain";


const Home = () => {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  usePageSEO(
    "Best Influencer Marketing Agency In India - ViralKar", // Use page title for SEO if available
    "Struggling to grow your brand online? Discover the best influencer marketing agency in India, driving real engagement and ROI for your business success.", // Use page description for SEO if available
    [
      "influencer marketing agency india, influencer agency india, influencer agency in india, best influencer marketing agency in india, influencer marketplace india",
    ] // No keywords provided in this example
  );

  const [SliderData, setSliderData] = useState(null);
  const [PopularInfluencer, setPopularInfluencer] = useState(null);
  const [ArtistData, setArtistData] = useState(null);
  const { CityData, ServiceData } = useData();
  const [InfluencerData, setInfluencerData] = useState(null);
  const [ModelData, setModelData] = useState(null);
  const [CategoryData, setCategoryData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  const fetchSlider = async () => {
    try {
      const response = await getAllSlider();
      setSliderData(response);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchPopularInfluencer = async () => {
    try {
      const response = await getPopularInfluencer();
      setPopularInfluencer(response);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchSlider();
    fetchPopularInfluencer();
  }, []);

  const getData = async () => {
    try {
      const results = await getAllInfluencer();
      setInfluencerData(results);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCategoryData = async () => {
    try {
      const categoryResult = await getAllCategory();
      setCategoryData(categoryResult);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false when data fetching is complete
    }
  };

  const getModelData = async () => {
    try {
      const results = await getAllModel();
      setModelData(results);
    } catch (error) {
      console.error(error);
    }
  };

  const getArtistData = async () =>{
    try {
      const results = await getAllArtist();
      setArtistData(results);
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    getData();
    fetchCategoryData();
    getModelData();
    getArtistData();
  }, []);



  const [selectedCategory, setSelectedCategory] = useState(null);
  useEffect(() => {
    if (CategoryData && CategoryData.length > 0) {
      setSelectedCategory(CategoryData[0]);
    }
  }, [CategoryData]); // Add CategoryData as a dependency

  // Handle tab click and set the selected main category
  const handleTabClick = (category) => {
    setSelectedCategory(category);
  };
  return (
    <div className="dark:bg-darkmode ">
      <Slider {...settings}>
        {SliderData?.map((val, index) => {
          return (
            <div key={index}>
              <div
                className="subscribe-bg relative bg-cover bg-center sm:h-96 surface:h-72 banner-images bg-no-repeat before:content-[''] before:absolute before:top-0 before:left-0 before:right-0 before:w-full before:h-full before:z-10 before:bg-black/5"
                style={{ backgroundImage: `url(${val.Image})` }}
              >
                <div className="container mx-auto relative z-10 px-2 py-5 surface:py-16 lg:py-32 hidden">
                  <div className="grid grid-cols-1">
                    <div className="">
                      <h2 className="lg:text-6xl surface:text-3xl text-xl font-medium lg:py-2 text-white uppercase ">
                        Welcome to
                      </h2>
                      <h2 className="surface:text-4xl lg:text-6xl text-xl font-medium lg:py-2 mb-2 md:mb-4 text-white uppercase ">
                        ViralKar.com
                      </h2>
                      <p className="text-white  xl:pe-96 md:pe-32 text-xs lg:text-base md:w-9/12">
                        Discover a world of opportunities for influencers,
                        models, and artists. Join ViralKar.com to connect with
                        brands, showcase your talent, and take your career to
                        new heights.
                      </p>
                      <div className="flex mt-5 space-x-6">
                        <Link to={"/sign-up"}>
                          <button className="bg-themecolor py-2 px-5 lg:text-md uppercase text-sm md:px-8 md:py-3 font-medium text-white rounded-full hover:bg-black hover:text-white">
                            Sign Up Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>

      {/*======================== Popular Influencer ======================*/}
      <div className="lg:pt-10 surface:py-4 py-8 pb-0">
  <div className="flex items-center justify-center flex-col md:mb-5">
    <p className="page-title text-center dark:text-white capitalize pb-3">
      Popular Influencer
    </p>
    <img src={titleimage} className="text-center title-icon" alt="" />
  </div>

  <div
    className="subscribe-bg relative bg-cover bg-right bg-no-repeat bg-gray-50 dark:bg-gray-200"
    style={{ backgroundImage: `url(${PopularInfluencerImg})` }}
  >
    <div className="container mx-auto relative z-10 lg:pt-10 lg:pb-10 py-10 md:pb-0 px-4 mt-5">
      {PopularInfluencer?.length === 0 ? (
        <NoDataFound />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 container mx-auto">
          {PopularInfluencer?.slice(0, 8)?.map((val, index) => {
            return (
              <Link to={`/influencer/${val.CityName}/${val.UserName}`} key={index}>
                <div className="item pb-[146px] group relative grid grid-cols-1">
                  <div className="relative overflow-hidden surface:h-[18rem] xl:h-[22rem] h-60">
                    <img
                      className="block w-full transform-style-[preserve-3d] h-full object-cover object-top rounded-t-lg"
                      src={val.Path + val.ProfileImage}
                      alt="#"
                    />
                  </div>
                  <div className="border-l-3 border-[#95c6d8] shadow-md rounded-b-lg bottom-0 left-0 overflow-hidden perspective-[500px] min-h-[146px] absolute right-0 px-[20px] pt-[20px] transition-all duration-500 ease-in-out bg-white dark:bg-darkmode dark:text-white z-20 group-hover:bg-[#f5f5f5] dark:group-hover:bg-darkmode group-hover:border-l-transparent group-hover:pt-[37px] group-hover:-translate-y-4">
                    <h3 className="text-xl text-left font-semibold leading-[1] tracking-normal mb-[18px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                      {val.Name}
                    </h3>
                    <h3 className="text-md text-left leading-[1] tracking-normal mb-[18px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                      {val.Name}
                    </h3>

                    {(val.Instragram  || val.Facebook || val.Twitter || val.Youtube || val.LinkedIn) && (
                      <div className="absolute bottom-0 right-0 top-0 w-[40px] bg-themecolor1 opacity-0 transition-all duration-500 ease-in-out transform translate-y-[50px] group-hover:translate-y-0 group-hover:opacity-100 flex flex-col justify-center space-y-4 text-white text-xl text-center">
                        {val.Instragram && (
                          <Link to={"https://www.instagram.com/viralkar.official/"} target="_blank" className=" text-white text-lg mx-1 drop-shadow-lg">
                            <FaInstagram className="mx-auto" />
                          </Link>
                        )}
                        {val.Facebook && (
                          <Link to={"https://www.facebook.com/viralkarr/"} target="_blank" className="text-white text-lg mx-1 drop-shadow-lg">
                            <FaFacebook className="text-lg mx-auto" />
                          </Link>
                        )}
                        {val.Twitter && (
                          <Link to={"https://x.com/viral_kar_"} target="_blank" className="text-white text-lg mx-1 drop-shadow-lg">
                            <FaXTwitter className="text-lg mx-auto" />
                          </Link>
                        )}
                        {val.YouTube && (
                          <Link to={"https://www.youtube.com/@ViralKarr"} target="_blank" className="text-white p-2 text-lg mx-1 drop-shadow-lg">
                            <FaYoutube className="text-lg mx-auto" />
                          </Link>
                        )}
                        {val.LinkedIn && (
                          <Link to={"https://www.linkedin.com/company/viralkar"} target="_blank" className="text-white p-2 text-lg mx-1 drop-shadow-lg">
                            <FaLinkedin className="text-lg mx-auto" />
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      )}

      <div className="mt-10">
        <Link to={`/popular-influencers`}>
          <button
            className="bg-themebgcolor3 hover:bg-themecolor1 text-md font-semibold mt-5 mx-auto block py-3 px-8 text-white rounded-full"
            type="button"
          >
            View All Influencers
          </button>
        </Link>
      </div>
    </div>
  </div>
      </div>

      {/*======================== Influencer Categories ======================*/}
      <div className="lg:py-12 px-4 md:px-0 py-8 container mx-auto">
        {/* Title */}
        <div className="flex items-center justify-center flex-col">
          <p className="page-title text-center text-2xl  dark:text-white pb-3">
            Viralkar Categories
          </p>
          <img src={titleimage} className="text-center title-icon" alt="" />
        </div>
        {/* Main Category Tabs */}
        <div className="tabs-nav flex justify-center mt-10">
          {CategoryData?.map((category) => (
            <button
              key={category.Id}
              onClick={() => handleTabClick(category)}
              className={` tab-link md:px-8 px-4 py-2 mx-2 text-sm md:text-lg rounded-t-lg  md:font-medium text-gray-700  dark:text-white border-b-0 ${selectedCategory?.Id === category.Id
                ? "bg-themebgcolor3 font-semibold  border border-themebgcolor3 dark:bg-themecolor1 border-b-0 text-white"
                : "border border-themebgcolor3 dark:border-themecolor1  border-b-0"
                }`}
            >
              {category.Title}
            </button>
          ))}
        </div>
        <div className="border border-themebgcolor3 dark:border-themecolor shadow-lg rounded-lg p-2 md:p-5 bg-white dark:bg-slate-900 dark:text-white">
          {selectedCategory && (
            <div className="subcategories text-center ">
              <ul className=" grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 md:gap-4 gap-3 md:py-5 py-2">
                {selectedCategory?.ChildCat?.map((subcat) => (
                  <Link
                    key={subcat.Id}
                    to={`/category/${selectedCategory.Slug}/${subcat.Slug}`}
                    state={{ CategoryId: subcat.Id }} // Pass the state here
                    className="md:mx-4 mx-1 flex items-center justify-center shadow-lg rounded-lg border border-gray-50 p-2"
                  >
                    <Link
                      to={`/category/${selectedCategory.Slug}/${subcat.Slug}`}
                      state={{ CategoryId: subcat.Id }} // Pass the state here
                      className="md:text-sm text-xs font-medium text-gray-600 dark:text-white flex items-center justify-center md:h-10 transition-colors"
                    >
                      {subcat.Title}
                    </Link>
                  </Link>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      {/*================================ Our Services ========================*/}
      <div className="">
        <div className="our-services-section  ">
          <div className="flex items-center justify-center flex-col mb-10">
            <p className="page-title text-center text-white-1 pb-3">Our Services</p>
            <img src={titleimage} className="text-center title-icon" alt="" />
          </div>
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 px-5 gap-4">
            {" "}
            <div className="my-auto">
              {ServiceData?.slice(0, 4).map((val, index) => {
                return (
                  <Link to={`/service/${val.Id}`} key={index}>
                    <div className="flex items-center bg-white my-4 p-4 px-5 rounded">
                      <img src={val.Icon} className="lg:w-10 w-8" alt="" />
                      <span className="ml-6 lg:text-base font-bold text-sm">
                        {val.Title}
                      </span>
                    </div>
                  </Link>
                );
              })}
            </div>
            <div className="mx-auto text-white">
              <img src={servicemid} alt="" className="w-96" />

              <Link to={"/service"}>
                <button className="block m-auto py-3 px-8 bg-themecolor lg:mt-10 mt-5 rounded-full text-sm lg:text-base">
                  Learn More About Our Services
                </button>
              </Link>
            </div>
            <div className="my-auto">
              {ServiceData?.slice(4, 8).map((val, index) => {
                return (
                  <Link to={`/service/${val.Id}`} key={index}>
                    <div className="flex items-center bg-white my-4 p-4 px-5 rounded">
                      <img src={val.Icon} className="lg:w-10 w-8" alt="" />
                      <span className="ml-6 lg:text-base font-bold text-sm">
                        {val.Title}
                      </span>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/*============================== Find Model========================= */}
      <div className="bg-themebgcolor dark:bg-slate-950 pb-10 surface:pt-14 pt-20 bg-home-page-back">
        <div className="container mx-auto">
          <div className="flex items-center justify-center flex-col mb-10">
            <p className="page-title text-center dark:text-white">Find Model</p>
            <img src={titleimage} className="text-center title-icon" alt="" />
          </div>
          {ModelData?.length === 0 ? (
            <NoDataFound />
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-2 ">
              {ModelData?.slice(0, 6)?.map((val, index) => {
                return (
                  <div key={index} className="mb-5 px-3">
                    <div className="bg-white p-5 rounded-lg dark:border-gray-500 shadow-[0px_0px_12px_0px_rgba(0,0,0,0.3)] dark:bg-slate-900 border dark:text-white">
                      <div className="grid grid-cols-1 md:grid-cols-5 border-b border-gray-200">
                        <div className="col-span-2">
                          <Link to={`/model/${val.CityName}/${val.StageName}`}>
                            <div className=" md:p-5 md:pl-0 p-0">
                              <div className="border rounded-md">
                                <img
                                  src={val.Path + val.ProfileImage}
                                  alt=""
                                  className="md:h-60 w-full p-2 border-themecolor h-72 surface:h-60 object-cover object-top"
                                />
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-span-3 md:py-5 py-3">
                          <div>
                            <div className="flex items-center">
                              <Link to={`/model/${val.CityName}/${val.StageName}`}>
                                <p className="text-md font-semibold text-black dark:text-white my-2">
                                  {val.Name}
                                </p>
                              </Link>
                              <span className="text-themecolor ml-2 text-sm font-normal">
                                {val.Gender === "F"
                                  ? "(Female)"
                                  : val.Gender === "M"
                                    ? "(Male)"
                                    : val.Gender === "K"
                                      ? "(Kids)"
                                      : ""}
                              </span>
                            </div>
                            <div className="md:flex items-center text-textcolor dark:text-white mb-2">
                              <p className="flex items-center">
                                <FaLocationDot className="text-green-500 text-sm" />
                                <span className="px-1.5 text-sm">{val.CityName}</span>
                              </p>
                            </div>

                            <p className="flex flex-wrap">
                              <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                <span className="text-gray-600 dark:text-gray-300 text-sm">
                                  Height :
                                </span>{" "}
                                <span className="text-themecolor font-semibold">
                                  {val.Height}
                                </span>
                              </p>
                              <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                <span className="text-gray-600 dark:text-gray-300 text-sm">
                                  Weight :
                                </span>{" "}
                                <span className="text-themecolor font-semibold">
                                  {val.Weight}
                                </span>
                              </p>

                              <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                <span className="text-gray-600 dark:text-gray-300 text-sm">
                                  Bust :
                                </span>{" "}
                                <span className="text-themecolor font-semibold">
                                  {val.Bust}
                                </span>
                              </p>
                              <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                <span className="text-gray-600 dark:text-gray-300 text-sm">
                                  Waist :
                                </span>{" "}
                                <span className="text-themecolor font-semibold">
                                  {val.Waist}
                                </span>
                              </p>
                              <p className="border text-gray-600 dark:text-gray-300 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                <span className="text-gray-600 dark:text-gray-300 text-sm">
                                  Hips :
                                </span>{" "}
                                <span className="text-themecolor font-semibold">
                                  {val.Hips}
                                </span>
                              </p>
                            </p>


                            {(val.Instagram || val.Facebook || val.Twitter || val.YouTube || val.LinkedIn) && (
                                  <div>
                                    <p className="flex items-center text-sm dark:text-white my-2 md:mb-3 text-gray-500">
                                      {val.Name}'s Social
                                    </p>

                                    <div className="text-white flex items-center mb-5">
                                      {val.Instagram && (
                                        <Link to={"https://www.instagram.com/viralkar.official/"} target="_blank" className="bg-instagram text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                                          <FaInstagram className="text-lg" />
                                        </Link>
                                      )}
                                      {val.Facebook && (
                                        <Link to={"https://www.facebook.com/viralkarr/"} target="_blank" className="bg-[#0165E1] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                                          <FaFacebook className="text-lg" />
                                        </Link>
                                      )}
                                      {val.Twitter && (
                                        <Link to={"https://x.com/viral_kar_"} target="_blank" className="bg-black text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                                          <FaXTwitter className="text-lg" />
                                        </Link>
                                      )}
                                      {val.YouTube && (
                                        <Link to={"https://www.youtube.com/@ViralKarr"} target="_blank" className="bg-red-600 text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                                          <FaYoutube className="text-lg" />
                                        </Link>
                                      )}
                                     {val.LinkedIn && (
                                        <Link to={"https://www.linkedin.com/company/viralkar"} target="_blank" className="bg-[#0077b5] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                                          <FaLinkedin className="text-lg" />
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                              )}  

                          </div>
                        </div>
                      </div>
                      <Link to={`/model/${val.CityName}/${val.StageName}`}>
                        <button
                          className="bg-themebgcolor3 hover:bg-themecolor1 dark:bg-themecolor1 text-sm mt-5 mx-auto block py-2 px-5 text-white rounded-full"
                          type="button"
                        >
                          Send Message to {val.name}
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="mt-10">
            <Link to={`/find-model`}>
              <button
                className="bg-themebgcolor3 hover:bg-themecolor1 text-md font-semibold mt-5 mx-auto block py-3 px-8 text-white rounded-full"
                type="button"
              >
                View All Model

              </button>
            </Link>
          </div>
        </div>
      </div>
      {/*============================== Micro-Influencers in India ========================= */}
      <div className="bg-white dark:bg-darkmode lg:py-8 py-8">
        <div className="flex items-center justify-center flex-col">
          <p className="page-title text-center dark:text-white">
            Micro Influencers in India
          </p>
          <img src={titleimage} className="text-center title-icon" alt="" />
        </div>
        <div className="container mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 md:gap-8 surface:px-16 md:px-36 px-3 lg:mt-16 my-5">
          {CityData?.sort((a, b) => a.Title.localeCompare(b.Title)).map(
            (val, index) => {
              return (
                <Link
                  key={index}
                  to={`/city/${val.Slug}`}
                  state={{ id: val.Id }}
                >
                  <div className="rounded-xl shadow-[0_0px_15px_-6px_rgba(0,0,0,0.3)] hover:translate-y-[-5px] transition ease duration-300 dark:border">
                    <img
                      src={val.Icon}
                      className="w-full lg:h-36 h-32 rounded-t-xl"
                      alt=""
                    />
                    <p className="text-center py-3 text-black dark:text-white dark:bg-slate-900">
                      {val.Title}
                    </p>
                  </div>
                </Link>
              );
            }
          )}
        </div>
      </div>
      <div className="bg-gray-50 dark:bg-slate-950">
        <div className="container mx-auto lg:py-12 py-8 dark:text-white px-5 md:px-0">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center text-black dark:text-white capitalize pb-3">Find Artist in India</p>
            <img src={titleimage} className="text-center title-icon" alt="" />
          </div>
          {ArtistData?.length === 0 ? (
            <NoDataFound />
          ) : (
            <div className="lg:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-16 gap-4 px-0 surface:px-5 md:px-12 xl:px-24">
              {ArtistData?.slice(0, 3)?.map((val, index) => (
                <div key={index} className="px-2 py-4 border mb-4 shadow-sm md:py-4 md:px-4 flex flex-col max-w-[24rem] justify-start rounded-[2.5rem] bg-gradient-to-b from-[#efefef] to-transparent">
                  <div className="h-fit overflow-hidden min-w-[18rem] min-h-[18rem] max-w-[18rem] max-h-[18rem] sm:min-w-[20rem] sm:min-h-[20rem] sm:max-w-[20rem] sm:max-h-[20rem] md:min-w-[22rem] md:min-h-[22rem] md:max-w-[22rem] md:max-h-[22rem] lg:min-w-[18rem] lg:min-h-[18rem] lg:max-w-[18rem] lg:max-h-[18rem] xl:min-w-[22rem] xl:min-h-[22rem] xl:max-w-[22rem] xl:max-h-[22rem] rounded-t-full rounded-br-full mx-auto">
                    <a href={`/artist/${val.CityName}/${val.ArtistName}`}>
                      <img
                        src={val.Path + val.ProfileImage}
                        alt=""
                        className="min-w-[18rem] min-h-[18rem] max-w-[18rem] max-h-[18rem] sm:min-w-[20rem] sm:min-h-[20rem] sm:max-w-[20rem] sm:max-h-[20rem] md:min-w-[22rem] md:min-h-[22rem] md:max-w-[22rem] md:max-h-[22rem] lg:min-w-[18rem] lg:min-h-[18rem] lg:max-w-[18rem] lg:max-h-[18rem] xl:min-w-[22rem] xl:min-h-[22rem] xl:max-w-[22rem] xl:max-h-[22rem] rounded-t-full rounded-br-full object-cover flex justify-center text-center items-center transition-all duration-300 cursor-pointer scale-100 hover:scale-110 object-top"
                      />
                    </a>
                  </div>
                  <div className="font-semibold leading-[38.73px] text-xl dark:text-themecolor mt-4 mb-2 line-clamp-1">
                    <Link to={`/artist/${val.CityName}/${val.ArtistName}`}> {val.Name}</Link>
                  </div>
                  <div className="flex items-center mb-3">
                    <FaSignLanguage className="text-sm text-themecolor" />
                    <label className="ml-2 text-sm leading-4 font-normal text-themebgcolor3 dark:text-white line-clamp-2">
                      English,Hindi,Marathi 
                    </label>
                  </div>
                  <div className="md:flex items-center text-textcolor dark:text-white">
                    <p className="flex items-center">
                      <FaLocationDot className="text-green-500 text-sm" />
                      <span className="px-1.5 text-sm dark:text-white">Mumbai</span>
                    </p>
                  </div>
                  
                  {(val.Instagram || val.Facebook || val.Twitter || val.YouTube || val.LinkedIn) && (
                    <div>
                      <p className="flex items-center text-sm dark:text-white my-2 md:mb-3 text-gray-500">
                        {val.Name}'s Social
                      </p>

                      <div className="text-white flex items-center mb-5">
                        {val.Instagram && (
                          <Link to={"https://www.instagram.com/viralkar.official/"} target="_blank" className="bg-instagram text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                            <FaInstagram className="text-lg" />
                          </Link>
                        )}
                        {val.Facebook && (
                          <Link to={"https://www.facebook.com/viralkarr/"} target="_blank" className="bg-[#0165E1] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                            <FaFacebook className="text-lg" />
                          </Link>
                        )}
                        {val.Twitter && (
                          <Link to={"https://x.com/viral_kar_"} target="_blank" className="bg-black text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                            <FaXTwitter className="text-lg" />
                          </Link>
                        )}
                        {val.YouTube && (
                          <Link to={"https://www.youtube.com/@ViralKarr"} target="_blank" className="bg-red-600 text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                            <FaYoutube className="text-lg" />
                          </Link>
                        )}
                        {val.LinkedIn && (
                          <Link to={"https://www.linkedin.com/company/viralkar"} target="_blank" className="bg-[#0077b5] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                            <FaLinkedin className="text-lg" />
                          </Link>
                        )}
                      </div>
                    </div>
                    )}   
                </div>
              ))}
            </div>
          )}

          <div className="mt-5">
            <Link to={`/find-artist`}>
              <button
                className="bg-themebgcolor3 hover:bg-themecolor1 text-md font-semibold mt-5 mx-auto block py-3 px-8 text-white rounded-full"
                type="button"
              >
                View All Artist

              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
