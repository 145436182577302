import React, { useState, useEffect } from "react";
import TermsBanner from "../../Images/TermsConditionBanner.jpeg"; // Adjust the path as needed
import Breadcrumb from "../../Breadcumb/Breadcumb";
import { GetPageBySlug } from "../../API/PagesApi";
import usePageSEO from "../usepageSEO/Index";

const TermsAndConditions = () => {
  const [termsData, setTermsData] = useState(null); // Use object or array depending on the structure
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const breadcrumbItems = [
    {
      text: "Privacy Policy",
    },
  ];

  useEffect(() => {
    // Fetch the privacy policy content dynamically
    const fetchTerms = async () => {
      try {
        const data = await GetPageBySlug("privacy-policy");

        // Log the data for debugging
        console.log("API Data:", data);

        if (data && data.length > 0) {
          setTermsData(data[0]); // Access the first object in the array
        } else {
          setError("No content available for the privacy policy.");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching privacy policy:", error);
        setError("Failed to load privacy policy. Please try again later.");
        setLoading(false);
      }
    };

    fetchTerms();
  }, []);

  usePageSEO(
    termsData ? termsData.SeoTitle : "Viral kar | Influencer",
    termsData ? termsData.SeoKeyword : "Viral kar | Influencer",
    [termsData ? termsData.SeoDescription : "Viral kar | Influencer"]
  );

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      <Breadcrumb items={breadcrumbItems} backgroundImage={TermsBanner} />
      <main className="bg-gray-100 dark:bg-darkmode min-h-screen">
        <section className="py-12 px-4">
          <div className="container mx-auto bg-white dark:bg-slate-950 dark:border dark:border-gray-500 lg:p-8 p-4 rounded-lg shadow-lg">
            <div className="space-y-8">
              <div className="mb-8">
                {/* Render the title */}
                <h2 className="text-2xl font-semibold text-gray-700 dark:text-white mb-4">
                  {termsData?.Title || "Privacy Policy"}
                </h2>

                {/* Render the content as HTML */}
                <div
                  className="text-gray-600 dark:text-slate-200 leading-relaxed text-sm lg:text-base"
                  dangerouslySetInnerHTML={{ __html: termsData?.Content || "<p>No content available.</p>" }} // Inject HTML content
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default TermsAndConditions;
