import React from "react";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";
import titleimage from "../Images/title-icon.png";


const Breadcrumb = ({ items, backgroundImage }) => {
  // const breadcrumbStyle = {
  //   backgroundImage: `url(${backgroundImage})`,
  //   backgroundSize: "cover",
  //   position: "relative",
  //   backgroundPosition: "top",
  //   zIndex: 1,
  // };

  return (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <div className="breadcrumb-bg bg-gray-200 dark:bg-gray-300">
        <div className="breadcrumb-before"></div>
        <ul className="breadcrumb-list">
          {items.map((item, index) => (
            <li key={index} className="breadcrumb-item">
              <div className="lg:px-32">
                  <h1 className=" text-lg md:text-2xl text-center capitalize text-black pb-1 dark:text-themecolor1">
                  {item.text}
                  </h1>

                  <img
                    src={titleimage}
                    alt=""
                    className="mx-auto title-icon h-5"
                  />
                </div>
              <h1 className="breadcrumb-text uppercase ">
              
              </h1>
              <div className="flex pt-8 justify-center space-x-4 text-center text-base text-black hidden">
                <Link to={"/"}>
                  <h1 className=" hover:text-themecolor duration-300">Home</h1>
                </Link>
                <p className="my-auto">
                  <GoDotFill />
                </p>
                <p>{item.text}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Breadcrumb;
