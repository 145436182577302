import React, { useEffect, useState } from "react";
import titleimage from "../../Images/title-icon.png";
import { IoSearchOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FilterModelsAPI } from "../../API/ModelSignUpAPI";
import NoDataFound from "../../common/NodataFound";
import { FaBlog, FaChevronDown, FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { getAllCategory } from "../../API/CategoryAPi";
import { getAllCity } from "../../API/CityApi";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../usepageSEO/Index";
import BreadCrumb from "../../Breadcumb/Breadcumb";

const FindModel = () => {
  const breadcrumbItems = [
    {
      text: " Models",
    },
  ];
  const [CategoryData, setCategoryData] = useState([]);
  const [CityData, setCityData] = useState([]);
  const [visibleItems, setVisibleItems] = useState(5); // Number of items to show
  const [hasMore, setHasMore] = useState(true); // Flag to indicate if there are more items
  const [searchQuery, setSearchQuery] = useState(""); // Search query state

  const [loading, setLoading] = useState(true); // Loading state

  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showCityDropdown, setShowCityDropdown] = useState(false);
  const [showGenderDropdown, setShowGenderDropdown] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryResult, cityResult] = await Promise.all([
          getAllCategory(),
          getAllCity(),
        ]);
        setCategoryData(categoryResult);
        setCityData(cityResult);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  usePageSEO(
    "Viral kar | Model , Influencer", // Use page title for SEO if available
    "Viral kar | Model , Influencer", // Use page description for SEO if available
    ["Viral kar | Model , Influencer"] // No keywords provided in this example
  );

    // ------------------------Filter-----------------------
    const [selectedCategories, setSelectedCategories] = useState({});
    const [selectedCities, setSelectedCities] = useState({});
    const [FilteredVenueData, setFilteredVenueData] = useState([]);
    const [selectedGenders, setSelectedGenders] = useState({});
  
    const handleCategoryFilterChange = (id) => {
      setSelectedCategories((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    };
  
    const handleCityFilterChange = (id) => {
      setSelectedCities((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    };
  
    const handleGenderFilterChange = (gender) => {
      setSelectedGenders((prev) => ({
        ...prev,
        [gender]: !prev[gender],
      }));
    };
  
    useEffect(() => {
      const fetchFilteredData = async () => {
        setLoading(true);
        try {
          const CityId = Object.keys(selectedCities)
            .filter((filter) => selectedCities[filter])
            .join("|");
          const InterestId = Object.keys(selectedCategories)
            .filter((filter) => selectedCategories[filter])
            .join("|");
          const GenderIds = Object.keys(selectedGenders)
            .filter((filter) => selectedGenders[filter])
            .join("|");
  
  
            const response = await FilterModelsAPI(CityId, InterestId, GenderIds);
          setFilteredVenueData(response.slice(0, 50));
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchFilteredData();
    }, [selectedCategories, selectedCities,selectedGenders]);
  
    // Filter influencers based on search query
    const filteredInfluencers = FilteredVenueData?.filter((influencer) =>
      influencer.Name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const loadMore = () => {
      setVisibleItems((prev) => {
        const newVisibleItems = prev + 2;
        if (newVisibleItems >= FilteredVenueData?.length) {
          setHasMore(false);
          return FilteredVenueData?.length;
        }
        return newVisibleItems;
      });
    };

  return (
    <div>
      <div className="dark:bg-darkmode">
      <BreadCrumb items={breadcrumbItems} />
        <div
          className="subscribe-bg lg:mb-0 relative bg-gray-50 dark:bg-slate-950 px-2 xl:px-0 lg:px-4">
          <div className="container mx-auto  pt-5 md:pt-10 pb-5">
            <div className="grid grid-cols-1 lg:grid-cols-4 xl:gap-10 lg:gap-5">

              <div className="mb-10"> 
              <div className="lg:sticky lg:top-24">
                <div className="mb-5">
                  <div className="flex items-center w-full border border-gray-300 dark:border-slate-200 rounded bg-white dark:bg-darkmode dark:text-white">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="border-r border-gray-300 dark:border-slate-200 p-3 w-full outline-none bg-transparent"
                      placeholder="Search by Names"
                    />
                    <h1 className="my-auto text-3xl mx-auto px-4 ">
                      <IoSearchOutline />
                    </h1>
                  </div>
                </div>
                <div className="relative">
                  <button
                    onClick={() =>
                      setShowCategoryDropdown(!showCategoryDropdown)
                    }
                    className="w-full text-left rounded text-gray-600 bg-white dark:bg-darkmode border border-gray-300 dark:border-slate-200 py-3 px-3 flex items-center dark:text-white"
                  >
                    Categories
                    <span className="ml-auto">
                      <FaChevronDown />
                    </span>
                  </button>
                  {showCategoryDropdown && (
                    <div className="z-10 bg-white rounded dark:bg-darkmode border border-gray-300 dark:border-slate-200 w-full border-t-0 overflow-y-auto max-h-64 dark:text-white ">
                      <form className="mx-auto w-full p-2">
                        {CategoryData[0]?.ChildCat?.map((val) => (
                          <div key={val.Id} className="my-2">
                            <input
                              type="checkbox"
                              id={`category-${val.Id}`}
                              checked={!!selectedCategories[val.Id]}
                              onChange={() =>
                                handleCategoryFilterChange(val.Id)
                              }
                              className="mr-2"
                            />
                            <label className="text-sm text-gray-600 dark:text-white" htmlFor={`category-${val.Id}`}>
                              {val.Title}
                            </label>
                          </div>
                        ))}
                      </form>
                    </div>
                  )}
                </div>
                <div className="relative mt-3">
                  <button
                    onClick={() => setShowCityDropdown(!showCityDropdown)}
                    className="w-full text-left rounded text-gray-600 bg-white dark:bg-darkmode border border-gray-300 dark:border-slate-200 py-3 px-3 flex items-center dark:text-white"
                  >
                    City
                    <span className="ml-auto">
                      <FaChevronDown />
                    </span>
                  </button>
                  {showCityDropdown && (
                    <div className="z-10 bg-white rounded border  border-themecolor dark:border-slate-200 w-full border-t-0 overflow-y-auto max-h-64 dark:bg-darkmode dark:text-white">
                      <form className="mx-auto w-full p-2">
                        {CityData?.map((val) => (
                          <div key={val.Id} className="my-2">
                            <input
                              type="checkbox"
                              id={`city-${val.Id}`}
                              checked={!!selectedCities[val.Id]}
                              onChange={() => handleCityFilterChange(val.Id)}
                              className="mr-2"
                            />
                            <label className="text-sm text-gray-600 dark:text-white" htmlFor={`city-${val.Id}`}>
                              {val.Title}
                            </label>
                          </div>
                        ))}
                      </form>
                    </div>
                  )}
                </div>

                  {/* Gender Filter */}
             <div className="relative mt-3">
                <button
                  onClick={() => setShowGenderDropdown(!showGenderDropdown)}
                  className="w-full text-left rounded text-gray-600 bg-white dark:bg-darkmode border border-gray-300 dark:border-slate-200 py-3 px-3 flex items-center dark:text-white"
                >
                  Gender
                  <span className="ml-auto">
                    <FaChevronDown />
                  </span>
                </button>
                {showGenderDropdown && (
                  <div className="z-10 bg-white border border-gray-300 rounded dark:border-slate-200 w-full border-t-0 overflow-y-auto max-h-64 dark:bg-darkmode dark:text-white">
                    <form className="mx-auto w-full p-2">
                      {['Male', 'Female', 'Other'].map((gender) => (
                        <div key={gender} className="my-2">
                          <input
                            type="checkbox"
                            id={`gender-${gender}`}
                            checked={!!selectedGenders[gender]}
                            onChange={() => handleGenderFilterChange(gender)}
                            className="mr-2"
                          />
                          <label className="text-sm text-gray-600 dark:text-white" htmlFor={`gender-${gender}`}>
                            {gender}
                          </label>
                        </div>
                      ))}
                    </form>
                  </div>
                )}
              </div>
              </div>
              </div>

              <div className="col-span-3">
                {loading ? (
                  <div className="flex justify-center items-center my-40">
                    <ClipLoader color={"#f59231"} loading={loading} size={40} />
                  </div>
                ) : (
                  <React.Fragment>
                    {filteredInfluencers?.slice(0, visibleItems).length ===
                      0 ? (
                      <NoDataFound />
                    ) : (
                      <div className="grid grid-cols-1">
                        {filteredInfluencers
                          ?.slice(0, visibleItems)
                          .map((val, index) => (
                            <div
                              key={index}
                              className="mb-5 xl:px-10 surface:px-2"
                            >
                              <div className="bg-white p-5 rounded-lg dark:border-gray-500 shadow-[0px_0px_12px_0px_rgba(0,0,0,0.3)] dark:bg-slate-900 border dark:text-white">
                                <div className="grid grid-cols-1 md:grid-cols-4 border-b-2 border-gray-100 md:gap-5 pb-4">
                                  <Link to={`/model/${val.CityName}/${val.StageName}`}>
                                    <div className="col-span-1">
                                      <img
                                        src={val.Path + val.ProfileImage}
                                        alt=""
                                        className="md:h-60 w-full p-2 border-gray-200 h-72 border rounded-md surface:h-60 object-cover object-top"
                                      />
                                    </div>
                                  </Link>
                                  <div className="col-span-3 mt-4 md:mt-0">
                                    <div>
                                      <div className="flex items-center">
                                        <Link to={`/model/${val.CityName}/${val.StageName}`}>
                                          <p className="text-md font-semibold dark:text-white text-black my-2">
                                            {val.Name}
                                          </p>
                                        </Link>
                                        <span className="text-themecolor ml-2 text-sm font-normal">
                                          {val.Gender === "F"
                                            ? "(Female)"
                                            : val.Gender === "M"
                                              ? "(Male)"
                                              : val.Gender === "K"
                                                ? "(Kids)"
                                                : ""}
                                        </span>
                                      </div>

                                      <div className="md:flex items-center text-textcolor dark:text-white mb-2">
                                        <p className="flex items-center">
                                          <FaLocationDot className="text-green-500 text-sm" />
                                          <span className="px-1.5 text-sm">
                                            {val.CityName}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="md:flex items-center text-textcolor dark:text-white mb-2">
                                        <p className="flex items-center">
                                          <span className="pe-3 text-sm">Availability for Work :
                                          </span>

                                          <span className="text-sm text-themecolor1 font-semibold"> {val.AvailabilityforWork}</span>
                                        </p>
                                      </div>
                                      <p className="flex items-center text-sm dark:text-white my-2 md:mb-3 text-gray-500">
                                        Interest in :{" "}
                                        {val.ChildCategoryName?.map(
                                          (interest, index) => (
                                            <span
                                              key={index}
                                              className="p-0.5  rounded-md"
                                            >
                                              {interest}
                                            </span>
                                          )
                                        )}
                                      </p>

                                      <p className="flex flex-wrap-reverse">
                                        <p className="border text-gray-600 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                          <span className="text-gray-600 dark:text-white text-sm">
                                            Height :
                                          </span>{" "}
                                          <span className="text-themecolor font-semibold">{val.Height}</span>
                                        </p>
                                        <p className="border text-gray-600 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                          <span className="text-gray-600 dark:text-white text-sm">
                                            Weight :
                                          </span>{" "}
                                          <span className="text-themecolor font-semibold">{val.Weight}</span>
                                        </p>
                                        <p className="border text-gray-600 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                          <span className="text-gray-600 dark:text-white text-sm">
                                            Age :
                                          </span>{" "}
                                          <span className="text-themecolor font-semibold">{val.Age}</span>
                                        </p>
                                        <p className="border text-gray-600 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                          <span className="text-gray-600 dark:text-white text-sm">
                                            Bust :
                                          </span>{" "}
                                          <span className="text-themecolor font-semibold">{val.Bust}</span>
                                        </p>
                                        <p className="border text-gray-600 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                          <span className="text-gray-600 dark:text-white text-sm">
                                            Waist :
                                          </span>{" "}
                                          <span className="text-themecolor font-semibold"> {val.Waist}</span>
                                        </p>
                                        <p className="border text-gray-600 text-sm rounded-full py-1 px-3 mr-2 my-1">
                                          <span className="text-gray-600 dark:text-white text-sm">
                                            Hips :
                                          </span>{" "}
                                          <span className="text-themecolor font-semibold"> {val.Hips}</span>
                                        </p>
                                      </p>

                                      {(val.Instagram || val.Facebook || val.Twitter || val.YouTube || val.LinkedIn) && (
                                    <div>
                                    <p className="flex items-center text-sm dark:text-white my-2 md:mb-3 text-gray-500">
                                      {val.Name}'s Social
                                    </p>

                                    <div className="text-white flex items-center mb-5">
                                      {val.Instagram && (
                                        <Link to={"https://www.instagram.com/viralkar.official/"} target="_blank" className="bg-instagram text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                                          <FaInstagram className="text-lg" />
                                        </Link>
                                      )}
                                      {val.Facebook && (
                                        <Link to={"https://www.facebook.com/viralkarr/"} target="_blank" className="bg-[#0165E1] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                                          <FaFacebook className="text-lg" />
                                        </Link>
                                      )}
                                      {val.Twitter && (
                                        <Link to={"https://x.com/viral_kar_"} target="_blank" className="bg-black text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                                          <FaXTwitter className="text-lg" />
                                        </Link>
                                      )}
                                      {val.YouTube && (
                                        <Link to={"https://www.youtube.com/@ViralKarr"} target="_blank" className="bg-red-600 text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                                          <FaYoutube className="text-lg" />
                                        </Link>
                                      )}
                                     {val.LinkedIn && (
                                        <Link to={"https://www.linkedin.com/company/viralkar"} target="_blank" className="bg-[#0077b5] text-white rounded-full p-2 text-lg mx-1 drop-shadow-lg">
                                          <FaLinkedin className="text-lg" />
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                        )}  
                                    </div>
                                  </div>
                                </div>
                                <Link to={`/model/${val.CityName}/${val.StageName}`}>
                                  <button
                                    className="bg-themebgcolor3 hover:bg-themecolor1 text-sm mt-5 mx-auto block py-2 px-5 text-white rounded-full"
                                    type="button"
                                  >
                                    Send Message to {val.name}
                                  </button>
                                </Link>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    {hasMore && (
                      <div className="flex justify-center mt-4">
                        <button
                          onClick={loadMore}
                          className="bg-themecolor1 hover:bg-themebgcolor3 text-sm mx-auto block py-2 md:py-3 px-5 md:px-10 text-white rounded-full"
                        >
                          Load More
                        </button>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindModel;