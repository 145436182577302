// CookieConsent.js
import React, { useState } from "react";
import Cookies from "js-cookie"; // Optional: If using js-cookie library

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(!Cookies.get("cookiesAccepted"));

  const handleAcceptCookies = () => {
    Cookies.set("cookiesAccepted", "true", { expires: 1 }); // Optional: Expires in 365 days
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-themebgcolor3 text-white py-2 px-3 md:flex justify-between items-center z-50">
      <p className="m-0 text-xs md:text-sm text-center md:text-left mb-2 md:mb-0">
        We use cookies to improve your experience on our site. By using our
        site, you accept our cookie policy.
      </p>
      <button
        onClick={handleAcceptCookies}
        className="bg-themecolor1 text-sm mx-auto block md:mx-0 text-white px-4 py-1 rounded-full"
      >
        Accept All Cookies
      </button>
    </div>
  );
};

export default CookieConsent;
