import React, { useState } from "react";
import DashboardMenu from "../../../DashboardMenu";
import ScrollToTop from "../../ScrollToTop";
import ContactusImg from "../../../../Images/contact-back.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../../common/FormLoader";
import Config from "../../../../API/Config";
import { CampaignAdd } from "../../../../API/CampaignApi";
import { useNavigate } from "react-router";
import { useData } from "../../../../Context/DataContext ";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../../../usepageSEO/Index";

const validationSchema = Yup.object().shape({
  Type: Yup.string().required("Type is required"),
  VendorType: Yup.string().required("Vendor Type is required"),
  CategoryId: Yup.string().required("Category is required"),
  Website: Yup.string().url("Invalid URL").required("Website is required"),
  City: Yup.array()
    .of(Yup.string().required())
    .min(1, "At least one city must be selected")
    .required("City is required"),
  CampaignName: Yup.string().required("Campaign Name is required"),
  Price: Yup.string().required("Price is required"),
  CampaignDoc: Yup.mixed()
    .required("Campaign Document is required")
    .test(
      "fileFormat",
      "Only PDF files are allowed",
      (value) => value && value.type === "application/pdf"
    ),
  ServiceId: Yup.array()
    .of(Yup.string().required())
    .min(1, "At least one service must be selected")
    .required("Service is required"),
  CampaignDescription: Yup.string().required(
    "Campaign Description is required"
  ),
  InfluencerCount: Yup.string().required("Influencer Count is required"),
  StartTime: Yup.string().required("Start Time is required"),
  Instagram: Yup.string()
    .url("Invalid Instagram URL")
    .required("Instagram is required"),
  YouTube: Yup.string()
    .url("Invalid YouTube URL")
    .required("YouTube is required"),
  Facebook: Yup.string()
    .url("Invalid Facebook URL")
    .required("Facebook is required"),
});
const AddCampaign = () => {
  const backgroundImage = ContactusImg;
  const breadcrumbStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    position: "relative",
    backgroundPosition: "center",
    zIndex: 1,
    padding: "140px 0 80px 0",
  };

  usePageSEO(
    "Viral kar | Add Campaign", // Use page title for SEO if available
    "Viral kar | Add Campaign", // Use page description for SEO if available
    ["Viral kar | Add Campaign"] // No keywords provided in this example
  );

  const Id = Config.getId();
  const { CategoryData, CityData, ServiceData, loading } = useData();
  // -------------------------City Data--------------------
  // const [CategoryData, setCategoryData] = useState(null);
  // useEffect(() => {
  //   const fetchCategory = async () => {
  //     try {
  //       const result = await getAllCategory();
  //       setCategoryData(result);
  //     } catch (error) {
  //       console.error();
  //     }
  //   };
  //   fetchCategory();
  // }, []);
  // -------------------------City Data--------------------
  // const [CityData, setCityData] = useState(null);
  // useEffect(() => {
  //   const fetchCity = async () => {
  //     try {
  //       const result = await getAllCity();
  //       setCityData(result);
  //     } catch (error) {
  //       console.error();
  //     }
  //   };
  //   fetchCity();
  // }, []);
  // // -------------------------Service Data--------------------
  // const [ServiceData, setServiceData] = useState(null);
  // useEffect(() => {
  //   const fetchService = async () => {
  //     try {
  //       const result = await getAllService();
  //       setServiceData(result);
  //     } catch (error) {
  //       console.error();
  //     }
  //   };
  //   fetchService();
  // }, []);

  const usenavigate = useNavigate();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      UserId: Id,
      Type: "",
      VendorType: "",
      CategoryId: "",
      Website: "",
      City: "",
      CampaignName: "",
      Price: "",
      CampaignDoc: "",
      ServiceId: "",
      CampaignDescription: "",
      InfluencerCount: "",
      StartTime: "",
      Instagram: "",
      YouTube: "",
      Facebook: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const result = await CampaignAdd(formData);
        if (result.status === true) {
          usenavigate("/campaigns");
        } else {
        }
      } catch (error) {
        console.error("Error updating slider:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const { City } = formik.values;

    if (checked) {
      if (City.length < 10) {
        formik.setFieldValue("City", [...City, parseInt(value)]);
      }
    } else {
      formik.setFieldValue(
        "City",
        City.filter((City) => City !== parseInt(value))
      );
    }
  };
  const handleCheckboxChange2 = (event) => {
    const { value, checked } = event.target;
    const { ServiceId } = formik.values;

    if (checked) {
      if (ServiceId.length < 10) {
        formik.setFieldValue("ServiceId", [...ServiceId, parseInt(value)]);
      }
    } else {
      formik.setFieldValue(
        "ServiceId",
        ServiceId.filter((ServiceId) => ServiceId !== parseInt(value))
      );
    }
  };

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    setSelectAll(checked);

    if (checked) {
      // Add all city IDs to formik.values.City
      const allCitys = CityData.map((city) => city.Id);
      formik.setFieldValue("City", allCitys);
    } else {
      // Remove all city IDs from formik.values.City
      formik.setFieldValue("City", []);
    }
  };

  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <div className="breadcrumb-bg" style={breadcrumbStyle}>
          <div className="breadcrumb-before"></div>
          <ul className="text-white text-3xl text-center">Add Campaigns</ul>
        </div>
      </nav>
      <div className="pb-24">
        <ScrollToTop />
        <div className="w-full container mx-auto">
          <div className="w-full  lg:grid grid-cols-5 py-20 px-3 gap-3 ">
            <DashboardMenu />

            {/* ===============================Nav Pilss data ====================================*/}
            <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4">
              <div className="bg-dark-light">
                <div className="grid grid-cols-1 gap-5">
                  <form
                    onSubmit={formik.handleSubmit}
                    className="bg-dark-light p-6"
                  >
                    <p className="text-center text-xl py-1.5 mb-3 bg-themecolor">
                      Create Influencer Marketing Campaign
                    </p>

                    <div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4  lg:grid-cols-2">
                        <div className="my-1">
                          <label className="text-base">
                            I want to Type a:{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="flex items-center mt-2 dark:bg-[#1e1e1e] px-2 py-2.5">
                            <div className="mr-3">
                              <input
                                type="radio"
                                id="Product"
                                name="Type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="P"
                                checked={formik.values.Type === "P"}
                                className="mr-2"
                              />
                              <label htmlFor="Product">Product</label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id="Service"
                                name="Type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="S"
                                className="mr-3"
                                checked={formik.values.Type === "S"}
                              />
                              <label htmlFor="Service">Service </label>
                            </div>
                          </div>

                          {formik.touched.Type && formik.errors.Type ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Type}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label className="text-base">
                            I want to Hire:{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="flex items-center mt-2 dark:bg-[#1e1e1e] px-2 py-2.5">
                            <div className="mr-3">
                              <input
                                type="radio"
                                id="Model"
                                name="VendorType"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="M"
                                checked={formik.values.VendorType === "M"}
                                className="mr-2"
                              />
                              <label htmlFor="Model">Model</label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id="Influencer"
                                name="VendorType"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="I"
                                className="mr-3"
                                checked={formik.values.VendorType === "I"}
                              />
                              <label htmlFor="Influencer">Influencer </label>
                            </div>
                          </div>

                          {formik.touched.Type && formik.errors.Type ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Type}
                            </div>
                          ) : null}
                        </div>

                        <div className="my-1">
                          <label className="text-base">
                            Select Brand Category:{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <select
                            name="CategoryId"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CategoryId}
                            className="dark:bg-[#1e1e1e] px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          >
                            {CategoryData?.map((val, index) => {
                              return (
                                <option key={index} value={val.Id}>
                                  {val.Title}
                                </option>
                              );
                            })}
                          </select>
                          {formik.touched.CategoryId &&
                          formik.errors.CategoryId ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.CategoryId}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label className="text-base">
                            Brand Website:
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="Website"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Website}
                            placeholder="E.g. https://www.example.com"
                            className="dark:bg-[#1e1e1e] px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.Website && formik.errors.Website ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Website}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                        <div className="my-1">
                          <label className="text-base mb-1">
                            I want to Type my brand in:
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="grid grid-cols-2 md:grid-cols-4 dark:bg-[#1e1e1e] px-2 py-1">
                            <div className="flex items-center my-1">
                              <input
                                type="checkbox"
                                className="mr-2"
                                name="selectAll"
                                checked={selectAll}
                                id="selectAll"
                                onChange={handleSelectAllChange}
                              />
                              <label htmlFor="selectAll">Select All</label>
                            </div>
                            {CityData?.map((val, index) => (
                              <div
                                className="flex items-center my-1"
                                key={index}
                              >
                                <input
                                  type="checkbox"
                                  className="mr-2"
                                  name="City"
                                  value={val.Id}
                                  checked={formik.values.City.includes(val.Id)}
                                  id={val.Title}
                                  onChange={handleCheckboxChange}
                                />
                                <label htmlFor={val.Title}>{val.Title}</label>
                              </div>
                            ))}
                          </div>
                          {formik.touched.City && formik.errors.City ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.City}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-2 gap-4 mt-3">
                        <div className="my-1">
                          <label className="text-base">
                            Campaign Name:
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="text"
                            name="CampaignName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CampaignName}
                            placeholder="Campaign Name"
                            className="dark:bg-[#1e1e1e] px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.CampaignName &&
                          formik.errors.CampaignName ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.CampaignName}
                            </div>
                          ) : null}
                        </div>

                        <div className="my-1">
                          <label for="">
                            Upload Campaign Brief:
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="file"
                            name="CampaignDoc"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "CampaignDoc",
                                event.currentTarget.files[0]
                              );
                            }}
                            onBlur={formik.handleBlur}
                            placeholder="Business Country"
                            className="dark:bg-[#1e1e1e] p-4 py-2 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.CampaignDoc &&
                          formik.errors.CampaignDoc ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.CampaignDoc}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-4 mt-2">
                        <div className="my-1">
                          <label className="text-base">
                            I want to hire service:
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-1.5 dark:bg-[#1e1e1e] px-2 py-1">
                            {ServiceData?.map((service, index) => (
                              <div
                                key={index}
                                className="flex items-center my-1"
                              >
                                <input
                                  type="checkbox"
                                  name="ServiceId"
                                  value={service.Id}
                                  checked={formik.values.ServiceId.includes(
                                    service.Id
                                  )}
                                  id={service.Id}
                                  onChange={handleCheckboxChange2}
                                />
                                <label htmlFor={service.Id} className="ml-2">
                                  {service.Title}
                                </label>
                              </div>
                            ))}
                          </div>
                          {formik.touched.ServiceId &&
                          formik.errors.ServiceId ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.ServiceId}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-4 mt-2">
                        <div className="my-1">
                          <label className="text-base">
                            Campaign Description:
                            <span className="text-red-500">*</span>
                          </label>
                          <textarea
                            type="text"
                            name="CampaignDescription"
                            rows={2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.CampaignDescription}
                            placeholder="Please describe what creators will do for your campaign and their deliverables"
                            className="dark:bg-[#1e1e1e] px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          ></textarea>

                          {formik.touched.CampaignDescription &&
                          formik.errors.CampaignDescription ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.CampaignDescription}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <p className="text-center text-xl py-1.5 mb-3 bg-themecolor mt-3">
                      Influencer Details
                    </p>
                    <div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4  lg:grid-cols-3">
                        <div className="my-1">
                          <label className="text-base">
                            How many influencers are you looking:
                            <span className="text-red-500">*</span>
                          </label>
                          <select
                            name="InfluencerCount"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.InfluencerCount}
                            className="dark:bg-[#1e1e1e] px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          >
                            <option>Select Influencer</option>
                            <option value="1-5">1 - 5</option>
                            <option value="5-10">5 - 10</option>
                            <option value="10-25">10 - 25</option>
                            <option value="25+">25+</option>
                          </select>
                          {formik.touched.InfluencerCount &&
                          formik.errors.InfluencerCount ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.InfluencerCount}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label className="text-base">
                            How much you want to pay:
                            <span className="text-red-500">*</span>
                          </label>
                          <select
                            name="Price"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Price}
                            className="dark:bg-[#1e1e1e] px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          >
                            <option>Select Price</option>
                            <option value="I will offer Giveaway">
                              I will offer Giveaway
                            </option>{" "}
                            <option value="INR 1000 - INR 3000">
                              INR 1000 - INR 3000
                            </option>{" "}
                            <option value="INR 3000 - INR 7000">
                              INR 3000 - INR 7000
                            </option>{" "}
                            <option value="INR 7000 - INR 25000">
                              INR 7000 - INR 25000
                            </option>{" "}
                            <option value="INR 25000 - INR 50000">
                              INR 25000 - INR 50000
                            </option>{" "}
                            <option value="INR 50000 - INR 100000">
                              INR 50000 - INR 100000
                            </option>{" "}
                            <option value="INR 100000+">INR 100000+</option>{" "}
                            <option value="Open for Negotiations">
                              Open for Negotiations
                            </option>{" "}
                          </select>
                          {formik.touched.Price && formik.errors.Price ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Price}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label className="text-base">
                            When do you want to start:
                            <span className="text-red-500">*</span>
                          </label>
                          <select
                            name="StartTime"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.StartTime}
                            className="dark:bg-[#1e1e1e] px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          >
                            <option>Select Campaign Start</option>
                            <option value="Immediately">
                              Immediately
                            </option>{" "}
                            <option value="Within a week">Within a week</option>{" "}
                            <option value="Within 2 weeks">
                              Within 2 weeks
                            </option>{" "}
                            <option value="Within 1 month">
                              Within 1 month
                            </option>{" "}
                          </select>
                          {formik.touched.StartTime &&
                          formik.errors.StartTime ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.StartTime}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <p className="text-center text-xl py-1.5 mb-3 bg-themecolor mt-3">
                      Brand URLs
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4  lg:grid-cols-3">
                      <div className="my-3">
                        <label for="">Instagram URL</label>
                        <input
                          type="text"
                          name="Instagram"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Instagram}
                          placeholder="Your brand's Instagram URL"
                          className="dark:bg-[#1e1e1e] px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                        />
                        {formik.touched.Instagram && formik.errors.Instagram ? (
                          <div className="text-red-500 text-sm">
                            {formik.errors.Instagram}
                          </div>
                        ) : null}
                      </div>
                      <div className="my-3">
                        <label for="">YouTube URL</label>
                        <input
                          type="text"
                          name="YouTube"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.YouTube}
                          placeholder="Your brand's YouTube URL"
                          className="dark:bg-[#1e1e1e] px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                        />
                        {formik.touched.YouTube && formik.errors.YouTube ? (
                          <div className="text-red-500 text-sm">
                            {formik.errors.YouTube}
                          </div>
                        ) : null}
                      </div>
                      <div className="my-3">
                        <label for="">Facebook URL</label>
                        <input
                          type="text"
                          name="Facebook"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Facebook}
                          placeholder="Your brand's Facebook URL"
                          className="dark:bg-[#1e1e1e] px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                        />
                        {formik.touched.Facebook && formik.errors.Facebook ? (
                          <div className="text-red-500 text-sm">
                            {formik.errors.Facebook}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="flex justify-center my-3">
                      <button
                        type="submit"
                        className="bg-themecolor cursor-pointer text-lg text-white p-2 px-5 font-bold mx-auto rounded"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCampaign;
