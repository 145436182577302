import React from "react";
import NoData from "../Images/nodatafound.png";

const NoDataFound = () => {
  return (
    <div className="container m-auto px-5 md:px-0 py-5">
      <div className="flex flex-col items-center justify-center md:h-96">
        <img src={NoData} alt="" className="mx-auto my-auto " />
      </div>
    </div>
  );
};

export default NoDataFound;



