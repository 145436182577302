import React from "react";
import BreadCrumb from "../../../Breadcumb/Breadcumb";
import ServiceBannerImg from "../../../Images/services-banner1.jpg";
import titleimage from "../../../Images/title-icon.png";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useData } from "../../../Context/DataContext ";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../../usepageSEO/Index";

const Service = () => {
  const breadcrumbItems = [
    {
      text: "  Our Services",
    },
  ];
  const backgroundImage = ServiceBannerImg;

  const { ServiceData, loading } = useData();

  usePageSEO(
    "Viral kar | Service", // Use page title for SEO if available
    "Viral kar | Service", // Use page description for SEO if available
    ["Viral kar | Service"] // No keywords provided in this example
  );

  return (
    <div className=" dark:bg-darkmode dark:text-white">
      <BreadCrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
        <div className="container mx-auto pt-5 md:pt-10 pb-5 md:pb-10 px-3">       

          {loading ? (
            <div className="flex justify-center items-center my-40">
              <ClipLoader color={"#d00067"} loading={loading} size={40} />
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-5">
              {ServiceData?.map((val, index) => (
                <div
                  className="border border-zinc-200 rounded-md shadow-lg"
                  key={index}
                >
                  <div className="relative overflow-hidden md:h-60 h-72 rounded-t-md">
                    <img
                      src={val.BannerImg}
                      alt=""
                      className="h-full w-full object-cover object-top "
                    />
                  </div>
                  <div className="text-black p-3 rounded-b-md">
                    <Link to={`/service/${val.Id}`}>
                      <h1 className="text-themebgcolor3 dark:text-white text-xl font-medium duration-200">
                        {val.Title}
                      </h1>
                    </Link>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: val ? val.Content : "",
                      }}
                      className="text-zinc-500 dark:text-gray-300 text-xs line-clamp-3 leading-5 lg:text-base"
                    />

                    <div className="flex items-center justify-center mt-3 mb-2">
                      <Link
                        to={`/service/${val.Id}`}
                        type="button"
                        className="bg-themebgcolor3 flex items-center p-3 rounded-full px-8 mx-auto font-medium text-sm mt-2  text-white hover:bg-themecolor1 dark:bg-themecolor1 "
                      >      <FaLongArrowAltRight className="me-2" />
                        Read More{" "}
                  
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
    </div>
  );
};

export default Service;
