import React, { useEffect, useState } from "react";
import DashboardMenu from "../../DashboardMenu";
import ScrollToTop from "../ScrollToTop";
import ContactusImg from "../../../Images/contact-back.jpg";
import {
  getAdvertiserById,
  UpdateAdvertiserById,
} from "../../../API/AddAdvertiserApi";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../common/FormLoader";
import Config from "../../../API/Config";
import usePageSEO from "../../usepageSEO/Index";

const validationSchema = Yup.object().shape({
  Name: Yup.string().required("Name is required"),

  BusinessName: Yup.string().required("Business Name is required"),
  GSTNumber: Yup.string().required("GST Number is required"),
  BusinessWebsite: Yup.string().required("Business Website is required"),
  BusinessAddress: Yup.string().required("Business Address is required"),
  BusinessCity: Yup.string().required("Business City is required"),
  BusinessState: Yup.string().required("Business State is required"),
  BusinessCountry: Yup.string().required("Business Country is required"),
});

const AdvProfile = () => {
  const backgroundImage = ContactusImg;
  const breadcrumbStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    position: "relative",
    backgroundPosition: "center",
    zIndex: 1,
    padding: "140px 0 80px 0",
  };

  usePageSEO(
    "Viral kar | Dashboard", // Use page title for SEO if available
    "Viral kar | Dashboard", // Use page description for SEO if available
    ["Viral kar | Dashboard"] // No keywords provided in this example
  );

  const [ThumbImagePreview, setThumbImagePreview] = useState();
  const Id = Config.getId();
  const AdvStatus = Config.getAdvStatus();
  const FatchData = async () => {
    try {
      const result = await getAdvertiserById(Id);
      formik.setValues(result);
      if (result.ProfileImage) {
        setThumbImagePreview(result.ProfileImage);
      }
      sessionStorage.setItem("getAdvStatus", result.Status);
    } catch (error) {}
  };
  useEffect(() => {
    FatchData();
  }, [Id]);
  sessionStorage.setItem("ProfileImage", ThumbImagePreview);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Phone: "",
      BusinessName: "",
      GSTNumber: "",
      BusinessWebsite: "",
      BusinessAddress: "",
      BusinessCity: "",
      BusinessState: "",
      BusinessCountry: "",
      ProfileImage: "",
      HidImage: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const result = await UpdateAdvertiserById(Id, formData);

        FatchData();
      } catch (error) {
        console.error("Error updating slider:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });
  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <div className="breadcrumb-bg" style={breadcrumbStyle}>
          <div className="breadcrumb-before"></div>
          <ul className="text-white text-3xl text-center">Dashboard</ul>
        </div>
      </nav>
      <div className="pb-24">
        <ScrollToTop />
        <div className="w-full container mx-auto">
          <div className="w-full  lg:grid grid-cols-5 py-20 px-3 gap-3 ">
            <DashboardMenu />

            {/* ===============================Nav Pilss data ====================================*/}
            <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4">
              <div className="bg-dark-light">
                <form onSubmit={formik.handleSubmit}>
                  <input
                    type="hidden"
                    name="HidImage"
                    value={formik.values.HidImage}
                  />
                  <div className="grid grid-cols-1 gap-5">
                    <div className="bg-dark-light p-6">
                      {AdvStatus === 0 ? (
                        <div
                          class="bg-orange-100 border-t-4 mb-4 text-sm border-orange-500 rounded-b text-orange-900 px-4 py-2 shadow-md"
                          role="alert"
                        >
                          <div class="flex justify-center">
                            <p class="font-bold">
                              Your Profile is Under Review
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          class="bg-green-100 border-t-4 mb-4 text-sm border-green-500 rounded-b text-green-900 px-4 py-2 shadow-md"
                          role="alert"
                        >
                          <div class="flex justify-center">
                            <p class="font-bold">Your Profile is Approved</p>
                          </div>
                        </div>
                      )}
                      <p className="text-center text-xl py-1.5 mb-3 bg-themecolor">
                        Basic Details
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="my-1">
                          <label for="">Name</label>
                          <input
                            type="text"
                            name="Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Name}
                            placeholder="Name"
                            className="dark:bg-[#1e1e1e]  px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.Name && formik.errors.Name ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Name}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Email</label>
                          <input
                            type="email"
                            name="Email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Email}
                            disabled
                            placeholder="Email Address"
                            className="dark:bg-[#1e1e1e] px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.Email && formik.errors.Email ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Email}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Phone</label>
                          <input
                            type="text"
                            name="Phone"
                            disabled
                            readOnly
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Phone}
                            placeholder="Phone"
                            className="dark:bg-[#1e1e1e] px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.Phone && formik.errors.Phone ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.Phone}
                            </div>
                          ) : null}
                        </div>

                        <div className="my-1">
                          <label for="">Business Name</label>
                          <input
                            type="text"
                            name="BusinessName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.BusinessName}
                            placeholder="Business Name"
                            className="dark:bg-[#1e1e1e]  px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.BusinessName &&
                          formik.errors.BusinessName ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.BusinessName}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">GST Number</label>
                          <input
                            type="text"
                            name="GSTNumber"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.GSTNumber}
                            placeholder="GST Number"
                            className="dark:bg-[#1e1e1e]  px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.GSTNumber &&
                          formik.errors.GSTNumber ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.GSTNumber}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Business Website</label>
                          <input
                            type="text"
                            name="BusinessWebsite"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.BusinessWebsite}
                            placeholder="Business Website"
                            className="dark:bg-[#1e1e1e]  px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.BusinessWebsite &&
                          formik.errors.BusinessWebsite ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.BusinessWebsite}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Business City</label>
                          <input
                            type="text"
                            name="BusinessCity"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.BusinessCity}
                            placeholder="Business City"
                            className="dark:bg-[#1e1e1e]  px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.BusinessCity &&
                          formik.errors.BusinessCity ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.BusinessCity}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Business State</label>
                          <input
                            type="text"
                            name="BusinessState"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.BusinessState}
                            placeholder="Business State"
                            className="dark:bg-[#1e1e1e]  px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.BusinessState &&
                          formik.errors.BusinessState ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.BusinessState}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Business Country</label>
                          <input
                            type="text"
                            name="BusinessCountry"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.BusinessCountry}
                            placeholder="Business Country"
                            className="dark:bg-[#1e1e1e]  px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.BusinessCountry &&
                          formik.errors.BusinessCountry ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.BusinessCountry}
                            </div>
                          ) : null}
                        </div>
                        <div className="my-1">
                          <label for="">Profile Picture</label>
                          <input
                            type="file"
                            name="ProfileImage"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "ProfileImage",
                                event.currentTarget.files[0]
                              );
                            }}
                            onBlur={formik.handleBlur}
                            placeholder="Business Country"
                            className="dark:bg-[#1e1e1e]  p-4 py-2 w-full focus:outline-none my-1.5 border border-zinc-600"
                          />
                          {formik.touched.ProfileImage &&
                          formik.errors.ProfileImage ? (
                            <div className="text-red-500 text-sm">
                              {formik.errors.ProfileImage}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="my-3">
                        <label for="">Business Address</label>
                        <textarea
                          rows="3"
                          cols=""
                          name="BusinessAddress"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.BusinessAddress}
                          placeholder="Business Address"
                          className="dark:bg-[#1e1e1e]  px-4 py-2.5 w-full focus:outline-none my-1.5 border border-zinc-600"
                        ></textarea>
                        {formik.touched.BusinessAddress &&
                        formik.errors.BusinessAddress ? (
                          <div className="text-red-500 text-sm">
                            {formik.errors.BusinessAddress}
                          </div>
                        ) : null}
                      </div>

                      <div className="flex justify-center my-3">
                        <button
                          type="submit"
                          className="bg-themecolor cursor-pointer text-lg text-white p-2 px-5 font-bold mx-auto rounded"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvProfile;
