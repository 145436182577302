import React, { useEffect, useState } from "react";
import HomeBackImg from "../../Images/home-page-back.png";
import titleimage from "../../Images/title-icon.png";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { getInfluencerByCategory } from "../../API/ModelInfMain";
import NoDataFound from "../../common/NodataFound";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../usepageSEO/Index";

const Category = () => {
  const { Slug } = useParams();

  const location = useLocation();
  const id = location.state?.id; // Get Id from state

  const [InfluencerData, setInfluencerData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const getData = async () => {
    try {
      const results = await getInfluencerByCategory(id);
      setInfluencerData(results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [id]);

  usePageSEO(
    "Viral kar | Category", // Use page title for SEO if available
    "Viral kar | Category", // Use page description for SEO if available
    ["Viral kar | Category"] // No keywords provided in this example
  );

  return (
    <div className="dark:bg-darkmode dark:text-white">
      {/*-------------- male - Influencers -----------------*/}

      <div className="container mx-auto  py-10">
        <p className="page-title text-center dark:text-white capitalize pb-3">{Slug} Influencers</p>
        <img src={titleimage} alt="" className="mx-auto title-icon" />
      </div>
      <div className="">
        <div
          className="subscribe-bg lg:mb-0 relative bg-cover bg-center bg-no-repeat py-20 px-5 md:px-9 xl:px-0 "
          style={{ backgroundImage: `url(${HomeBackImg})` }}
        >
          {loading ? (
            <div className="flex justify-center items-center my-40">
              <ClipLoader color={"#d00067"} loading={loading} size={40} />
            </div>
          ) : (
            <div className="container mx-auto relative">
              {InfluencerData?.length === 0 ? (
                <NoDataFound />
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 container mx-auto ">
                  {InfluencerData?.map((val, index) => {
                    return (
                      <div key={index} className="p-5">
                        <Link
                          to={`/influencer/${val.CityName}/${val.UserName}`}
                          className="relative models-box cursor-pointer "
                        >
                          <img
                            src={val.ProfileImage}
                            alt=""
                            className="rounded-lg h-[500px] w-full object-cover"
                          />
                          <h2 className="absolute bottom-0 left-0 right-0 bg-themecolor1 p-3 text-center text-white text-2xl font-bold rounded-tl-none rounded-tr-none rounded-lg">
                            {val.Name}
                          </h2>

                          <div className="overlay absolute bottom-0 text-white w-full transition-opacity ease-linear duration-500 opacity-0 text-xl p-0 text-center">
                            <div className="bg-black px-4 py-1 text-left text-xl lg:text-2xl">
                              <p>{val.Name}</p>
                            </div>
                            <div className="flex items-center justify-between bg-black">
                              <div className="box-border flex items-center justify-between w-full bg-white text-black">
                                <div className="mx-auto leading-9">
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Height
                                  </h4>
                                  <p>{val.Height}</p>
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Bust
                                  </h4>
                                  <p className="m-0">{val.Bust}</p>
                                </div>
                                <div className="mx-auto leading-9">
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Weight
                                  </h4>
                                  <p>{val.Weight}</p>
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    waist
                                  </h4>
                                  <p className="m-0">{val.Waist}</p>
                                </div>
                                <div className="mx-auto leading-9">
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Age
                                  </h4>
                                  <p>{val.Age}</p>
                                  <h4 className="text-themecolor font-bold uppercase surface:font-semibold">
                                    Hips
                                  </h4>
                                  <p className="m-0">{val.Hips}</p>
                                </div>
                              </div>
                              <div className="models-social-box">
                                <div>
                                  <Link
                                    to={val.Facebook || "https://facebook.com/"}
                                    target="_blank"
                                  >
                                    <FaFacebookF className="text-2xl m-3 my-4 pointer-events-none" />
                                  </Link>
                                  <Link
                                    to={val.Twitter || "https://x.com/"}
                                    target="_blank"
                                  >
                                    <FaXTwitter className="text-2xl m-3 my-4 pointer-events-none" />
                                  </Link>
                                  <Link
                                    to={
                                      val.Instagram || "https://instagram.com/"
                                    }
                                    target="_blank"
                                  >
                                    <FaInstagram className="text-2xl m-3 my-4 pointer-events-none" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Category;
