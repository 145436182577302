import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../Breadcumb/Breadcumb";
import ModelsBanner from "../../../Images/kids-models-banner.jpg";
import titleimage from "../../../Images/title-icon.png";
import HomeBackImg from "../../../Images/home-page-back.png";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import NoDataFound from "../../../common/NodataFound";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../../usepageSEO/Index";
import { getAllModel } from "../../../API/NewInfluencerAPI";

const KidsModels = () => {
  const breadcrumbItems = [
    {
      text: "Kids Model",
    },
  ];
  const backgroundImage = ModelsBanner;

  usePageSEO(
    "Viral kar | Kids Model", // Use page title for SEO if available
    "Viral kar | Kids Model", // Use page description for SEO if available
    ["Viral kar | Kids Model"] // No keywords provided in this example
  );

  const [InfluencerData, setInfluencerData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const getData = async () => {
    try {
      const results = await getAllModel();
      const filteredResults = results.filter(
        (influencer) => influencer.Gender === "K"
      );
      setInfluencerData(filteredResults);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="dark:bg-darkmode dark:text-white">
      <BreadCrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
      <div className="container mx-auto py-10">
        <h1 className=" page-title text-3xl text-center pb-1 dark:text-white pb-4">
          Kids Models
        </h1>
        <img src={titleimage} alt="" className="mx-auto title-icon" />
      </div>

      <div className="lg:pb-20 pb-10">
        <div
          className="subscribe-bg relative bg-cover bg-right bg-no-repeat bg-gray-50"
          style={{ backgroundImage: `url(${HomeBackImg})` }}
        >
          {loading ? (
            <div className="flex justify-center items-center my-40 dark:bg-darkmode">
              <ClipLoader color={"#f59231"} loading={loading} size={40} />
            </div>
          ) : (
            <div className="container mx-auto lg:py-12 py-10">
              {InfluencerData?.length === 0 ? (
                <NoDataFound />
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 container mx-auto ">
                  {InfluencerData?.map((val, index) => {
                    return (
                      <div key={index}>
                      <Link
                        to={`/model/${val.Id}`}
                        className="relative models-box cursor-pointer "
                      >
                        <div class="item pb-[90px] group relative grid grid-cols-1 ">
                          <div class="relative overflow-hidden surface:h-[20rem] xl:h-[25rem] h-60">
                            <img
                              class="block w-full transform-style-[preserve-3d] h-full object-cover object-top rounded-t-lg"
                              src={val.Path + val.ProfileImage}
                              alt="#"
                            />
                          </div>
                          <div class="border-l-3 border-[#95c6d8] shadow-md rounded-b-lg bottom-0 left-0 overflow-hidden perspective-[500px] absolute right-0 px-[20px]   pt-[20px] transition-all duration-500 ease-in-out bg-white dark:bg-darkmode dark:text-white z-20 group-hover:bg-[#f5f5f5] dark:group-hover:bg-darkmode group-hover:border-l-transparent group-hover:pt-[37px] group-hover:-translate-y-4 ">
                            <h3 class=" text-xl text-left font-semibold leading-[1] tracking-normal mb-[18px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                              <Link to={`/model/${val.Id}`}>{val.Name}</Link>
                            </h3>
                            <h3 class="text-md text-left leading-[1]   tracking-normal my-[18px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                              {val.StageName}
                            </h3>

                            <div class="hidden group-hover:block transition-all duration-500 ease-in-out transform group-hover:-translate-y-4 group-hover:opacity-100 border-l-4 border-themecolor">
                              <div class="flex flex-col space-y-[10px] opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out transform group-hover:translate-y-0 translate-y-[50px]">
                                <div className="grid grid-cols-2 lg:grid-cols-2">
                                  <div class="inline-block text-left leading-[1.4] mx-[16px]">
                                    <span class="font-semibold block overflow-hidden mb-[3px] text-xs lg:text-base">
                                      Height
                                    </span>
                                    <span className="text-sm">{val.Height}</span> 
                                  </div>
                                  <div class="inline-block text-left leading-[1.4] mx-[16px]">
                                    <span class="font-semibold block overflow-hidden mb-[3px] text-xs lg:text-base">
                                      Weight
                                    </span>
                                    <span className="text-sm">{val.Weight}</span>
                                  </div>
                                  <div class="inline-block text-left leading-[1.4] mx-[16px]">
                                    <span class="font-semibold block overflow-hidden mb-[3px] text-xs lg:text-base">
                                      Waist
                                    </span>
                                    <span className="text-sm"> {val.Waist}</span>
                                  </div>
                                  <div class="inline-block text-left leading-[1.4] mx-[16px]">
                                    <span class="font-semibold block overflow-hidden mb-[3px] text-xs lg:text-base">
                                      Hips
                                    </span>
                                    <span className="text-sm">{val.Hips}</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="absolute bottom-0 right-0 top-0 w-[40px] bg-themecolor opacity-0 transition-all duration-500 ease-in-out transform translate-y-[50px] group-hover:translate-y-0 group-hover:opacity-100 flex flex-col justify-center space-y-4 text-white text-xl text-center">
                              <div class="social-icon ">
                                <Link
                                  to={val.Facebook || "https://facebook.com/"}
                                  target="_blank"
                                  className="text-center pointer-events-none"
                                >
                                  <span>
                                    <FaFacebookF className="mx-auto" />
                                  </span>
                                </Link>
                              </div>
                              <div class="social-icon">
                                <Link
                                  to={val.Twitter || "https://x.com/"}
                                  target="_blank"
                                  className="text-center pointer-events-none"
                                >
                                  <span>
                                    <FaXTwitter className="mx-auto" />
                                  </span>
                                </Link>
                              </div>
                              <div class="social-icon">
                                <Link
                                  to={val.Youtube || "https://youtube.com/"}
                                  target="_blank"
                                  className="text-center pointer-events-none"
                                >
                                  <span>
                                    <FaYoutube className="mx-auto" />
                                  </span>
                                </Link>
                              </div>
                              <div class="social-icon">
                                <Link
                                  to={
                                    val.Instagram || "https://instagram.com/"
                                  }
                                  target="_blank"
                                  className="text-center pointer-events-none"
                                >
                                  <span>
                                    <FaInstagram className="mx-auto" />
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* ----------------Loadmore ---------------*/}
    </div>
  );
};

export default KidsModels;
