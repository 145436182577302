/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DashboardMenu from "../../DashboardMenu";
import ContactusImg from "../../../Images/contact-back.jpg";
import Config from "../../../API/Config";
import { CampaignbyId, CampaignGetbyId } from "../../../API/CampaignApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  FaFacebook,
  FaGlobe,
  FaInstagram,
  FaRupeeSign,
  FaYoutube,
} from "react-icons/fa";
import * as Yup from "yup";
import { FaArrowLeft, FaLocationDot, FaXTwitter } from "react-icons/fa6";
import FormLoader from "../../../common/FormLoader";
import ClipLoader from "react-spinners/FadeLoader";
import ScrollToTop from "../ScrollToTop";
import { AddVendorInquiry, campaignApply } from "../../../API/ModelInfMain";
import { useFormik } from "formik";
import { format, parseISO } from "date-fns";
import usePageSEO from "../../usepageSEO/Index";

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Name cannot contain numbers")
    .required("Name is required"),

  Email: Yup.string().email("Invalid email").required("Email is required"),

  Phone: Yup.string()
    .matches(/^[0-9]+$/, "Invalid mobile number")
    .required("Number is required"),
  Message: Yup.string().required("Message is required"),
});

const ApplyCampaign = () => {
  const backgroundImage = ContactusImg;
  const breadcrumbStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    position: "relative",
    backgroundPosition: "center",
    zIndex: 1,
    padding: "140px 0 80px 0",
  };

  usePageSEO(
    "Viral kar | Apply Campaign", // Use page title for SEO if available
    "Viral kar | Apply Campaign", // Use page description for SEO if available
    ["Viral kar | Apply Campaign"] // No keywords provided in this example
  );

  const [CampaingnData, setCampaingnData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const { Id } = useParams();
  const VendorId = Config.getId();
  const FatchData = async () => {
    try {
      const result = await CampaignbyId(Id);
      setCampaingnData(result.responseData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    FatchData();
  }, [Id]);

  const [isFormLoading, setIsFormLoading] = useState(false);
  // -------------------delete venue------------------
  const navigate = useNavigate();

  const backClick = () => {
    navigate("/campaign");
  };

  const formik = useFormik({
    initialValues: {
      VendorId: VendorId,
      CampaignId: Id,
      Name: "",
      Email: "",
      Phone: "",
      Message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await campaignApply(values);
        if (response.status === true) {
          actions.resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  return (
    <div className="bg-dark">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <div className="breadcrumb-bg" style={breadcrumbStyle}>
          <div className="breadcrumb-before"></div>
          <ul className="text-white text-3xl text-center">Apply Campaign</ul>
        </div>
      </nav>
      <div className="pb-24">
        <ScrollToTop />
        <div className="w-full container mx-auto">
          <div className="w-full  lg:grid grid-cols-5 py-20 px-3 gap-3 ">
            {/* ===============================Nav Pilss data ====================================*/}
            <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-5">
              <div className="bg-dark-light">
                <div className="grid grid-cols-1 gap-5">
                  <div className="bg-dark-light p-6">
                    <div className=" text-xl py-1.5 mb-3 bg-themecolor flex items-center">
                      <span
                        onClick={backClick}
                        className="bg-white text-black p-2 ml-1.5 cursor-pointer"
                      >
                        <FaArrowLeft />
                      </span>

                      <p className="text-center mx-auto text-base md:text-xl">
                        Apply Campaign
                      </p>
                    </div>
                    {loading ? (
                      <div className="flex justify-center items-center my-40">
                        <ClipLoader
                          color={"#d00067"}
                          loading={loading}
                          size={40}
                        />
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="grid grid-cols-1 lg:grid-cols-1 gap-5">
                          {CampaingnData.map((val, index) => {
                            return (
                              <div key={index} className="mb-5  surface:px-2">
                                <div className="bg-white p-5 shadow-lg rounded">
                                  <div>
                                    <div className="flex items-center">
                                      <p className="text-2xl text-themecolor my-2">
                                        {val.CampaignName}
                                      </p>
                                      <p className="ml-auto">
                                        <Link
                                          className="bg-themecolor2 p-2"
                                          target="_blank"
                                          to={val.CampaignDoc}
                                        >
                                          View
                                        </Link>
                                      </p>
                                    </div>
                                    <div className="text-textcolor my-2">
                                      <p className="flex items-center my-2 border line-break-anywhere border-themecolor p-2">
                                        <FaLocationDot className="text-themecolor" />
                                        <span className="px-1.5">
                                          {val.CityNames}
                                        </span>
                                      </p>
                                      <p className="flex items-center my-2 line-break-anywhere border border-themecolor p-2">
                                        <span className="mr-1.5">
                                          <FaGlobe className="text-themecolor" />
                                        </span>
                                        <Link to={val.Website} target="_blank">
                                          {val.Website}
                                        </Link>
                                      </p>

                                      <p className="border border-themecolor p-2 my-2 text-justify">
                                        <span className="text-themecolor">
                                          Description :{" "}
                                        </span>
                                        {val.CampaignDescription}
                                      </p>
                                      <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
                                        <p className="border border-themecolor p-2">
                                          <span className="text-themecolor">
                                            Category :{" "}
                                          </span>
                                          {val.CategoryNames}
                                        </p>

                                        <p className="border border-themecolor p-2">
                                          <span className="text-themecolor">
                                            Vendor Type :{" "}
                                          </span>
                                          {val.VendorType === "M"
                                            ? "Model"
                                            : "Influencer"}
                                        </p>
                                        <p className="border border-themecolor p-2">
                                          <span className="text-themecolor">
                                            Promotion Type :{" "}
                                          </span>
                                          {val.Type === "S"
                                            ? "Service"
                                            : "Product"}
                                        </p>
                                        <p className="border border-themecolor p-2">
                                          {" "}
                                          <span className="text-themecolor">
                                            Service :{" "}
                                          </span>
                                          {val.ServiceNames}
                                        </p>
                                        <p className="border border-themecolor p-2">
                                          {" "}
                                          <span className="text-themecolor">
                                            Influencer Required :{" "}
                                          </span>
                                          {val.InfluencerCount}
                                        </p>
                                        <p className="border border-themecolor p-2">
                                          {" "}
                                          <span className="text-themecolor">
                                            Start Time :{" "}
                                          </span>
                                          {val.StartTime}
                                        </p>
                                        <p className="border border-themecolor p-2">
                                          {" "}
                                          <span className="text-themecolor">
                                            Price :{" "}
                                          </span>
                                          {val.Price}
                                        </p>
                                        <p className="border border-themecolor p-2">
                                          {" "}
                                          <span className="text-themecolor">
                                            Campaign Add Date :{" "}
                                          </span>
                                          {format(
                                            parseISO(val.EntDt),
                                            "dd-MM-yyyy"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-white flex items-center justify-center mb-0 mt-5">
                                    <Link
                                      to={
                                        val.Facebook || "https://facebook.com/"
                                      }
                                      target="_blank"
                                      className="bg-themecolor mx-3 p-1.5 rounded cursor-pointer"
                                    >
                                      <FaFacebook className="text-3xl" />
                                    </Link>
                                    <Link
                                      to={
                                        val.Instagram ||
                                        "https://instagram.com/"
                                      }
                                      target="_blank"
                                      className="bg-themecolor mx-3 p-1.5 rounded cursor-pointer"
                                    >
                                      <FaInstagram className="text-3xl" />
                                    </Link>
                                    <Link
                                      to={val.YouTube}
                                      target="_blank"
                                      className="bg-themecolor mx-3 p-1.5 rounded cursor-pointer"
                                    >
                                      <FaYoutube className="text-3xl" />
                                    </Link>
                                  </div>

                                  <div className="bg-dark-light p-6 mt-10">
                                    <form onSubmit={formik.handleSubmit}>
                                      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
                                        <div>
                                          <label for="">
                                            Name{" "}
                                            <span className="text-red-500">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            name="Name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Name}
                                            className="p-3 py-2 mt-2 w-full  bg-neutral-500 rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black placeholder:text-sm"
                                            placeholder="Enter Your Name"
                                          />
                                          {formik.touched.Name &&
                                          formik.errors.Name ? (
                                            <div className="text-red-500 text-sm">
                                              {formik.errors.Name}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div>
                                          <label for="">
                                            Email{" "}
                                            <span className="text-red-500">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="email"
                                            name="Email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Email}
                                            className="p-3 py-2 mt-2 w-full bg-neutral-500 rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black placeholder:text-sm"
                                            placeholder="Enter Your Email"
                                          />
                                          {formik.touched.Email &&
                                          formik.errors.Email ? (
                                            <div className="text-red-500 text-sm">
                                              {formik.errors.Email}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="mt-5">
                                        <label for="">
                                          Phone{" "}
                                          <span className="text-red-500">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          name="Phone"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.Phone}
                                          className="p-3 py-2 mt-2 w-full bg-neutral-500 rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black placeholder:text-sm"
                                          placeholder="Enter Your Mobile"
                                        />
                                        {formik.touched.Phone &&
                                        formik.errors.Phone ? (
                                          <div className="text-red-500 text-sm">
                                            {formik.errors.Phone}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="mt-5">
                                        <label for="">
                                          Message{" "}
                                          <span className="text-red-500">
                                            *
                                          </span>
                                        </label>
                                        <textarea
                                          name="Message"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.Message}
                                          rows="4"
                                          className="w-full p-3 py-2 mt-2 bg-neutral-500 rounded-md focus:outline-none focus:ring focus:border-blue-500 placeholder:text-black placeholder:text-sm"
                                          placeholder="Message"
                                        ></textarea>
                                        {formik.touched.Message &&
                                        formik.errors.Message ? (
                                          <div className="text-red-500 text-sm">
                                            {formik.errors.Message}
                                          </div>
                                        ) : null}
                                      </div>

                                      <button
                                        type="submit"
                                        className="bg-themecolor p-2 rounded-md px-6 font-medium text-lg mt-4 border border-themecolor hover:bg-black"
                                      >
                                        Apply
                                      </button>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyCampaign;
