import React, { useEffect, useState } from "react";
import titleimage from "../../../../Images/title-icon.png";
import { FaAddressCard, FaCalendar, FaUser } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { Link, useNavigate } from "react-router-dom";
import Config from "../../../../API/Config";
import usePageSEO from "../../../usepageSEO/Index";
import { getAllCollbration } from "../../../../API/CollabrationAPi";
import { ArtistGetById, ArtistStep3Add } from "../../../../API/ArtistSignUpAPI";

// Updated validation schema without requiring OtherCollaborationsType
const validationSchema = Yup.object().shape({
  CollaborationsType: Yup.array().min(1, "Please select at least one collaboration type"),
  AvailableforTravel: Yup.string().required("Please enter your availability for travel"),
  TermsandConditions: Yup.boolean().oneOf([true], "You must accept the Terms and Conditions"),
  PrivacyPolicy: Yup.boolean().oneOf([true], "You must accept the Privacy Policy"),
});

const RegisterArtist3 = () => {
  usePageSEO(
    "Free Artist Register - ViralKar",
    "Need more exposure for your art? Join our free artist register to connect with clients, showcase your work, and grow your creative career today.",
    ["Free Artist Register", "Artist Register", "Register as Artist", "Register as Artist for free"]
  );

  const ArtistId = Config.getArtistIdForTemp();
  const [CollaborationData, setCollaborationData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [CollaborationResult] = await Promise.all([getAllCollbration()]);
        setCollaborationData(CollaborationResult);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const getData = async () => {
    try {
      const response = await ArtistGetById(ArtistId);

      sessionStorage.setItem("ArtistStep1ForTemp", JSON.stringify(response.Step1));
      sessionStorage.setItem("ArtistStep2ForTemp", JSON.stringify(response.Step2));
      sessionStorage.setItem("ArtistStep3ForTemp", JSON.stringify(response.Step3));

      const numericCollabs = [];
      let otherCollabType = "";

      response.CollaborationsType.forEach((collab) => {
        if (/^\d+$/.test(collab)) {
          numericCollabs.push(collab);
        } else {
          otherCollabType = collab;
        }
      });

      formik.setValues({
        ArtistId: response?.Id || ArtistId,
        CollaborationsType: response?.CollaborationsType || [],
        AvailableforTravel: response?.AvailableforTravel || "",
        OtherCollaborationsType: otherCollabType, // keep this in the state but not required
        CollaborationIndustries: response?.CollaborationIndustries || "",
        TermsandConditions: response?.TermsandConditions || false,
        PrivacyPolicy: response?.PrivacyPolicy || false,
      });

      if (otherCollabType) {
        formik.setFieldValue("CollaborationsType", [
          ...response.CollaborationsType,
          "Other",
        ]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, [ArtistId]);

  const handleLanguageCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const { CollaborationsType } = formik.values;

    if (checked) {
      if (!CollaborationsType.includes(value)) {
        formik.setFieldValue("CollaborationsType", [
          ...CollaborationsType,
          value,
        ]);
      }
    } else {
      const newCollaborationsType = CollaborationsType.filter((id) => id !== value);
      if (value === "Other") {
        formik.setFieldValue("OtherCollaborationsType", ""); // reset if 'Other' is unchecked
      }
      formik.setFieldValue("CollaborationsType", newCollaborationsType);
    }
  };

  const [isFormLoading, setIsFormLoading] = useState(false);
  const navigation = useNavigate();
  const formik = useFormik({
    initialValues: {
      ArtistId: ArtistId,
      CollaborationsType: [],
      AvailableforTravel: "",
      OtherCollaborationsType: "", // Keep this in the initial values
      CollaborationIndustries: "",
      TermsandConditions: false,
      PrivacyPolicy: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsFormLoading(true);
      try {
        const finalValues = {
          ...values,
        };
        const result = await ArtistStep3Add(finalValues);
        if (result.status === true) {
          navigation("/profile/artist");
        }
        getData();
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  return (
    <div>
      <div className="bg-dark">
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-14 px-5 container mx-auto">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center pb-2 dark:text-white capitalize">
              Unleash your creativity - register or log in to let your art shine
            </p>
            <img src={titleimage} className="text-center mt-5 title-icon" alt="" />
          </div>
          <div className="relative flex items-center justify-between w-full my-10">
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300"></div>
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-themecolor dark:bg-white transition-all duration-500"></div>
            <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor rounded-full place-items-center">
              <FaUser className="w-5 h-5" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th bg-themecolor transition-all duration-300 rounded-full place-items-center">
              <FaCalendar className="w-5 h-5 text-white" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-themecolor rounded-full place-items-center">
              <FaAddressCard className="w-5 h-5 text-white" />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit} className="my-10 dark:border border-white">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="col-span-3 bg-white dark:bg-slate-800 p-5 md:p-8 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border">
                <p className="text-center text-2xl pb-3 mb-3">Collaboration Preferences</p>
                <div className="rounded-lg shadow-sm duration-500 border px-4 py-4 mb-6">
                  <div className="mb-3">
                    <p>
                      Type of Collaborations Interested In <span className="text-red-500">*</span>
                    </p>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-4">
                    {CollaborationData[2]?.ChildCat?.map((val) => (
                      <div className="flex items-center my-1" key={val.Id}>
                        <input
                          type="checkbox"
                          className="mr-2"
                          name="CollaborationsType"
                          value={val.Id}
                          checked={formik.values.CollaborationsType.includes(val.Id.toString())}
                          id={`collab-${val.Id}`}
                          onChange={handleLanguageCheckboxChange}
                        />
                        <label className="text-sm text-gray-500 font-normal" htmlFor={`collab-${val.Id}`}>
                          {val.Title}
                        </label>
                      </div>
                    ))}
                    <div className="flex items-center my-1">
                      <input
                        type="checkbox"
                        className="mr-2"
                        name="CollaborationsType"
                        value="Other"
                        id="collab-other"
                        checked={formik.values.CollaborationsType.includes("Other")}
                        onChange={handleLanguageCheckboxChange}
                      />
                      <label className="text-sm text-gray-500 font-normal" htmlFor="collab-other">
                        Other
                      </label>
                    </div>
                  </div>
                  {formik.touched.CollaborationsType && formik.errors.CollaborationsType && (
                    <div className="text-red-500 text-sm">{formik.errors.CollaborationsType}</div>
                  )}
                  {formik.values.CollaborationsType.includes("Other") && (
                    <div className="my-2">
                      <input
                        type="text"
                        placeholder="Please specify"
                        className={`w-full border rounded-lg p-2 ${formik.touched.OtherCollaborationsType ? 'border-gray-300' : 'border-gray-300'}`}
                        onChange={formik.handleChange}
                        value={formik.values.OtherCollaborationsType}
                        name="OtherCollaborationsType"
                      />
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div className="my-1">
                    <label className="text-base">
                      Available for Travel{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center mt-3 px-2 py-2">
                      <div className="mr-3">
                        <input
                          type="radio"
                          id="Yes"
                          name="AvailableforTravel"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="Yes"
                          className="mr-2"
                          checked={formik.values.AvailableforTravel === "Yes"}
                        />
                        <label htmlFor="Yes">Yes</label>
                      </div>
                      <div className="">
                        <input
                          type="radio"
                          id="No"
                          name="AvailableforTravel"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="No"
                          className="mr-3"
                          checked={formik.values.AvailableforTravel === "No"}
                        />
                        <label htmlFor="No">No</label>
                      </div>
                    </div>
                    {formik.touched.AvailableforTravel && formik.errors.AvailableforTravel ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.AvailableforTravel}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label htmlFor="">
                      Preferred Brands/Industries
                    </label>
                    <input
                      type="text"
                      name="CollaborationIndustries"
                      value={formik.values.CollaborationIndustries}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Preferred Brands/Industries"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                  </div>
                </div>
                <div className="grid my-2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="TermsandConditions"
                      id="terms"
                      className="mr-2"
                      onChange={formik.handleChange}
                      checked={formik.values.TermsandConditions}
                    />
                    <label htmlFor="terms" className="text-gray-500 text-sm">
                      I agree to the <Link to="/terms" className="text-themecolor">Terms and Conditions</Link>
                    </label>
                  </div>
                  <div className="grid pt-1">
                    {formik.touched.TermsandConditions && formik.errors.TermsandConditions && (
                      <div className="text-red-500 text-sm">{formik.errors.TermsandConditions}</div>
                    )}
                  </div>
                </div>
                <div className="grid my-2">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="PrivacyPolicy"
                      id="privacy"
                      className="mr-2"
                      onChange={formik.handleChange}
                      checked={formik.values.PrivacyPolicy}
                    />
                    <label htmlFor="privacy" className="text-gray-500 text-sm">
                      I agree to the <Link to="/privacy" className="text-themecolor">Privacy Policy</Link>
                    </label>
                  </div>
                  <div className="grid pt-1">
                    {formik.touched.PrivacyPolicy && formik.errors.PrivacyPolicy && (
                      <div className="text-red-500 text-sm">{formik.errors.PrivacyPolicy}</div>
                    )}
                  </div>
                </div>
                <div className="flex justify-around my-3 mt-5">
                  <Link
                    to={"/artist/sign-up/artist2"}
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10"
                  >
                    Previous
                  </Link>
                  <button
                    type="submit"
                    className="bg-green-600 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 ms-auto"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterArtist3;