/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import NoDataFound from "../../common/NodataFound";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../usepageSEO/Index";
import { getInfluencerBySubCategoryId } from "../../API/NewInfluencerAPI";
import Breadcrumb from "../../Breadcumb/Breadcumb";

const CatSubCategory = () => {
  const { Slug } = useParams();
  const breadcrumbItems = [
    {
      text: `${Slug} Influencer`
    }
  ];

  const location = useLocation();
  const state = location.state || {}; // Default to empty object if no state

  const CategoryId = state.CategoryId || "No ID provided";
  console.log("Passed CategoryId:", CategoryId);

  const [InfluencerData, setInfluencerData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const getData = async () => {
    try {
      const results = await getInfluencerBySubCategoryId(CategoryId);
      setInfluencerData(results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [CategoryId]);

  usePageSEO(
    "Viral kar | Category", // Use page title for SEO if available
    "Viral kar | Category", // Use page description for SEO if available
    ["Viral kar | Category"] // No keywords provided in this example
  );

  return (
    <div className="dark:bg-darkmode dark:text-white">
      {/*-------------- male - Influencers -----------------*/}
      <Breadcrumb items={breadcrumbItems} />
      <div className="">
        <div
          className="subscribe-bg lg:mb-0 relative bg-cover bg-center bg-no-repeat md:py-10 py-5 px-5 md:px-9 xl:px-0" >
          {loading ? (
            <div className="flex justify-center items-center my-40">
              <ClipLoader color={"#f59231"} loading={loading} size={40} />
            </div>
          ) : (
            <div className="container mx-auto relative">
              {InfluencerData?.length === 0 ? (
                <NoDataFound />
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 container mx-auto ">
                  {InfluencerData?.map((val, index) => {
                    return (
                      <Link to={`/influencer/${val.CityName}/${val.UserName}`}>
                        <div class="item pb-[146px] group relative grid grid-cols-1 ">
                          <div class="relative overflow-hidden surface:h-[20rem] xl:h-[25rem] h-60">
                            <img
                              class="block w-full transform-style-[preserve-3d] h-full object-cover object-top rounded-t-lg"
                              src={val.Path + val.ProfileImage}
                              alt="#"
                            />
                          </div>
                          <div class="  border-l-3 border-[#95c6d8] shadow-md rounded-b-lg bottom-0 left-0 overflow-hidden perspective-[500px] min-h-[146px] absolute right-0 px-[20px]   pt-[20px] transition-all duration-500 ease-in-out bg-white dark:bg-darkmode dark:text-white z-10 group-hover:bg-[#f5f5f5] dark:group-hover:bg-darkmode group-hover:border-l-transparent group-hover:pt-[37px] group-hover:-translate-y-4 ">
                            <h3 class=" text-xl text-left font-semibold leading-[1] tracking-normal mb-[18px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                              {val.Name}
                            </h3>
                            <h3 class="text-md text-left leading-[1]   tracking-normal my-[18px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                              {val.Name}
                            </h3>
                            <div class="absolute bottom-0 right-0 top-0 w-[40px] opacity-0 transition-all duration-500 ease-in-out transform translate-y-[50px] group-hover:translate-y-0 group-hover:opacity-100 flex flex-col justify-center space-y-4 text-white text-xl text-center">
                              {(val.Instragram || val.Facebook || val.Twitter || val.Youtube || val.LinkedIn) && (
                                <div className="absolute bottom-0 right-0 top-0 w-[40px] bg-themecolor1 opacity-0 transition-all duration-500 ease-in-out transform translate-y-[50px] group-hover:translate-y-0 group-hover:opacity-100 flex flex-col justify-center space-y-4 text-white text-xl text-center">
                                  {val.Instragram && (
                                    <Link to={"https://www.instagram.com/viralkar.official/"} target="_blank" className=" text-white text-lg mx-1 drop-shadow-lg">
                                      <FaInstagram className="mx-auto" />
                                    </Link>
                                  )}
                                  {val.Facebook && (
                                    <Link to={"https://www.facebook.com/viralkarr/"} target="_blank" className="text-white text-lg mx-1 drop-shadow-lg">
                                      <FaFacebook className="text-lg mx-auto" />
                                    </Link>
                                  )}
                                  {val.Twitter && (
                                    <Link to={"https://x.com/viral_kar_"} target="_blank" className="text-white text-lg mx-1 drop-shadow-lg">
                                      <FaXTwitter className="text-lg mx-auto" />
                                    </Link>
                                  )}
                                  {val.YouTube && (
                                    <Link to={"https://www.youtube.com/@ViralKarr"} target="_blank" className="text-white p-2 text-lg mx-1 drop-shadow-lg">
                                      <FaYoutube className="text-lg mx-auto" />
                                    </Link>
                                  )}
                                  {val.LinkedIn && (
                                    <Link to={"https://www.linkedin.com/company/viralkar"} target="_blank" className="text-white p-2 text-lg mx-1 drop-shadow-lg">
                                      <FaLinkedin className="text-lg mx-auto" />
                                    </Link>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CatSubCategory;
