import React, { useEffect, useState } from "react";
import ModelsBanner from "../../Images/about-banner.jpg";
import HomeBackImg from "../../Images/home-page-back.png";
import {FaFacebook,FaInstagram,FaLinkedin,FaYoutube} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import NoDataFound from "../../common/NodataFound";
import ClipLoader from "react-spinners/FadeLoader";
import usePageSEO from "../usepageSEO/Index";
import { PopularInfluencerAPI } from "../../API/InfluencerSignUpAPI";
import Breadcrumb from "../../Breadcumb/Breadcumb";

const PopularInfluencer = () => {
    const breadcrumbItems = [
        {
            text: "Popular Influencer",
        },
    ];
    const backgroundImage = ModelsBanner;

    usePageSEO(
        "Viral kar | Popular Influencer", // Use page title for SEO
        "Viral kar | Popular Influencer", // Use page description for SEO
        ["Viral kar | Influencers"] // Keywords
    );

    const [InfluencerData, setInfluencerData] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state

    const getData = async () => {
        try {
            const results = await PopularInfluencerAPI();
            setInfluencerData(results.slice(0, 50)); // Limit the data to 50 items
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="dark:bg-darkmode dark:text-white">
            <Breadcrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
            <div className="mb-10">
                <div
                    className="subscribe-bg relative bg-cover bg-right bg-no-repeat bg-gray-50"
                    style={{ backgroundImage: `url(${HomeBackImg})` }}
                >
                    {loading ? (
                        <div className="flex justify-center items-center my-40">
                            <ClipLoader color={"#d00067"} loading={loading} size={40} />
                        </div>
                    ) : (
                        <div className="container mx-auto px-4 md:px-0 lg:py-12 py-10">
                            {InfluencerData?.length === 0 ? (
                                <NoDataFound />
                            ) : (
                                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 container mx-auto ">
                                    {InfluencerData?.map((val, index) => {
                                        return (
                                            <div key={index}>
                                                <Link
                                                    to={`/influencer/${val.CityName}/${val.UserName}`}
                                                    className="relative models-box cursor-pointer"
                                                >
                                                    <div className="item pb-[146px] group relative grid grid-cols-1">
                                                        <div className="relative overflow-hidden surface:h-[20rem] xl:h-[25rem] h-60">
                                                            <img
                                                                className="block w-full transform-style-[preserve-3d] h-full object-cover object-top rounded-t-lg"
                                                                src={val.Path + val.ProfileImage}
                                                                alt="#"
                                                            />
                                                        </div>
                                                        <div className="border-l-3 border-[#95c6d8] shadow-md rounded-b-lg bottom-0 left-0 overflow-hidden perspective-[500px] min-h-[146px] absolute right-0 px-[20px] pt-[20px] transition-all duration-500 ease-in-out bg-white dark:bg-darkmode dark:text-white z-20 group-hover:bg-[#f5f5f5] dark:group-hover:bg-darkmode group-hover:border-l-transparent group-hover:pt-[37px] group-hover:-translate-y-4">
                                                            <h3 className="text-xl text-left font-semibold leading-[1] tracking-normal mb-[10px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                                                                <Link to={`/influencer/${val.CityName}/${val.UserName}`}>
                                                                    {val.Name}
                                                                </Link>
                                                            </h3>
                                                            <h3 className="text-sm text-left leading-[1.3] md:pr-6 line-clamp-2 tracking-normal mb-[20px] transition-transform duration-500 ease-in-out transform group-hover:-translate-y-2">
                                                                {val.ContentDescriptionBio}
                                                            </h3>
                                                            <div className="hidden group-hover:block transition-all duration-500 ease-in-out transform group-hover:-translate-y-4 group-hover:opacity-100 border-l-4 border-themecolor">
                                                                <div className="flex flex-col space-y-[10px] opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out transform group-hover:translate-y-0 translate-y-[50px]">
                                                                    <div className="grid grid-cols-2 lg:grid-cols-2">
                                                                        <div className="inline-block text-left leading-[1.4] mx-[16px]">
                                                                            <span className="font-semibold block overflow-hidden mb-[3px] text-xs lg:text-base">
                                                                                Follower Count
                                                                            </span>
                                                                            <span className="text-sm">{val.FollowerCount}</span>
                                                                        </div>
                                                                        <div className="inline-block text-left leading-[1.4] mx-[16px]">
                                                                            <span className="font-semibold block overflow-hidden mb-[3px] text-xs lg:text-base">
                                                                                Engage Count
                                                                            </span>
                                                                            <span className="text-sm">{val.EngagementRate}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="absolute bottom-0 right-0 top-0 w-[40px] bg-themecolor1 opacity-0 transition-all duration-500 ease-in-out transform translate-y-[50px] group-hover:translate-y-0 group-hover:opacity-100 flex flex-col justify-center space-y-4 text-white text-xl text-center">
                                                            {(val.Instragram  || val.Facebook || val.Twitter || val.Youtube || val.LinkedIn) && (
                                                                <div className="absolute bottom-0 right-0 top-0 w-[40px] bg-themecolor1 opacity-0 transition-all duration-500 ease-in-out transform translate-y-[50px] group-hover:translate-y-0 group-hover:opacity-100 flex flex-col justify-center space-y-4 text-white text-xl text-center">
                                                                    {val.Instragram && (
                                                                    <Link to={"https://www.instagram.com/viralkar.official/"} target="_blank" className=" text-white text-lg mx-1 drop-shadow-lg">
                                                                        <FaInstagram className="mx-auto" />
                                                                    </Link>
                                                                    )}
                                                                    {val.Facebook && (
                                                                    <Link to={"https://www.facebook.com/viralkarr/"} target="_blank" className="text-white text-lg mx-1 drop-shadow-lg">
                                                                        <FaFacebook className="text-lg mx-auto" />
                                                                    </Link>
                                                                    )}
                                                                    {val.Twitter && (
                                                                    <Link to={"https://x.com/viral_kar_"} target="_blank" className="text-white text-lg mx-1 drop-shadow-lg">
                                                                        <FaXTwitter className="text-lg mx-auto" />
                                                                    </Link>
                                                                    )}
                                                                    {val.YouTube && (
                                                                    <Link to={"https://www.youtube.com/@ViralKarr"} target="_blank" className="text-white p-2 text-lg mx-1 drop-shadow-lg">
                                                                        <FaYoutube className="text-lg mx-auto" />
                                                                    </Link>
                                                                    )}
                                                                    {val.LinkedIn && (
                                                                    <Link to={"https://www.linkedin.com/company/viralkar"} target="_blank" className="text-white p-2 text-lg mx-1 drop-shadow-lg">
                                                                        <FaLinkedin className="text-lg mx-auto" />
                                                                    </Link>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PopularInfluencer;
