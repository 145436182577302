import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

// ------------------------------Contact ----------------------
// https://www.model.easyshadi.com/front/api/contact
export const sendContact = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/contact`, data);
    if (response.data.status === true) {
      toast.success(response.data.message);
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
