import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Breadcumb/Breadcumb";
import { GetPageBySlug } from "../../API/PagesApi"; // Correct API function
import backgroundImage from "../../Images/about-banner.jpg"

const AboutUs = () => {
  const [aboutData, setAboutData] = useState(null); // Initialize state
  const breadcrumbItems = [
    {
      text: "About Us",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Since GetPageBySlug returns responseData directly
        const responseData = await GetPageBySlug("about-us");
        // Check if responseData is an array and has data
        if (responseData && responseData.length > 0) {
          setAboutData(responseData[0]);
        } else {
          console.log("No data found in API response.");
        }
      } catch (error) {
        console.error("Error fetching About Us data:", error);
      }
    };

    fetchData();
  }, []);

  if (!aboutData) {
    return <div>Loading...</div>;
  }

  const backgroundImage1 = aboutData.Image;
  const content = aboutData.Content;

  return (
    <div className="dark:bg-gray-300 dark:text-white">
      <Breadcrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
      <div className="">
        <div className="container mx-auto pt-10 pb-10 px-5 md:px-0">
          <div className="md:flex items-center gap-10">
            <div className="md:w-3/5">
              <h2 className="text-4xl mb-5 text-themecolor1">{aboutData.Title}</h2>
              <h3 className="font-medium text-textcolor2">Welcome to ViralKar.com!</h3>
              <div
                className="py-3 dark:text-gray-700"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
            <div className="mt-5 md:w-2/5 lg:mt-0">
              <div className="overflow-hidden relative h-[30rem]">
                <img
                  src={backgroundImage1}
                  alt={aboutData.Title}
                  className="h-full w-full object-cover object-top"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
