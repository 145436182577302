import React, { useState } from "react";
import titleimage from "../../../../Images/title-icon.png";
import logo from "../../../../Images/logo.png";
import loginbg from "../../../../Images/password.png";
import sectionbg from "../../../../Images/in-section-shape15.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { Link, useNavigate } from "react-router-dom";
import usePageSEO from "../../../usepageSEO/Index";
import Config from "../../../../API/Config";
import { ModelPassword } from "../../../../API/ModelSignUpAPI";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const validationSchema = Yup.object().shape({
  Password: Yup.string().required("Please Enter Your Password"),
});

const LoginPasswordModel = () => {
  usePageSEO(
    "Free Model Register - VilarKar",
    "Struggling to get noticed? Join our free model register to showcase your talent, connect with top brands, and land your next big opportunity.",
    [
      "Free Model Register",
      "Model Register",
      "Register as Model",
      "Register as Model for free",
    ]
  );

  const ModelId = Config.getModelIdForTemp();
  const ModelEmail = Config.getModelEmailForTemp();
  const ModelPhone = Config.getModelPhoneForTemp();
  const ModelUser = Config.getModelUserNameForTemp();

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility
  const usenavigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      ModelId: ModelId,
      ModelEmail: ModelEmail,
      ModelPhone: ModelPhone,
      ModelStageName: ModelUser,
      Password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      console.log(values);

      try {
        const response = await ModelPassword(values);
        if (response.status === true) {
          usenavigate("/profile/model");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <div className="bg-dark">
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="md:py-14 py-5 px-5 xl:px-0">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center pb-2 capitalize dark:text-white">
              Step into the limelight register or log in to showcase your style
            </p>

            <img src={titleimage} className="text-center title-icon" alt="" />
          </div>

          <form onSubmit={formik.handleSubmit} className="py-10">

          <div className="flex items-center justify-center">
          <div className="surface:w-4/5 xl:w-3/5 rounded-lg bg-dark-light p-5 surface:p-0 md:p-0 grid md:grid-cols-2 grid-cols-1 dark:border border-white w-full">
          <div className="col-span-1 hidden md:block mt-10 md:mt-0 relative">
                  <img src={sectionbg} alt="" className="" />
                  <img
                    src={loginbg}
                    alt=""
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  />
                </div>
                <div className="col-span-1 my-auto mx-0 md:mx-20">
                  <img
                    src={logo}
                    className="bg-white p-2 w-64 rounded mx-auto mb-10"
                    alt=""
                  />
                  <div className="my-3">
                    <label htmlFor="Password">Password</label>
                    <div className="relative">
                      <input
                        type={passwordVisible ? "text" : "password"} // Toggle between 'text' and 'password'
                        name="Password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Your Password"
                        className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
                      >
                        {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                    {formik.touched.Password && formik.errors.Password ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Password}
                      </div>
                    ) : null}
                  </div>

                  <div className="flex justify-center mx-auto my-3 gap-2 md:gap-8">
                    <Link to={"/model-register"}>
                      <button
                        type="button"
                        className="bg-green-600 hover:bg-themecolor rounded-full text-sm font-semibold text-white p-3 px-5 md:px-10"
                      >
                        Back
                      </button>
                    </Link>
                    <button
                      type="submit"
                      className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-sm font-semibold text-white p-3 px-5 md:px-10"
                    >
                      Login
                    </button>
                  </div>
                </div>
          </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPasswordModel;
