/* eslint-disable react-hooks/exhaustive-deps */
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import React, { useEffect, useState } from "react";
import titleimage from "../../../../Images/title-icon.png";
import { FaAddressCard, FaCalendar, FaEye, FaEyeSlash,FaUser} from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { useNavigate } from "react-router";
import usePageSEO from "../../../usepageSEO/Index";
import Config from "../../../../API/Config";
import { ModelGetById, ModelStep1Add } from "../../../../API/ModelSignUpAPI";

const RegisterModel = () => {
  usePageSEO(
    "Free Model Register - VilarKar", // Use page title for SEO if available
    "Struggling to get noticed? Join our free model register to showcase your talent, connect with top brands, and land your next big opportunity.", // Use page description for SEO if available
    [
      "Free Model Register, Model Register, Register as Model , Register as Model for free",
    ] // No keywords provided in this example
  );
  const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);
  const socialMediaOptions = [
    "Instagram",
    "YouTube",
    "Facebook",
    "Twitter",
    "LinkedIn",
    "Other",
  ];

  const validateFileSize = (file, maxSizeMB) => {
    if (file && file.size > maxSizeMB * 1024 * 1024) {
      return `File size must be less than ${maxSizeMB} MB`;
    }
    return null;
  };
  const validationSchema = Yup.object()
    .shape({
      Name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only alphabetic characters are allowed")
        .required("Please Enter Your Name"),
      StageName: Yup.string().required("Please Enter Your Stage Name"),
      ProfilePicture: Yup.mixed()
        .required("Profile Picture is required")
        .test("fileSize", "File size must be less than 5 MB", (value) => {
          return validateFileSize(value, 5) === null;
        }),
      Email: Yup.string().required("Please Enter Your Email"),
      Phone: Yup.string().required("Please Enter Your Phone"),
      PassWord: Yup.string().required("Please Enter Your Password"),
      Username: Yup.string()
      .required("Please Enter a Username")
      .matches(/^[a-z0-9]*$/, "Username must be lowercase letters and numbers only.")
      .matches(/^\S*$/, "Username cannot contain spaces."),
      // .test('is-unique', 'Username already exists', async (value) => {
      //   if (value) {
      //     const exists = await checkUsernameExists(value); // Call to check if the username exists
      //     return !exists; // Return true if username does not exist
      //   }
      //   return true; // If no value, return true
      // }),
      Gender: Yup.string().required("Please Enter Your Gender"),
      Instagram: Yup.string().url("Invalid URL format"),
      YouTube: Yup.string().url("Invalid URL format"),
      Facebook: Yup.string().url("Invalid URL format"),
      Twitter: Yup.string().url("Invalid URL format"),
      LinkedIn: Yup.string().url("Invalid URL format"),
      Other: Yup.string().url("Invalid URL format"),
    })
    .test(
      "at-least-one-social-media",
      "Please add at least one social media URL",
      (values) => {
        return socialMediaOptions.some(
          (option) => values[option] && values[option].trim() !== ""
        );
      }
    );

  const ModelId = Config.getModelIdForTemp();
  const ModelStep1 = Config.getModelStep1ForTemp();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [ModelData, setModelData] = useState([]);
  const [Path, setPath] = useState([]);
  const [profilePicture, setProfilePicture] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const getData = async () => {
      const response = await ModelGetById(ModelId);
      setModelData(response);
      setPath(response.Path);
      setProfilePicture(response.ProfileImage || "");

      sessionStorage.setItem(
        "ModelStep1ForTemp",
        JSON.stringify(response.Step1)
      );
      sessionStorage.setItem(
        "ModelStep2ForTemp",
        JSON.stringify(response.Step2)
      );
      sessionStorage.setItem(
        "ModelStep3ForTemp",
        JSON.stringify(response.Step3)
      );
      formik.setValues({
        ModelId: response.Id || "",
        Name: response.Name || "",
        StageName: response.StageName || "",
        ProfilePicture: response.ProfileImage || "",
        Hid_Image: response.Hid_Image || "",
        Email: response.Email || "",
        Phone: response.Phone || "",
        Username: response.Username || "",
        PassWord: response.PassWord || "",
        Gender: response?.Gender || "",
        Instagram: response.Instagram || "",
        YouTube: response.YouTube || "",
        Facebook: response.Facebook || "",
        Twitter: response.Twitter || "",
        LinkedIn: response.LinkedIn || "",
        Other: response.Other || "",
      });
      // Automatically check the boxes for social media that have values
      const filledSocialMedia = socialMediaOptions.filter(
        (option) => response[option] && response[option].trim() !== ""
      );
      setSelectedSocialMedia(filledSocialMedia);
    };
    getData();
  }, [ModelId]);

  const navigation = useNavigate();
  const formik = useFormik({
    initialValues: {
      ModelId: ModelId,
      Name: "",
      StageName: "",
      ProfilePicture: "",
      Hid_Image: "",
      Email: "",
      Username: "",
      Phone: "",
      PassWord: "",
      Gender: "",
      Instagram: "",
      YouTube: "",
      Facebook: "",
      Twitter: "",
      LinkedIn: "",
      Other: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);

      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const result = await ModelStep1Add(formData);

        if (result.status === true) {
          navigation("/model/sign-up/model2");
          sessionStorage.setItem(
            "ModelEmailForTemp",
            JSON.stringify(ModelData.Email)
          );
          sessionStorage.setItem(
            "ModelUserNameForTemp",
            JSON.stringify(ModelData.StageName)
          );
          sessionStorage.setItem(
            "ModelUserNameForTemp",
            JSON.stringify(ModelData.UserName)
          );
          sessionStorage.setItem(
            "ModelPhoneForTemp",
            JSON.stringify(ModelData.Phone)
          );
          sessionStorage.setItem(
            "ModelStep1ForTemp",
            JSON.stringify(ModelData.Step1)
          );
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const handleSocialMediaCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedSocialMedia((prevSelected) =>
      checked
        ? [...prevSelected, name]
        : prevSelected.filter((media) => media !== name)
    );
  };

  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    formik.setFieldValue("Phone", value);
  };

  return (
    <div>
      <div className="bg-dark">
        {" "}
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-14 px-5 container mx-auto">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center pb-2 dark:text-white capitalize">
              Step into the limelight register or log in to showcase your style
            </p>

            <img
              src={titleimage}
              className="text-center mt-5 title-icon"
              alt=""
            />
          </div>
          <div className="relative flex items-center justify-between w-full my-10">
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300"></div>
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-themecolor dark:bg-white transition-all duration-500"></div>
            <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor rounded-full place-items-center">
              <FaUser className="w-5 h-5" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-gray-300 rounded-full place-items-center">
              <FaCalendar className="w-5 h-5 text-black" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-gray-300 rounded-full place-items-center">
              <FaAddressCard className="w-5 h-5 text-black  " />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className=" my-10 dark:border border-white"
          >
            <input
              type="hidden"
              name="Hid_Image"
              value={formik.values.Hid_Image}
            />
            <div className=" grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="col-span-3 bg-white dark:bg-slate-800 p-5 md:p-8 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border">
                <p className="text-center text-2xl pb-3 mb-3 border-b">
                  Personal Information
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="my-1">
                    <label className="text-base">
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Name}
                      placeholder="Please Enter Full Name"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    {formik.touched.Name && formik.errors.Name ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Name}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Stage Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="StageName"
                      value={formik.values.StageName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Stage Name"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    {formik.touched.StageName && formik.errors.StageName ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.StageName}
                      </div>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <label className="text-base">Username <span className="text-red-500">*</span></label>
                    <input
                      type="text"
                      name="Username"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Username}
                      placeholder="Please Enter Username"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Username && formik.errors.Username ? (
                      <div className="text-red-500 text-sm">{formik.errors.Username}</div>
                    ) : null}
                  </div>

                  <div className="my-1">
                    <label className="text-base">
                      Profile Picture <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="file"
                      name="ProfilePicture"
                      accept="image/*"
                      onBlur={formik.handleBlur}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "ProfilePicture",
                          event.currentTarget.files[0]
                        );
                      }}
                      className="text-sm text-grey-500 file:mr-5 file:py-1.5 file:px-5 file:rounded-full file:border-0 file:text-md file:font-semibold  file:text-white
                      file:bg-gradient-to-r file:from-themecolor file:to-black hover:file:cursor-pointer hover:file:opacity-80 py-1.5 w-full focus:outline-none mt-3 border-0 rounded"
                    />
                    {profilePicture && (
                      <img
                        src={
                          typeof profilePicture === "string"
                            ? Path + profilePicture
                            : URL.createObjectURL(profilePicture)
                        }
                        alt="Profile"
                        className="mt-2 h-24 w-24 object-cover"
                      />
                    )}
                    {formik.touched.ProfilePicture &&
                    formik.errors.ProfilePicture ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.ProfilePicture}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      name="Email"
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Email Address"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617] "
                    />
                    {formik.touched.Email && formik.errors.Email ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Email}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Phone Number  <span className="text-red-500">*</span>
                    </label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={formik.values.Phone}
                      onChange={handlePhoneChange} // Update the phone value with country code
                      defaultCountry="IN"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Phone && formik.errors.Phone ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Phone}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Gender <span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center mt-3 px-2 py-2.5">
                      <div className="mr-3">
                        <input
                          type="radio"
                          id="Male"
                          name="Gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="M"
                          className="mr-2"
                          checked={formik.values.Gender === "M"}
                        />
                        <label htmlFor="Male">Male</label>
                      </div>
                      <div className="mr-3">
                        <input
                          type="radio"
                          id="Female"
                          name="Gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="F"
                          className="mr-3"
                          checked={formik.values.Gender === "F"}
                        />
                        <label htmlFor="Female">Female</label>
                      </div>
                      <div className="">
                        <input
                          type="radio"
                          id="Kid"
                          name="Gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value="K"
                          className="mr-3"
                          checked={formik.values.Gender === "K"}
                        />
                        <label htmlFor="Kid">Kid</label>
                      </div>
                    </div>

                    {formik.touched.Gender && formik.errors.Gender ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Gender}
                      </div>
                    ) : null}
                  </div>
                  {ModelStep1 === "1" ? (
                    ""
                  ) : (
                    <div className="my-1">
                      <label className="text-base">
                        Create Password <span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          name="PassWord"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.PassWord}
                          placeholder="Please Enter Password "
                          className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
                        >
                          {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                        </button>
                      </div>
                      {formik.touched.PassWord && formik.errors.PassWord ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.PassWord}
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>

                <p className="text-center text-2xl pb-3 mb-3 border-b"></p>
                <div className="rounded-md mt-5 md:mb-10">
                  <div className="">
                    <p>Your Social Media Assets</p>
                    <small className="text-gray-400">
                      Select Social Media Assets and Add Links
                    </small>
                  </div>
                  <div className="grid md:grid-cols-4 grid-cols-1 gap-5 mt-2">
                    {/* {socialMediaOptions.map((option) => (
                      <div
                        key={option}
                        className="rounded-lg shadow-lg duration-500 border px-3 py-2"
                      >
                        <div className="flex items-center my-1">
                          <input
                            type="checkbox"
                            className="mr-2"
                            name={option}
                            id={option}
                            checked={selectedSocialMedia.includes(option)}
                            onChange={handleSocialMediaCheckboxChange}
                          />
                          <label htmlFor={option}>{option}</label>
                        </div>
                        {selectedSocialMedia.includes(option) && (
                          <div>
                            <input
                              type="text"
                              name={option}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values[option]}
                              placeholder={`Please Enter ${option} URL`}
                              className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                            />
                            {formik.touched[option] &&
                              formik.errors[option] && (
                                <div className="text-red-500 text-sm">
                                  {formik.errors[option]}
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    ))}*/}

                    <div className="my-1">
                      <label className="text-base">Instagram</label>
                      <input
                        type="text"
                        name="Instagram"
                        value={formik.values.Instagram}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Instagram URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Instagram && formik.errors.Instagram ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Instagram}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">YouTube</label>
                      <input
                        type="text"
                        name="YouTube"
                        value={formik.values.YouTube}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter YouTube URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.YouTube && formik.errors.YouTube ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.YouTube}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">Facebook</label>
                      <input
                        type="text"
                        name="Facebook"
                        value={formik.values.Facebook}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Facebook URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Facebook && formik.errors.Facebook ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Facebook}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">Twitter</label>
                      <input
                        type="text"
                        name="Twitter"
                        value={formik.values.Twitter}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Twitter URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Twitter && formik.errors.Twitter ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Twitter}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">LinkedIn</label>
                      <input
                        type="text"
                        name="LinkedIn"
                        value={formik.values.LinkedIn}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter LinkedIn URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.LinkedIn && formik.errors.LinkedIn ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.LinkedIn}
                        </div>
                      ) : null}
                    </div>

                    <div className="my-1">
                      <label className="text-base">Other</label>
                      <input
                        type="text"
                        name="Other"
                        value={formik.values.Other}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Other URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Other && formik.errors.Other ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Other}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="flex justify-center my-3">
                  <button
                    type="submit"
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 mx-auto"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterModel;
