import axios from "axios";
import Config from "./Config";
const { API_BASE_URL } = Config;

// ------------------------------get pages by Slug-------------------
// htt[s://www.model.easyshadi.com/front/api/pages/${Slug}

export const GetPageBySlug = async (Slug) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/pages/${Slug}`);
    if (response.data.status === true) {
      return response.data.responseData; // Return the array of data
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

