import React, { useState } from "react";
import ContactusImg from "../../../Images/contact-back.jpg";
import titleimage from "../../../Images/title-icon.png";
import { FaEnvelope, FaMobile, FaPaperPlane, FaPlane } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { useFormik } from "formik";

import * as Yup from "yup";
import Breadcrumb from "../../../Breadcumb/Breadcumb";
import FormLoader from "../../../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
import { Link } from "react-router-dom";
import usePageSEO from "../../usepageSEO/Index";

const validationSchema = Yup.object().shape({
  Email: Yup.string().email("Invalid email").required("Email is required"),
});

const ForgotPassword = () => {
  usePageSEO(
    "Viral kar | Forgot Password", // Use page title for SEO if available
    "Viral kar | Forgot Password", // Use page description for SEO if available
    ["Viral kar | Forgot Password"] // No keywords provided in this example
  );

  const breadcrumbItems = [
    {
      text: "Reset Your Password",
    },
  ];

  const backgroundImage = ContactusImg;
  const [isFormLoading, setIsFormLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      Email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        // const response = await ContactData(values);
        // if (response.status === true) {
        //   actions.resetForm();
        // }
        console.log("Reset Your Password Data:", values);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });
  return (
    <div>
      <div className="dark:bg-darkmode dark:text-white">
        <Breadcrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-20 px-5">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center">Reset Your Password</p>

            <img src={titleimage} className="text-center title-icon" alt="" />
          </div>

          <form onSubmit={formik.handleSubmit} className=" my-10">
            <div className=" grid grid-cols-1 lg:grid-cols-3 gap-5">
              <div className="col-span-1"></div>
              <div className="col-span-1 border rounded-md shadow-md p-8">
                <div className="my-3">
                  <label for="">Email address</label>
                  <input
                    type="text"
                    name="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Email address"
                    className=" px-4 py-2.5 w-full focus:outline-none my-2.5 mt-1.5 border border-zinc-400 rounded dark:bg-darkmode"
                  />
                  {formik.touched.Email && formik.errors.Email ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.Email}
                    </div>
                  ) : null}
                </div>

                <div className="flex justify-center my-3">
                  <button
                    type="submit"
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 mx-auto"
                  >
                    Reset Your Password
                  </button>
                </div>
                <p className="text-center my-3">
                  Already have an account?
                  <Link
                    to={"/sign-up"}
                    className="ml-2 text-center underline hover:no-underline cursor-pointer hover:text-white transition-all "
                  >
                    Login now
                  </Link>
                </p>
              </div>
              <div className="col-span-1"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
