import React, { useEffect, useState } from "react";
import titleimage from "../../../../Images/title-icon.png";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {FaAddressCard, FaCalendar, FaEye, FaEyeSlash, FaUser} from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormLoader from "../../../../common/FormLoader";
import ScrollToTop from "../../ScrollToTop";
import { useNavigate } from "react-router";
import usePageSEO from "../../../usepageSEO/Index";
import Config from "../../../../API/Config";
import { InfluencerGetById, InfluencerStep1Add} from "../../../../API/InfluencerSignUpAPI";
import { isValidPhoneNumber } from 'libphonenumber-js';

const RegisterInfluencerNew = () => {
  usePageSEO(
    "Free Influencer Register - ViralKar",
    "Want more brand deals? Use our free influencer register to boost your visibility, connect with top companies, and grow your online presence fast.",
    [
      "Free influence Register, influence Register, Register as influencer, Register as influencer for free",
    ]
  );
  const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);
  const socialMediaOptions = [
    "Instragram",
    "YouTube",
    "Facebook",
    "Twitter",
    "LinkedIn",
    "TikTok",
    "Other",
  ];

  const validateFileSize = (file, maxSizeMB) => {
    if (file && file.size > maxSizeMB * 1024 * 1024) {
      return `File size must be less than ${maxSizeMB} MB`;
    }
    return null;
  };


const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabetic characters are allowed")
    .required("Please Enter Your Name"),
  ProfilePicture: Yup.mixed()
    .required("Profile Picture is required")
    .test("fileSize", "File size must be less than 5 MB", (value) => {
      return validateFileSize(value, 5) === null;
    }),
  Email: Yup.string().required("Please Enter Your Email"),
  Phone: Yup.string()
    .required('Phone number is required')
    .test('isValidPhoneNumber', 'Phone number is not valid', function (value) {
      return isValidPhoneNumber(value);
    }),
  PassWord: Yup.string()
    .required("Please Enter Your Password")
    .matches(/^[a-z0-9]*$/, "Password must be lowercase letters and numbers only.")
    .min(6, "Password must be at least 6 characters long."),
    Username: Yup.string()
    .required("Please Enter a Username")
    .matches(/^[a-z0-9]*$/, "Username must be lowercase letters and numbers only.")
    .matches(/^\S*$/, "Username cannot contain spaces."),
    // .test('is-unique', 'Username already exists', async (value) => {
    //   if (value) {
    //     const exists = await checkUsernameExists(value); // Call to check if the username exists
    //     return !exists; // Return true if username does not exist
    //   }
    //   return true; // If no value, return true
    // }),
   
  Instragram: Yup.string().url("Invalid URL format"),
  YouTube: Yup.string().url("Invalid URL format"),
  Facebook: Yup.string().url("Invalid URL format"),
  Twitter: Yup.string().url("Invalid URL format"),
  LinkedIn: Yup.string().url("Invalid URL format"),
  TikTok: Yup.string().url("Invalid URL format"),
  Other: Yup.string().url("Invalid URL format"),
}).test(
  "at-least-one-social-media",
  "Please add at least one social media URL",
  (values) => {
    return socialMediaOptions.some(
      (option) => values[option] && values[option].trim() !== ""
    );
  }
);


  const InfluencerId = Config.getInfluencerIdForTemp();
  const InfluencerStep1 = Config.getInfluencerStep1ForTemp();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [InfluencerData, setInfluencerData] = useState([]);
  const [Path, setPath] = useState([]);
  const [profilePicture, setProfilePicture] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const getData = async () => {
      const response = await InfluencerGetById(InfluencerId);
      setInfluencerData(response);
      setPath(response.Path);
      setProfilePicture(response.ProfileImage || "");

      sessionStorage.setItem(
        "InfluencerStep1ForTemp",
        JSON.stringify(response.Step1)
      );
      sessionStorage.setItem(
        "InfluencerStep2ForTemp",
        JSON.stringify(response.Step2)
      );
      sessionStorage.setItem(
        "InfluencerStep3ForTemp",
        JSON.stringify(response.Step3)
      );
      formik.setValues({
         InfluencerId: response.Id || "",
        Name: response.Name || "",
        ProfilePicture: response.ProfileImage || "",
        Hid_Image: response.Hid_Image || "",
        Email: response.Email || "",
        Phone: response.Phone || "",
        PassWord: response.PassWord || "",
        Username: response.Username || "",
        Instragram: response.Instragram || "",
        YouTube: response.YouTube || "",
        Facebook: response.Facebook || "",
        Twitter: response.Twitter || "",
        LinkedIn: response.LinkedIn || "",
        TikTok: response.TikTok || "",
        Other: response.Other || "",
      });
      // Automatically check the boxes for social media that have values
      const filledSocialMedia = socialMediaOptions.filter(
        (option) => response[option] && response[option].trim() !== ""
      );
      setSelectedSocialMedia(filledSocialMedia);
    };
    getData();
  }, [InfluencerId]);

  const navigation = useNavigate();
  const formik = useFormik({
    initialValues: {
      InfluencerId: InfluencerId,
      Name: "",
      ProfilePicture: "",
      Hid_Image: "",
      Email: "",
      Phone: "",
      PassWord: "",
      Username: "",
      Instragram: "",
      YouTube: "",
      Facebook: "",
      Twitter: "",
      LinkedIn: "",
      TikTok: "",
      Other: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);

      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const result = await InfluencerStep1Add(formData);

        if (result.status === true) {
          navigation("/influencer/sign-up/influencer2");
          sessionStorage.setItem(
            "InfluencerEmailForTemp",
            JSON.stringify(InfluencerData.Email)
          );
          sessionStorage.setItem(
            "InfluencerUserNameForTemp",
            JSON.stringify(InfluencerData.UserName)
          );
          sessionStorage.setItem(
            "InfluencerPhoneForTemp",
            JSON.stringify(InfluencerData.Phone)
          );
          sessionStorage.setItem(
            "InfluencerStep1ForTemp",
            JSON.stringify(InfluencerData.Step1)
          );
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const handleSocialMediaCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedSocialMedia((prevSelected) =>
      checked
        ? [...prevSelected, name]
        : prevSelected.filter((media) => media !== name)
    );
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  //const [value, setValue] = useState()
  const handlePhoneChange = (value) => {
    setPhone(value);
    formik.setFieldValue("Phone", value);
  };

  return (
    <div>
      <div className="bg-dark">
        {" "}
        {isFormLoading && <FormLoader loading={isFormLoading} />}
        <ScrollToTop />
        <div className="py-14 px-5 container mx-auto">
          <div className="flex items-center justify-center flex-col">
            <p className="page-title text-center pb-2 dark:text-white">
              Unlock your influence-register or log in to shine brighter
            </p>

            <img
              src={titleimage}
              className="text-center mt-5 title-icon"
              alt=""
            />
          </div>
          <div className="relative flex items-center justify-between w-full my-10">
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-300"></div>
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-themecolor dark:bg-white transition-all duration-500"></div>
            <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor rounded-full place-items-center">
              <FaUser className="w-5 h-5" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-gray-300 rounded-full place-items-center">
              <FaCalendar className="w-5 h-5 text-black" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-th transition-all duration-300 bg-gray-300 rounded-full place-items-center">
              <FaAddressCard className="w-5 h-5 text-black  " />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className=" my-10 dark:border border-white"
          >
            <input
              type="hidden"
              name="Hid_Image"
              value={formik.values.Hid_Image}
            />
            <div className=" grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="col-span-3 bg-white dark:bg-slate-800 p-5 md:p-8 py-6 rounded-lg hover:shadow-2xl shadow-lg duration-500 border">
                <p className="text-center text-2xl pb-3 mb-3 border-b">
                  Personal Information
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="my-1">
                    <label className="text-base">
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Name}
                      placeholder="Please Enter Full Name"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Name && formik.errors.Name ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Name}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      name="Email"
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Please Enter Email Address"
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Email && formik.errors.Email ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Email}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Profile Picture <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="file"
                      name="ProfilePicture"
                      accept="image/*"
                      onBlur={formik.handleBlur}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "ProfilePicture",
                          event.currentTarget.files[0]
                        );
                      }}
                      className="text-sm text-grey-500 file:mr-5 file:py-1.5 file:px-5 file:rounded-full file:border-0 file:text-md file:font-semibold  file:text-white
                      file:bg-gradient-to-r file:from-themecolor file:to-black hover:file:cursor-pointer hover:file:opacity-80 py-1.5 w-full focus:outline-none mt-3 border-0 rounded"
                    />
                    {profilePicture && (
                      <img
                        src={
                          typeof profilePicture === "string"
                            ? Path + profilePicture
                            : URL.createObjectURL(profilePicture)
                        }
                        alt="Profile"
                        className="mt-2 h-24 w-24 object-cover"
                      />
                    )}
                    {formik.touched.ProfilePicture &&
                    formik.errors.ProfilePicture ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.ProfilePicture}
                      </div>
                    ) : null}
                  </div>
                  <div className="my-1">
                    <label className="text-base">
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={formik.values.Phone}
                      onChange={handlePhoneChange}
                      defaultCountry="IN" 
                      className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Phone && formik.errors.Phone ? (
                      <div className="text-red-500 text-sm">
                        {formik.errors.Phone}
                      </div>
                    ) : null}
                  </div>
                  {InfluencerStep1 === "1" ? (
                    ""
                  ) : (
                    <div className="my-1">
                      <label className="text-base">
                        Create Password <span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          name="PassWord"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.PassWord}
                          placeholder="Please Enter Password "
                          className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
                        >
                          {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                        </button>
                      </div>
                      {formik.touched.PassWord && formik.errors.PassWord ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.PassWord}
                        </div>
                      ) : null}
                    </div>
                  )}

                    <div className="my-1">
                    <label className="text-base">Username <span className="text-red-500">*</span></label>
                    <input
                      type="text"
                      name="Username"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Username}
                      placeholder="Please Enter Username"
                      className="px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                    />
                    {formik.touched.Username && formik.errors.Username ? (
                      <div className="text-red-500 text-sm">{formik.errors.Username}</div>
                    ) : null}
                  </div>

                </div>
                <p className="text-center text-2xl pb-3 mb-3 border-b"></p>
                <div className="rounded-md mt-5 md:mb-10">
                  <div className="md:mb-4">
                    <p>Your Social Media Assets</p>
                    <small className="text-gray-400">
                      Select Social Media Assets and Add Links
                    </small>
                  </div>
                  <div className="grid md:grid-cols-4 grid-cols-1 gap-5 mt-2">
                    <div className="my-1">
                      <label className="text-base">Instagram</label>
                      <input
                        type="text"
                        name="Instragram"
                        value={formik.values.Instragram}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Instagram URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Instragram && formik.errors.Instragram ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Instragram}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">YouTube</label>
                      <input
                        type="text"
                        name="YouTube"
                        value={formik.values.YouTube}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter YouTube URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.YouTube && formik.errors.YouTube ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.YouTube}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">Facebook</label>
                      <input
                        type="text"
                        name="Facebook"
                        value={formik.values.Facebook}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Facebook URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Facebook && formik.errors.Facebook ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Facebook}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">Twitter</label>
                      <input
                        type="text"
                        name="Twitter"
                        value={formik.values.Twitter}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Twitter URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Twitter && formik.errors.Twitter ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Twitter}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">LinkedIn</label>
                      <input
                        type="text"
                        name="LinkedIn"
                        value={formik.values.LinkedIn}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter LinkedIn URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.LinkedIn && formik.errors.LinkedIn ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.LinkedIn}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">TikTok</label>
                      <input
                        type="text"
                        name="TikTok"
                        value={formik.values.TikTok}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter TikTok URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.TikTok && formik.errors.TikTok ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.TikTok}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-1">
                      <label className="text-base">Other</label>
                      <input
                        type="text"
                        name="Other"
                        value={formik.values.Other}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Please Enter Other URL"
                        className=" px-4 py-3 w-full focus:outline-none placeholder-placeholder my-2.5 border border-zinc-200 rounded-md mt-3 dark:bg-[#020617]"
                      />
                      {formik.touched.Other && formik.errors.Other ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.Other}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center my-3">
                  <button
                    type="submit"
                    className="bg-themebgcolor3 hover:bg-themecolor rounded-full text-lg font-semibold text-white p-2 px-5 md:px-10 mx-auto"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
// API function to check if username exists
async function checkUsernameExists(username) {
  const response = await fetch(`/api/check-username?username=${username}`);
  const data = await response.json();
  return data.exists; // Assuming the response has an 'exists' property
}
export default RegisterInfluencerNew;
