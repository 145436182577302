import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";
const { API_BASE_URL } = Config;

export const getAllBlogsAPI = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/blog`);
      if (response.data.status === true) {
        return response.data.responsedata;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      throw  error;
    }
}

export const getRecentPostsAPI = async () => {
    try{
        const response = await axios.get(`${API_BASE_URL}/recent-post`);
        if(response.data.status === true){
            return response.data.responseData
        }else{
            throw new Error(response.data.message);
        }
    }catch (error){
        throw error;
    }
}

export const BlogbySlug = async (slug) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/blog/${slug}`);
      if(response.data.status === true){
            return response.data.responseData
        }else{
            throw new Error(response.data.message);
        }
    } catch (error) {
      console.error("Error fetching blog by slug:", error);
      throw error;
    }
  };